import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import * as CryptoJS from 'crypto-js';

import { DataServices } from '../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../Interfaces/Models/LoginModels';

import { DialogoContrasenaComponent } from "./dialogo-contrasena/dialogo-contrasena.component";

import { DialogoCrearCuentaComponent } from './dialogo-crear-cuenta/dialogo-crear-cuenta.component';

//Servicio para verificar seleccion
import { ServicesComponent } from '../Interfaces/Services/general.service';
import { 
	SaveUsuarioInvitadoRequest, 
	SaveUsuarioInvitadoResponse,
	
	DeleteUsuarioInvitadoRequest,
	DeleteUsuarioInvitadoResponse
} from '../Interfaces/Models/MovimientosModels';
import { OverlayService } from '../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { DialogoTerminosComponent } from './dialogo-terminos/dialogo-terminos.component';
import { DialogoAvisoComponent } from './dialogo-aviso/dialogo-aviso.component';
import { DialogoPreguntasComponent } from './dialogo-preguntas/dialogo-preguntas.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
	@Output() Login = new EventEmitter<any>();
	constructor(
		private snack: MatSnackBar,
		private _router: Router,
		private dataService: DataServices,
		private _dialog: MatDialog,
		private _overlay: OverlayService,
        public _servicio : ServicesComponent
		) { }

	email: string = "";
	password: string = "";
	loginView: boolean = true;
	recoverPwd1: boolean = false;
	recoverPwd2: boolean = false;
	traceitInfo: boolean = false;
	emailRecovery: string = "";
	recoveryCode: string = "";
	newPassword: string = "";
	rePassword: string = "";
	checkedInv: boolean = false;
	remember: boolean = false;

	overlayRef: OverlayRef;

	onSubmit(event) {
		this._servicio.doble = true;
		if (this.email.trim() == "" || this.password.trim() == "") {
			event.stopPropagation(); event.preventDefault();
			this.openSnack("Captura el usuario y contraseña", "Aceptar");
			return false;
		}

		var request: any = {};
		request.password = CryptoJS.SHA256(this.password).toString();
		request.user = this.email;
		request.id = "0";
		this.dataService.postDataApi<any>().subscribe(
			dataApi => {
				request.lat = dataApi.latitude;
				request.lon = dataApi.longitude;
				this.dataService.postData<LoginUserResponse>("User/loginUser", "", request).subscribe(
					data => {
						
						if (data.messageEsp != "") {
							event.stopPropagation(); event.preventDefault();
							this._router.navigate(['Login']);
							this.openSnack(data.messageEsp, "Aceptar");
							return false;
						}

						data.userData.userPermissions.forEach((it, id) => {
							sessionStorage.setItem(it.namePermission, it.permissionId.toString());
						});
						console.log('DATA', data);
						;
						
						if(!!data.userData.userData.acopiosIds){
							//Si nuestro usuario trae acopios, los guardamos de en nuestro local storage					
							let arraytemporal =  data.userData.userData.acopiosIds.split(',');
							//Convertimos el string a enteros (porque yolo, no funciona con string)
							let arrayNumeros = arraytemporal.map(str => {
								return Number(str);
							});
							//Se convierte en arreglo, pero al parecer no funciona y se tendra que convertir cada vez, que se quiera hacer uso del recurso. 
							sessionStorage.setItem("acopiosIds", arrayNumeros);
							
						}
						sessionStorage.setItem("token", data.token);
						sessionStorage.setItem("name", data.userData.userData.name);
						sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
						sessionStorage.setItem("company", data.userData.userData.company.toString());
						sessionStorage.setItem("email", this.email);
						sessionStorage.setItem("password", CryptoJS.SHA256(this.password).toString());

						document.body.style.overflow = "hidden";
						this.Login.emit(true);
						this._router.navigate(["Home"]);
					},
					error => {
						event.stopPropagation(); event.preventDefault();
						console.log(error);
						this._router.navigate(['Login']);
						this.openSnack("Error al mandar la solicitud", "Aceptar");
						return false;
					}
				);
			},
			error => {
				this.dataService.postData<LoginUserResponse>("User/loginUser", "", request).subscribe(
					data => {
						if (data.messageEsp != "") {
							event.stopPropagation(); event.preventDefault();
							this._router.navigate(['Login']);
							this.openSnack(data.messageEsp, "Aceptar");
							return false;
						}

						data.userData.userPermissions.forEach((it, id) => {
							sessionStorage.setItem(it.namePermission, it.permissionId.toString());
						});
						if(!!data.userData.userData.acopiosIds){
							//Si nuestro usuario trae acopios, los guardamos de en nuestro local storage					
							let arraytemporal =  data.userData.userData.acopiosIds.split(',');
							//Convertimos el string a enteros (porque yolo, no funciona con string)
							let arrayNumeros = arraytemporal.map(str => {
								return Number(str);
							});
							//Se convierte en arreglo, pero al parecer no funciona y se tendra que convertir cada vez, que se quiera hacer uso del recurso. 
							sessionStorage.setItem("acopiosIds", arrayNumeros);
							
						}
						sessionStorage.setItem("token", data.token);
						sessionStorage.setItem("name", data.userData.userData.name);
						sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
						sessionStorage.setItem("company", data.userData.userData.company.toString());
						sessionStorage.setItem("email", this.email);
						sessionStorage.setItem("password", CryptoJS.SHA256(this.password).toString());

						document.body.style.overflow = "hidden";
						this.Login.emit(true);
						this._router.navigate(["Home"]);
					},
					error => {
						event.stopPropagation(); event.preventDefault();
						console.log(error);
						this._router.navigate(['Login']);
						this.openSnack("Error al mandar la solicitud", "Aceptar");
						return false;
					}
				);
			}
		);
	}

	validateEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}

	recoverPassword() {

		this.loginView = false;
		this.recoverPwd1 = true;
		this.recoverPwd2 = false;
	}

	requestRecover() {
		if (this.emailRecovery !== "" && this.validateEmail(this.emailRecovery)) {
			this.dataService.postData<LoginUserResponse>("User/recoveryPassword", "", { 'email': this.emailRecovery }).subscribe(
				data => {
					if (data.messageEsp != "") {
						event.stopPropagation(); event.preventDefault();
						this._router.navigate(['Login']);
						this.openSnack(data.messageEsp, "Aceptar");
						return false;
					} else {
						this.loginView = false;
						this.recoverPwd1 = false;
						this.recoverPwd2 = true;
						this.openSnack("Código de recuperación enviado", "Aceptar");
					}
				},
				error => {
					event.stopPropagation(); event.preventDefault();
					console.log(error);
					this._router.navigate(['Login']);
					this.openSnack("Error al mandar la solicitud", "Aceptar");
					return false;
				}
			);
		} else {
			this.openSnack("Dirección inválida", "Aceptar");
		}
	}
	resetPassword() {
		if (this.recoveryCode !== "" && this.newPassword !== "" && this.rePassword !== "") {
			if ((this.newPassword === this.rePassword)) {

				var request = { 'email': this.emailRecovery, 'recoveryCode': this.recoveryCode, 'password': CryptoJS.SHA256(this.newPassword).toString() };
				this.recoveryCode = "";
				this.emailRecovery = "";
				this.newPassword = "";
				this.rePassword = "";
				this.dataService.postData<LoginUserResponse>("User/restorePassword", "", request).subscribe(
					data => {
						if (data.messageEsp != "") {
							event.stopPropagation(); event.preventDefault();
							this._router.navigate(['Login']);
							this.openSnack(data.messageEsp, "Aceptar");
							return false;
						} else {
							this.loginView = true;
							this.recoverPwd1 = false;
							this.recoverPwd2 = false;
							this.openSnack("Contraseña reestablecida exitosamente", "Aceptar");
						}
					},
					error => {
						event.stopPropagation(); event.preventDefault();
						console.log(error);
						this._router.navigate(['Login']);
						this.openSnack("Error al mandar la solicitud", "Aceptar");
						return false;
					}
				);
			} else {
				this.openSnack("Confirmación de contraseña no coincide", "Aceptar");
			}
		} else {
			this.openSnack("Todos los campos son obligatorios", "Aceptar");
		}
	}

	infoContact = () => {
		this.loginView = false;
		this.traceitInfo = true;
	}

	backLogin = () => {
		this.loginView = true;
		this.traceitInfo = false;
	}

	ngOnInit() {
		localStorage.clear();
		document.body.style.overflow = "auto";
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		document.body.style.overflow = "hidden";
	}

	//Funcion para abrir el modal del mensaje
	openSnack = (message: string, action: string) => {
		this.snack.open(message, action, {
			duration: 10000
		})
	}

	resetPass = () => {
		const dialogRef = this._dialog.open(DialogoContrasenaComponent, {
			panelClass: 'dialog-visor-edit',
			disableClose: true,
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {

		});
	}

	scrollBot = () => {
		window.scrollTo(0, document.body.scrollHeight);
	}

	downloadPDF = () => {
		let linko = document.createElement("a");
		linko.download = "(Traceit) Guia de operacion y funcionamiento";
		linko.href = "../../assets/doc/(Traceit)Guiadeoperacionyfuncionamiento.pdf";
		linko.click();

	}

	//#region Métodos (click) 

	//Abrir ventana de Dialogo de Crear Cuenta
	openDialogCrearCuenta(): void {
		console.log("Tamaño: "+screen.width);
		if ( screen.width <= 576)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
		if (screen.width > 1200){
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
	}

	openInvitado(){
		this._servicio.invitado = true;
	}

	SaveUsuarioInvitado(){

		setTimeout(() => {
			this.overlayRef = this._overlay.open();
		  }, .001);
	
		var requestUpdate = new SaveUsuarioInvitadoRequest();
		requestUpdate.email1 = '';
		requestUpdate.pass1 = CryptoJS.SHA256('1').toString();
	
		this.dataService.postData<SaveUsuarioInvitadoResponse>("Movimientos/saveUsuarioInvitado", sessionStorage.getItem("token"), requestUpdate).subscribe(
		  data => {
				if (data.messageEsp != "") {
					this.openSnack(data.messageEsp, "Aceptar");
					
				} else {

					if ( screen.width <= 576)
					{
						const dialogRef =this._dialog.open(DialogoAvisoComponent,{
							width: 'auto',
							height: 'auto'
							});    
							dialogRef.afterClosed().subscribe(res=>{
							console.log(res);
							if(res){
								console.log('Mensaje de éxito');
							}
							else{
								console.log('Mensaje de error');
							}
						});
					}
					if (screen.width > 576 && screen.width <= 768)
					{
						const dialogRef =this._dialog.open(DialogoAvisoComponent,{
							width: 'auto',
							height: 'auto'
							});    
							dialogRef.afterClosed().subscribe(res=>{
							console.log(res);
							if(res){
								console.log('Mensaje de éxito');
							}
							else{
								console.log('Mensaje de error');
							}
						});
					}  
					if (screen.width > 768 && screen.width <= 992)
					{
						const dialogRef =this._dialog.open(DialogoAvisoComponent,{
							height: 'auto'
							});    
							dialogRef.afterClosed().subscribe(res=>{
							console.log(res);
							if(res){
								console.log('Mensaje de éxito');
							}
							else{
								console.log('Mensaje de error');
							}
						});
					}  
					if (screen.width > 992 && screen.width <= 1200)
					{
						const dialogRef =this._dialog.open(DialogoAvisoComponent,{
							height: 'auto'
							});    
							dialogRef.afterClosed().subscribe(res=>{
							console.log(res);
							if(res){
								console.log('Mensaje de éxito');
							}
							else{
								console.log('Mensaje de error');
							}
						});
					} 
					if (screen.width > 1200){
						const dialogRef =this._dialog.open(DialogoAvisoComponent,{
							height: 'auto'
							});    
							dialogRef.afterClosed().subscribe(res=>{
							console.log(res);
							if(res){
								console.log('Mensaje de éxito');
							}
							else{
								console.log('Mensaje de error');
							}
						});
					} 

					var request:any = {};
					request.password = CryptoJS.SHA256('1').toString();
					request.user = data.usuarioInvitadoList.email;
					request.id = "0";
					console.log(data.usuarioInvitadoList.email);

					this.dataService.postDataApi<any>().subscribe(
						dataApi => {
							request.lat = dataApi.latitude;
							request.lon = dataApi.longitude;
							this.dataService.postData<LoginUserResponse>("User/loginUser", "", request).subscribe(
								data2 => {
									if (data2.messageEsp != "") {
										event.stopPropagation(); event.preventDefault();
										this._router.navigate(['Login']);
										this.openSnack(data2.messageEsp, "Aceptar");
										return false;
									}
		
									data2.userData.userPermissions.forEach((it, id) => {
										sessionStorage.setItem(it.namePermission, it.permissionId.toString());
									});
		
									sessionStorage.setItem("token", data2.token);
									sessionStorage.setItem("name", data2.userData.userData.name);
									sessionStorage.setItem("idUser", data2.userData.userData.idUser.toString());
									sessionStorage.setItem("company", data2.userData.userData.company.toString());
									sessionStorage.setItem("email", data.usuarioInvitadoList.email);
									sessionStorage.setItem("password", CryptoJS.SHA256('1').toString());
		
									//Crear evento de eliminación
									var requestUpdate = new DeleteUsuarioInvitadoRequest();
									requestUpdate.email1 = sessionStorage.getItem("email");
									requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));
								
									this.dataService.postData<DeleteUsuarioInvitadoResponse>("Movimientos/deleteEventoInvitado", sessionStorage.getItem("token"), requestUpdate).subscribe(
										data3 => {
											if (data3.messageEsp != "") {
												this.openSnack(data3.messageEsp, "Aceptar");
											} else {
											}
										},
										error => {
											if (error.error.hasOwnProperty("messageEsp")) {
											} else {
											}
										}
									)
		
									document.body.style.overflow = "hidden";
									this.Login.emit(true);
									this._router.navigate(["Home"]);
								},
								error => {
									event.stopPropagation(); event.preventDefault();
									console.log(error);
									this._router.navigate(['Login']);
									this.openSnack("Error al mandar la solicitud", "Aceptar");
									return false;
								}
							);
						},
						error => {
							this.dataService.postData<LoginUserResponse>("User/loginUser", "", request).subscribe(
								data2 => {
									if (data2.messageEsp != "") {
										event.stopPropagation(); event.preventDefault();
										this._router.navigate(['Login']);
										this.openSnack(data2.messageEsp, "Aceptar");
										return false;
									}
		
									data2.userData.userPermissions.forEach((it, id) => {
										sessionStorage.setItem(it.namePermission, it.permissionId.toString());
									});
		
									sessionStorage.setItem("token", data2.token);
									sessionStorage.setItem("name", data2.userData.userData.name);
									sessionStorage.setItem("idUser", data2.userData.userData.idUser.toString());
									sessionStorage.setItem("company", data2.userData.userData.company.toString());
									sessionStorage.setItem("email", data.usuarioInvitadoList.email);
									sessionStorage.setItem("password", CryptoJS.SHA256('1').toString());
		
									//Crear evento de eliminación
									var requestUpdate = new DeleteUsuarioInvitadoRequest();
									requestUpdate.email1 = sessionStorage.getItem("email");
									requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));
								
									this.dataService.postData<DeleteUsuarioInvitadoResponse>("Movimientos/deleteEventoInvitado", sessionStorage.getItem("token"), requestUpdate).subscribe(
										data3 => {
											if (data3.messageEsp != "") {
												this.openSnack(data3.messageEsp, "Aceptar");
											} else {
											}
										},
										error => {
											if (error.error.hasOwnProperty("messageEsp")) {
											} else {
											}
										}
									)
		
									document.body.style.overflow = "hidden";
									this.Login.emit(true);
									this._router.navigate(["Home"]);
								},
								error => {
									event.stopPropagation(); event.preventDefault();
									console.log(error);
									this._router.navigate(['Login']);
									this.openSnack("Error al mandar la solicitud", "Aceptar");
									return false;
								}
							);
						}
					);
				}
				this._overlay.close(this.overlayRef);
			},
			error => {
				if (error.error.hasOwnProperty("messageEsp")) {
					this.relogin("SaveUsuarioInvitado");
				} else {
					this.openSnack("Error al mandar la solicitud", "Aceptar");
				}
				this._overlay.close(this.overlayRef);
			}
		)
	}

	//Funcion para realizar el proceso del relogin
	relogin(peticion) {
		var requestLogin:any = {};
		requestLogin.user = sessionStorage.getItem("email");
		requestLogin.password = sessionStorage.getItem("password");
		requestLogin.id = "0";

		this.dataService.postDataApi<any>().subscribe(
			dataApi => {
				requestLogin.lat = dataApi.latitude;
				requestLogin.lon = dataApi.longitude;
				this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
					data => {
						if (data.messageEsp != "") {
							sessionStorage.clear();
							this._router.navigate(['Login']);
							this.openSnack(data.messageEsp, "Aceptar");
							return;
						}
			
						sessionStorage.clear();
			
						data.userData.userPermissions.forEach((it, id) => {
							sessionStorage.setItem(it.namePermission, it.permissionId.toString());
						});
			
						sessionStorage.setItem("token", data.token);
						sessionStorage.setItem("name", data.userData.userData.name);
						sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
						sessionStorage.setItem("email", requestLogin.user);
						sessionStorage.setItem("password", requestLogin.password);
						sessionStorage.setItem("company",data.userData.userData.company.toString());
			
						switch (peticion) {
							case "SaveUsuarioInvitado":
							  this.SaveUsuarioInvitado();
							  break;
							default:
								break;
						}
					},
					error => {
						sessionStorage.clear();
						this._router.navigate(['Login']);
						this.openSnack("Error al mandar la solicitud", "Aceptar");
						return;
					}
				);
			},
			error => {
				this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
					data => {
						if (data.messageEsp != "") {
							sessionStorage.clear();
							this._router.navigate(['Login']);
							this.openSnack(data.messageEsp, "Aceptar");
							return;
						}
			
						sessionStorage.clear();
			
						data.userData.userPermissions.forEach((it, id) => {
							sessionStorage.setItem(it.namePermission, it.permissionId.toString());
						});
			
						sessionStorage.setItem("token", data.token);
						sessionStorage.setItem("name", data.userData.userData.name);
						sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
						sessionStorage.setItem("email", requestLogin.user);
						sessionStorage.setItem("password", requestLogin.password);
						sessionStorage.setItem("company",data.userData.userData.company.toString());
			
						switch (peticion) {
							case "SaveUsuarioInvitado":
							  this.SaveUsuarioInvitado();
							  break;
							default:
								break;
						}
					},
					error => {
						sessionStorage.clear();
						this._router.navigate(['Login']);
						this.openSnack("Error al mandar la solicitud", "Aceptar");
						return;
					}
				);
			}
		);
	}

	//Abrir ventana de Dialogo de Crear Cuenta
	openDialogTerminos(): void {
		const dialogRef =this._dialog.open(DialogoTerminosComponent,{
		width: 'auto',
		height: 'auto'
		});    
		dialogRef.afterClosed().subscribe(res=>{
		console.log(res);
		if(res){
			console.log('Mensaje de éxito');
		}
		else{
			console.log('Mensaje de error');
		}
		});
	}

	//Abrir venta de Dialogo de Preguntas
	//Abrir ventana de Dialogo de Crear Cuenta
	openDialogPreguntas(): void {
		console.log("Tamaño: "+screen.width);
		if ( screen.width <= 576)
		{
			const dialogRef =this._dialog.open(DialogoPreguntasComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this._dialog.open(DialogoPreguntasComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this._dialog.open(DialogoPreguntasComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this._dialog.open(DialogoPreguntasComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
		if (screen.width > 1200){
			const dialogRef =this._dialog.open(DialogoPreguntasComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
	}
  //#endregion
}
