import {Component, ViewChild, ViewEncapsulation, OnInit, Inject, ChangeDetectorRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { BarcodeFormat } from '@zxing/library';

import { ServicesComponent, DataServices } from '../../Interfaces/Services/general.service';
import { DialogoRecibirBusComponent } from '../dialogo-recibir-bus/dialogo-recibir-bus.component';
import { DialogoRecibirComponent } from '../dialogo-recibir/dialogo-recibir.component';
import { SearchDataMovimientoGeneralProdRecepResponse, SearchDataMovimientoGeneralProdRecepRequest } from '../../Interfaces/Models/MovimientosModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { DialogoVerDetalleProductosComponent } from '../dialogo-ver-detalle-productos/dialogo-ver-detalle-productos.component';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-scan-qr',
  templateUrl: './scan-qr.component.html',
  styleUrls: ['./scan-qr.component.less']
})
export class ScanQrComponent implements OnInit {

  overlayRef: OverlayRef;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  scannerEnabled: boolean = true;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;
  selectedValue;
  tipoRecepcion: number = 1;
  envioModal: boolean = false;

  responseData2 = new SearchDataMovimientoGeneralProdRecepResponse();

  numeroProductos: any = { cantidad: 0 };
  numeroRemProductos: number = 0;

  constructor(
    private readonly _dialog: MatDialog, 
    private snack: MatSnackBar, 
    private _dialogRef: MatDialogRef<ScanQrComponent>,
    private _servicio: ServicesComponent,
    private _dataService: DataServices,
    private _overlay: OverlayService,
    @Inject(MAT_DIALOG_DATA) public _data: any
    ) { }

  ngOnInit() {
    this.tipoRecepcion = this._data.tipoRecepcion;
    this.envioModal = this._data.hasOwnProperty("envioModal") ? true : false;
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    resultString = decodeURIComponent(resultString);
    let result = "";
    for(let x=0;x<resultString.length; x++) {
      result += resultString[x].replace("[", '"').replace("-", "/").replace("'", "-").replace("Ñ", ":").replace("ñ",":").replace("*","}").replace("¿", "=").replace("_", "?").replace("¨", "{");
    };
    resultString = result;

    this.qrResultString = resultString.includes("qr=") ? resultString.split("qr=")[1] : resultString;
    if(this.qrResultString.includes('/')) {
      result = "";
      for(let x=0;x<this.qrResultString.length; x++) {
        result += this.qrResultString[x].replace('/', '-');
      };
      this.qrResultString = result;
    }
    let objJson = JSON.parse(this.qrResultString);
    if(objJson.T == "P" && !this.envioModal && ((this.tipoRecepcion == 1 || this.tipoRecepcion == 2) && !this._data.hasOwnProperty("isAgro") || this.tipoRecepcion == 2 && this._data.hasOwnProperty("isAgro"))) {
      this.snack.open('Favor de leer una caja o una agrupación','Aceptar', { duration: 10000});
      return;
    }
    if (this.qrResultString.length) {
      this.scannerEnabled = false;
      this._dialogRef.close(this.qrResultString);
      this.reproducir();
      if(!this.envioModal) { // False: Recepcion ; True: Envío
        this.openDialogRecibir();
      }
    } else {
      this.openSnack("El QR tiene una liga vacia o tiene un error", "Aceptar");
    }

  }

  openDialogRecibir(): void {
    this._servicio.codigoQR = this.qrResultString;

    // Si es parcial, buscar primero la lista de productos de la operación entrante
    if(this.tipoRecepcion == 2) {
      this.openDialogVerDetalleProductos();
    } else {
      let data: any = {
        codigoQR : this.qrResultString, 
        tipoRecepcion: this.tipoRecepcion
      }
      // Revisar si es agro
      if(this._data.hasOwnProperty("isAgro")) {
        data.isAgro = true;
      }
      let _dialogRef2 =this._dialog.open(DialogoRecibirBusComponent,{
        height: 'auto',
        disableClose: true,
        data: data
      });   
      
      _dialogRef2.afterClosed().subscribe(res=>{
        console.log(res);
        if(res) {
            console.log('Listo para buscar movimientos a recibir');
        } else{
            console.log('Error');
        }
      });
    }
    return;
  }

  openDialogVerDetalleProductos(): void {
    var request:any = {};
    let objJson = JSON.parse(this.qrResultString);
    request.codigoQR = this.qrResultString;
    request.codigoI = objJson.I;
    request.codigoF = objJson.F;

    if(parseInt(objJson.I, 16) && parseInt(objJson.F, 16)){
      let codigoIDecimal = parseInt(objJson.I, 16);
      let codigoFDecimal = parseInt(objJson.F, 16);

      request.codigoIHEXA  = parseInt(codigoIDecimal.toString().slice(6)).toString();
      request.codigoFHEXA = parseInt(codigoFDecimal.toString().slice(6)).toString();

    }else{
      request.codigoIHEXA = "0";
      request.codigoFHEXA = "0";
    }

    if(request.codigoIHEXA != "0" && request.codigoFHEXA != "0") {
      request.isHexa = true;
    }else{
      request.isHexa = false;  
    }
    if(request.codigoQR == "A"){
      request.codigoQR = this.qrResultString;
    }
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      request.isAgro = true;
    }

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this._dataService.postData<SearchDataMovimientoGeneralProdRecepResponse>("Movimientos/searchDataMovimientoGeneralProductoRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        this.responseData2 = data;
       
        if(this.responseData2.movimientosDataGeneralDesProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralOperaProdRecepList.length == 0
          && this.responseData2.movimientosDataGeneralProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralUnicoProdRecepList.length == 0) {
            this.snack.open('Productos no encontrados','Aceptar');
        } else {
          let _dialogoRef = this._dialog.open(DialogoVerDetalleProductosComponent, {
            panelClass: "dialog-min",
            data: {responseData2: this.responseData2, numeroProductos: { cantidad: 0 }}
          });
          _dialogoRef.afterClosed().subscribe(res=>{
            if(res.selectedProduct.hasOwnProperty("movimientoId")) {
              this._data.recepEvent.close();
              this.numeroProductos = res.selectedProduct;
              this.numeroRemProductos = res.selectedProduct.cantidad;
              
              let data: any = {
                codigoQR : this.qrResultString, 
                tipoRecepcion: this.tipoRecepcion, 
                numeroProductos: this.numeroProductos, 
                numeroRemProductos: this.numeroRemProductos,
                nProdsRestantes: this.numeroProductos.cantidad - (this.numeroProductos.merma + this.numeroProductos.productosRecibidos) <= 0 ? 0 : this.numeroProductos.cantidad - (this.numeroProductos.merma + this.numeroProductos.productosRecibidos)
              }
              // Revisar si es agro
              if(this._data.hasOwnProperty("isAgro")) {
                data.isAgro = true;
              }
              let _dialogRef2 =this._dialog.open(DialogoRecibirBusComponent,{
                height: 'auto',
                disableClose: true,
                data: data
              });
              
              _dialogRef2.afterClosed().subscribe(res=>{
                console.log(res);
                if(res) {
                    console.log('Listo para buscar movimientos a recibir');
                } else{
                    console.log('Error');
                }
              });
            }
          });
        }
      },
      error => {
        console.log(error);
        this._overlay.close(this.overlayRef);
      }
    );
  }

  reproducir() {
    const audio = new Audio('../../../assets/aud/sonidoEscaner.mp3');
    audio.play();
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  empezarCaptura() {
    this.scannerEnabled = true;
  }

  cerrarCaptura() {
    this.scannerEnabled = false;
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
      duration: 5000
    })
  }
}
