import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialogo-preguntas',
  templateUrl: './dialogo-preguntas.component.html',
  styleUrls: ['./dialogo-preguntas.component.css']
})
export class DialogoPreguntasComponent implements OnInit {

  constructor() { }

  nombre: string = "";
  apellido: string = "";
  correo: string = "";
  telefono: string = "";
  asunto: string = "";
  mensaje: string = ""

  ngOnInit() {
  }

  enviarCorreo(){
    alert ("<?php  funkx(); ?>");
  }

}
