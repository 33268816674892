import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import {
  SearchDataMovimientoGeneralRecepRequest,
  SearchDataMovimientoGeneralRecepResponse,

  SearchComboPaisEstadoRequest,
  SearchComboPaisEstadoResponse,

  SearchDataMovimientoDestinatarioRecepRequest,
  SearchDataMovimientoDestinatarioRecepResponse,

  EditDataMovimientoDestinatarioRequest,
  EditDataMovimientoDestinatarioResponse,
  SaveEstadosSiNoExisteResponse,
  SearchExistenciaEstadoResponse,
  SearchExistenciaEstadoRequest,
  SaveEstadosSiNoExisteRequest,
  SearchDataMovimientoGeneralRequest,
} from '../../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-dialogo-info-destinatario',
  templateUrl: './dialogo-info-destinatario.component.html',
  styleUrls: ['./dialogo-info-destinatario.component.css']
})
export class DialogoInfoDestinatarioComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoInfoDestinatarioComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
  ) { }

  movimientoId : string = "";
  compania: string = "";
  nombreContacto: string="";
  apellidoContacto: string="";
  telefono: string ="";
  ciudad: string="";
  codigoPostal:number = 0;
  direccion:string="";
  numeroC: string ="";
  rancho : string ="";
  sector : string="";
  paisId : number = 0;
  estadoId : number = 0;
  responseNombre = new SearchDataMovimientoGeneralRecepResponse();
  responsePaisEstado = new SearchComboPaisEstadoResponse();
  responseData = new SearchDataMovimientoDestinatarioRecepResponse();
  overlayRef: OverlayRef;

  responseGuardarEstado = new SaveEstadosSiNoExisteResponse();
  responseExistenciaEstado = new SearchExistenciaEstadoResponse();
  nombreEstado : string ="";
  //NG AUTOCOMPLETE PAIS
  public keyword = "data";
  initialPais = {
    id: 241,
    data: 'Seleccione un Pais',
  }

  initialEstado = {
    id: 36,
    data: 'Seleccione un Estado',
  }

  selectEvent(item) {
    this.paisId = item.id;
    this.nombreEstado = '';
    this.BusquedaCombos2();
  }
  onClearedSearch(){
    this.nombreEstado = '';
    this.paisId = 241;
  }

  //NG AUTOCOMPLETE ESTADO
  public keywordE = "data";
  selectEventE(item) {
    this.nombreEstado = '';
    this.estadoId = item.id;
  }

  onChangeSearchE(item){
    this.nombreEstado = item;
  }

  onClearedSearchE(){
    this.nombreEstado = '';
    this.estadoId = 36;
  }

  ngOnInit() {
    this.movimientoId = this._data.id + "";
    if(this.movimientoId == "0") {
      if(this._data.hasOwnProperty("domicilio")) {
        this.numeroC = this._data.domicilio.numeroC;
        this.fillAddress();
      } else {
        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        this.BusquedaCombos();
      }
    }else {
      this.BusquedaNombre();
    }
  }


  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaNombre() {
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = parseInt(this.movimientoId);

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<any>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseNombre = data;
        this.Busqueda();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoDestinatarioRecepRequest();
    request.codigoQR = this._data.id + "";

    this.dataService.postData<SearchDataMovimientoDestinatarioRecepResponse>("Movimientos/searchDataMovimientoDestinatarioRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this.compania = this.responseData.movimientosDataDestinatarioRecepList.nombreCompaniaD;
        this.nombreContacto = this.responseData.movimientosDataDestinatarioRecepList.nombreDestinatario;
        this.apellidoContacto = this.responseData.movimientosDataDestinatarioRecepList.apellidoDestinatario;
        this.telefono = this.responseData.movimientosDataDestinatarioRecepList.telefonoD;
        this.ciudad = this.responseData.movimientosDataDestinatarioRecepList.ciudadD;
        this.codigoPostal = parseInt(this.responseData.movimientosDataDestinatarioRecepList.cpD);
        this.direccion = this.responseData.movimientosDataDestinatarioRecepList.domicilioD;
        this.numeroC = this.responseData.movimientosDataDestinatarioRecepList.numeroC;
        this.rancho = this.responseData.movimientosDataDestinatarioRecepList.ranchoD;
        this.sector = this.responseData.movimientosDataDestinatarioRecepList.sectorD;
        
        this.initialPais.id=this.responseData.movimientosDataDestinatarioRecepList.paisD;
        this.paisId = this.responseData.movimientosDataDestinatarioRecepList.paisD;
        this.initialPais.data=this.responseData.movimientosDataDestinatarioRecepList.paisND;
        this.initialEstado.id=this.responseData.movimientosDataDestinatarioRecepList.estadoD;
        this.estadoId = this.responseData.movimientosDataDestinatarioRecepList.estadoD;
        this.initialEstado.data=this.responseData.movimientosDataDestinatarioRecepList.estadoND;
        this.BusquedaCombos();
        
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaCombos() {
    var request = new SearchComboPaisEstadoRequest();
    request.paisId = this.paisId;
    //request.paisId = this.responseData.movimientosDataDestinatarioRecepList.paisD;

    this.dataService.postData<SearchComboPaisEstadoResponse>("Movimientos/searchPaisEstadoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responsePaisEstado = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaCombos");
        } else {
          console.log(error);
        }
      }
    )
  }

  //Posee  barra de carga
  BusquedaCombos2() {
    var request = new SearchComboPaisEstadoRequest();
    request.paisId = this.paisId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, 1);

    this.dataService.postData<SearchComboPaisEstadoResponse>("Movimientos/searchPaisEstadoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responsePaisEstado = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaCombos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    )
  }

  cleanDestinatario(){
    this.initialPais = {
      id: 241,
      data: 'Seleccione un Pais',
    }
  
    this.initialEstado = {
      id: 36,
      data: 'Seleccione un Estado',
    }
    this.paisId = 241;
    this.estadoId = 36;
    this.ciudad = "";
    this.codigoPostal = 0;
    this.direccion = "";
    this.nombreContacto = "";
    this.apellidoContacto = "";
    this.compania = "";
    this.telefono = "";
    this.numeroC = "";
    this.rancho = "";
    this.sector = "";
  }

  fillAddress() {
    this.paisId = this._data.domicilio.paisD;
    this.estadoId = this._data.domicilio.estadoD;
    this.ciudad = this._data.domicilio.ciudadD;
    this.codigoPostal = this._data.domicilio.cpD;
    this.direccion = this._data.domicilio.domicilioD;
    this.nombreContacto = this._data.domicilio.nombreDestinatario;
    this.apellidoContacto = this._data.domicilio.apellidoDestinatario;
    this.compania = this._data.domicilio.rzCompaniaD;
    this.telefono = this._data.domicilio.telefonoD;
    this.numeroC = this._data.domicilio.numeroC;
    this.rancho = this._data.domicilio.ranchoD;
    this.sector = this._data.domicilio.sectorD;
    this.initialEstado = this._data.domicilio.initialEstado;
    this.initialPais = this._data.domicilio.initialPais;
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaNombre":
                  this.BusquedaNombre();
                  break;
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                case "SaveDestinatario":
                    this.SaveDestinatario();
                    break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaNombre":
                  this.BusquedaNombre();
                  break;
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                case "SaveDestinatario":
                    this.SaveDestinatario();
                    break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  SaveDestinatario(){
    // No guardar en caso de que no se encuentre el movimiento id
    if(this.movimientoId == "0"){
      this.dialogRef.close({
        domicilio: {
          paisD: this.paisId,
          estadoD: this.estadoId,
          ciudadD: this.ciudad,
          cpD: this.codigoPostal,
          domicilioD: this.direccion,
          nombreDestinatario: this.nombreContacto,
          apellidoDestinatario: this.apellidoContacto,
          nombreCompaniaD: this.compania,
          rzCompaniaD: this.compania,
          telefonoD: this.telefono,
          numeroC: this.numeroC,
          ranchoD: this.rancho,
          sectorD: this.sector,
          initialEstado: this.initialEstado,
          initialPais: this.initialPais
        }
      });
      return;
    }

    var request = new SearchExistenciaEstadoRequest();
    request.pais = this.paisId;
    if(this.nombreEstado!=''){
      request.nombreEstado = this.nombreEstado;
    }
    else{
      request.nombreEstado = this.estadoId.toString();
    }
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchExistenciaEstadoResponse>("Movimientos/searchExistenciaEstado", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseExistenciaEstado = data;
        
        //Si no existe el dato
        if(this.responseExistenciaEstado.existenciaEstadoList.bandera == false){
          var requestUpdate2 = new SaveEstadosSiNoExisteRequest();
          requestUpdate2.pais = this.paisId;
          requestUpdate2.nombreEstado = this.nombreEstado;

          this.dataService.postData<SaveEstadosSiNoExisteResponse>("Movimientos/saveEstadosSiNoExiste", sessionStorage.getItem("token"), requestUpdate2).subscribe(
              data => {
                  if (data.messageEsp != "") {
                      this.openSnack(data.messageEsp, "Aceptar");
                      this._overlay.close(this.overlayRef);
                  } else {
                    this.openSnack("Estado agregado, favor de seleccionarlo", "Aceptar");
                    this._overlay.close(this.overlayRef)
                  }
              },
              error => {
                  if (error.error.hasOwnProperty("messageEsp")) {
                      this.relogin("SaveDocs");
                  } else {
                      this.openSnack("Error al mandar la solicitud", "Aceptar");
                  }
                  this._overlay.close(this.overlayRef);
              }
          );
        }
        //Si existe, solo guarda las variables
        else{
          console.log('Si existe');
          var requestUpdate = new EditDataMovimientoDestinatarioRequest();
          requestUpdate.movimientoId = this.responseData.movimientosDataDestinatarioRecepList.movimientoId;
          requestUpdate.nombreDestinatario = this.nombreContacto;
          requestUpdate.apellidoDestinatario = this.apellidoContacto;
          requestUpdate.nombreCompaniaD = this.compania;
          requestUpdate.rzCompaniaD = this.compania;
          requestUpdate.telefonoD = this.telefono;
          requestUpdate.paisD = this.paisId;
          requestUpdate.estadoD = this.estadoId;
          requestUpdate.ciudadD = this.ciudad;
          requestUpdate.cpD = this.codigoPostal + "";
          requestUpdate.domicilioD = this.direccion;
          requestUpdate.ranchoD = this.rancho;
          requestUpdate.sectorD = this.sector;

          setTimeout(() => {
            this.overlayRef = this._overlay.open();
          }, .001);

          this.dataService.postData<EditDataMovimientoDestinatarioResponse>("Movimientos/editDataMovimientoDestinatario", sessionStorage.getItem("token"), requestUpdate).subscribe(
              data => {
                  if (data.messageEsp != "") {
                      this.openSnack(data.messageEsp, "Aceptar");
                  } else {
                      this.openSnack("Datos de destinatario guardados con éxito", "Aceptar");
                      this.dialogRef.close(true);
                  }
                  this._overlay.close(this.overlayRef);
              },
              error => {
                  if (error.error.hasOwnProperty("messageEsp")) {
                      this.relogin("SaveDestinatario");
                  } else {
                      this.openSnack("Error al mandar la solicitud", "Aceptar");
                  }
                  this._overlay.close(this.overlayRef);
              }
          )
          this._overlay.close(this.overlayRef)
        }
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
}
