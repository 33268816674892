import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog } from '@angular/material';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import {
  SearchDataFichasSeleccionadasRequest,
  SearchDataFichasSeleccionadasResponse,
  SearchDataFichaResponse2,

  SaveProdDescResponse,
  SaveProdDescRequest
} from '../../Interfaces/Models/AgrupacionesModels';
import { ServicesComponent } from '../../Interfaces/Services/general.service';
import { DialogoCreeCuentaComponent } from '../../header-invitado/dialogo-cree-cuenta/dialogo-cree-cuenta.component';


@Component({
  selector: 'app-dialogo-producto-desconocido',
  templateUrl: './dialogo-producto-desconocido.component.html',
  styleUrls: ['./dialogo-producto-desconocido.component.css']
})
export class DialogoProductoDesconocidoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoProductoDesconocidoComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public _servicio : ServicesComponent,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService
  ) { }

  nombre : string = "";
  cantidad : number = 0;
  lote : string = "";
  fechaCaducidad : string = "";
  numSerie : string = "";
  overlayRef: OverlayRef;
  responseSeleccionadas = new SearchDataFichasSeleccionadasResponse();

  ngOnInit() {
  }

  SaveProdDesc(){

    var requestUpdate = new SaveProdDescRequest();
    requestUpdate.nombre = this.nombre;
    requestUpdate.cantidad = this.cantidad;
    requestUpdate.lote = this.lote;
    requestUpdate.fechaCaducidad = this.fechaCaducidad;
    requestUpdate.numSerie = this.numSerie;
    requestUpdate.codigo = this._data.codigo
    requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SaveProdDescResponse>("Agrupaciones/saveProdDesc", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
              this.openSnack(data.messageEsp, "Aceptar");
              this.reloadPage();
              if(sessionStorage.getItem("name") == ''){
                this.openDialogRegistrate();
              }
                this._overlay.close(this.overlayRef);
            } else {
              this.openSnack("Producto guardado exitosamente", "Aceptar");
              this._overlay.close(this.overlayRef);
              this.reloadPage();
              if(sessionStorage.getItem("name") == ''){
                this.openDialogRegistrate();
              }
            }
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("SaveFichaTemp");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }

  reloadPage() { // click handler or similar
        location.reload();
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin: any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "SaveProdDesc":
                  this.SaveProdDesc();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "SaveProdDesc":
                  this.SaveProdDesc();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  openDialogRegistrate(): void {
    const dialogRef =this.dialog.open(DialogoCreeCuentaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Mensaje de éxito');
      }
      else{
        console.log('Mensaje de error');
      }
    });
  }
}
