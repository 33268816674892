import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '../material/material.module';
import { OverlayService } from '../Interfaces/Services/overlay.service';


@NgModule({
    declarations: [],
    imports: [
        MaterialModule,
        BrowserModule,
        RouterModule,
        CommonModule
	],
	providers: [OverlayService]
})
export class HomeModule { }
