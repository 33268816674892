import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DataServices } from '../../Interfaces/Services/general.service';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { PdfMakeWrapper, 
  Table, 
  Img, 
  Columns,
  Cell,
  Txt, } from 'pdfmake-wrapper';
import { SearchDataMovimientoDestinatarioRequest, SearchDataMovimientoDestinatarioResponse, SearchDataMovimientoRemitenteRequest, SearchDataMovimientoRemitenteResponse, SearchDataMovimientoTransportistaRequest, SearchDataMovimientoTransportistaResponse, SearchDataMovimientoInfoLegalRequest, SearchDataMovimientoInfoLegalResponse, SearchDocDetalleProductosRequest, SearchDocDetalleProductosResponse, SearchDocDetalleProductosIndiRequest, SearchDocDetalleProductosIndiResponse, SearchDocDetalleProductosCajasResponse, SearchDocDetalleTotalProdResponse, SearchDocDetalleTotalPalletResponse, SearchDocDetalleTotalCajasResponse, SearchDocDetalleTotalCantidadResponse, SearchDocDetalleTotalPesoResponse, SearchDocDetalleFechaMinResponse, SearchDataMovimientoObservacionesRequest, SearchDataMovimientoObservacionResponse, SearchDataMovimientoGeneralResponse } from '../../Interfaces/Models/MovimientosModels';
import { LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { enviroments } from '../../Interfaces/Enviroments/enviroments';

@Component({
  selector: 'app-dialogo-envio-registrado',
  templateUrl: './dialogo-envio-registrado.component.html',
  styleUrls: ['./dialogo-envio-registrado.component.css']
})
export class DialogoEnvioRegistradoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoEnvioRegistradoComponent>,
    private _router: Router,
    private dataService: DataServices,
    private snack: MatSnackBar,
    private _overlay: OverlayService,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

  overlayRef: OverlayRef;

  nombre:string = '';
  movimientoId:number = 0;
  codigoQR:string = "";

  /* Variables necesarias para la generación del pdf */
  responseData = new SearchDataMovimientoGeneralResponse();
  responseDataDes = new SearchDataMovimientoDestinatarioResponse();
  responseDataRem = new SearchDataMovimientoRemitenteResponse();
  responseDataTrans = new SearchDataMovimientoTransportistaResponse();
  responseDataInfo = new SearchDataMovimientoInfoLegalResponse();

  responseDocsProductos = new SearchDocDetalleProductosResponse();
  responseDocsProductosIndi = new SearchDocDetalleProductosIndiResponse();
  responseDocsProductosCajas = new SearchDocDetalleProductosCajasResponse();
  responseDocsTotalProd = new SearchDocDetalleTotalProdResponse();
  responseDocsTotalPallet = new SearchDocDetalleTotalPalletResponse();
  responseDocsTotalCajas = new SearchDocDetalleTotalCajasResponse();
  responseDocsTotalCantidad = new SearchDocDetalleTotalCantidadResponse();
  responseDocsTotalPeso = new SearchDocDetalleTotalPesoResponse();
  responseDocsFechaMin= new SearchDocDetalleFechaMinResponse();
  responseDataComentario = new SearchDataMovimientoObservacionResponse();
  
  ngOnInit() {
    this.nombre = this._data.nombre;
    if(this._data.hasOwnProperty("movimientoId")){
      this.movimientoId = this._data.movimientoId;
      this.codigoQR = this._data.codigoQR;
      this.responseData = this._data.responseData;
    }
  }

  recargarHome(){
    this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
    this._router.navigate(["Home"]));
    this.dialogRef.close(true);
  }

  generarPDF(){
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.BusquedaDes();
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaDes() {
    
    var request = new SearchDataMovimientoDestinatarioRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoDestinatarioResponse>("Movimientos/searchDataMovimientoDestinatario", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataDes = data; 
        this.BusquedaRem();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDes");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaRem() {
    
    var request = new SearchDataMovimientoRemitenteRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoRemitenteResponse>("Movimientos/searchDataMovimientoRemitente", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataRem = data;
        this.BusquedaTrans();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaRem");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaTrans() {
    
    var request = new SearchDataMovimientoTransportistaRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportista", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataTrans = data;
        this.BusquedaInfo();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaTrans");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaInfo() {
    
    var request = new SearchDataMovimientoInfoLegalRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoInfoLegalResponse>("Movimientos/searchDataMovimientoInfoLegal", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataInfo = data;
        this.BusquedaDocsProductos();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaDocsProductos() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductos", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        //this.BusquedaDetalleProductos();
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  /*BusquedaDetalleProductos() { // Productos tabla Pallet
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = this.codigoQR;

    this.dataService.postData<SearchDocDetalleProductosIndiResponse>("Movimientos/searchDocDetalleProductosIndi", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosIndi = data;
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/

  BusquedaDetalleProductos2() { // Productos tabla Cajas
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = ''+this.movimientoId;

    //Obtener valor de rango en formato Json
    if(JSON.parse('{ "return":'+this.codigoQR+' }')){
      let objJson = JSON.parse('{ "return":'+this.codigoQR+' }')
      let inicial = objJson.return.I;
      let final = objJson.return.F;
      let tipo = objJson.return.T;

      request.codigoTipo = tipo;
      request.codigoI = inicial;
      request.codigoF = final;
    }
    

    this.dataService.postData<SearchDocDetalleProductosCajasResponse>("Movimientos/searchDocDetalleProductosCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosCajas = data;
        this.BusquedaDocsTotalProd();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaDocsTotalProd() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalProdResponse>("Movimientos/searchDocDetalleTotalProd", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalProd = data;
        //this.BusquedaDocsTotalPallet();
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalProd");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  /*BusquedaDocsTotalPallet() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPalletResponse>("Movimientos/searchDocDetalleTotalPallet", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPallet = data;
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPallet");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/

  BusquedaDocsTotalCajas() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCajasResponse>("Movimientos/searchDocDetalleTotalCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCajas = data;
        this.BusquedaDocsTotalCantidad();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCajas");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaDocsTotalCantidad() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCantidadResponse>("Movimientos/searchDocDetalleTotalCantidad", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCantidad = data;
        this.BusquedaDocsTotalPeso();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCantidad");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaDocsTotalPeso() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPesoResponse>("Movimientos/searchDocDetalleTotalPeso", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPeso = data;
        this.BusquedaDocsFechaMin();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPeso");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaDocsFechaMin() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleFechaMinResponse>("Movimientos/searchDocDetalleFechaMin", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsFechaMin = data;
        this.BusquedaComentario();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsFechaMin");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaComentario() {
      
    var request = new SearchDataMovimientoObservacionesRequest();
    request.movimientoId = this.movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcion", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataComentario = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'Guía de Embarque',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 60, 20, 30 ]);

    let tableHeader = new Table([
        [ {text:this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, bold:true, fontSize:18}
        ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;

    if(this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR == "") {
      tableHeader = new Table([
        [ new Cell( new Txt(' ').bold().fontSize(12).end).fillColor("#012152").end ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;
    }

    pdf.header(
      new Columns([
        tableHeader,
        await new Img('../../assets/img/img-logo-stock-3.png').width('100').margin([-20,20,20,0]).build()
      ]).end,
    );

    pdf.add(
      pdf.ln(1)
    );

    let textCajaId = this.responseDocsProductosCajas.docDetalleProductoCajasList.length > 0 ? this.responseDocsProductosCajas.docDetalleProductoCajasList[0].codigoCaja.split(',')[0] : '';

    pdf.add(
      new Columns([
        new Table([
          ['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],
          [ {text: 'Guía de Embarque', bold:true, alignment:'left', fontSize:16, colSpan: 3} ],
          ['','',''],['','',''], ['','',''],  ['','',''],['','',''],  ['','',''],  ['','',''],  
          [ {text:'OP Interna: ', alignment:'right', bold:true}, 
            {text: this.responseData.movimientosDataGeneralRecepList.referenciaInterna, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'No. cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.numeroC, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'OP Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseData.movimientosDataGeneralRecepList.referenciaExterna, alignment:'left', color: 'gray'},
            ''
          ]
        ]).widths([80, 150, 40]).fontSize(9).layout('noBorders').end,
        new Table([
          [ {qr: enviroments.qrUrl + this.codigoQR, alignment:'center', fit:100, colSpan:2},
            ''
          ],
          [ {text: textCajaId, color: 'gray', colSpan:2}]
        ]).widths([80, 150]).alignment('center').fontSize(9).layout('noBorders').end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    // Domicilio Destinatario
    let fullDomicilioDest = this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD;
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '') 
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '');
    // Domicilio Remitente
    let fullDomicilioRem = this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR;
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? ', C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? 'C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '') 
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '');

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Destinatario: ', alignment:'right', bold:true, fontSize:11}, 
            {text: /*this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+*/
                   this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioDest, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Remitente: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioRem, alignment:'left', color: 'gray'} 
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
          {
            text: this.responseDataRem.movimientosDataRemitenteRecepList.atendioUsuario, alignment:'left', color: 'gray'
          }
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Transporte: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.transportista, alignment:'left', color: 'gray'}
          ],
          [ {text:'Fecha embarque: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, alignment:'left', color: 'gray'}
          ]
          ,
          [ {text:'Referencia: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.numReferencia, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Observaciones: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.observacion, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dimensiones por caja (cms): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.dimensionesCaja, alignment:'left', color: 'gray'}
          ],
          [ {text:'Peso por caja (Kg): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.pesoCaja, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Resumen de Productos/Pedido: ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
          ],
          [ {text:'Producto/Familia: ', bold:true}, 
            {text: 'Marca: ', bold:true},
            {text: 'GTIN: ', bold:true},
            {text: 'Pallets: ', bold:true},
            {text: 'Cajas por pallet: ', bold:true},
            {text: 'Unidades por caja: ', bold:true},
            {text: 'Total de unidades: ', bold:true},
            {text: 'Caducidad prox: ', bold:true},
          ]
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(9).end,
      ]).end
    );

    //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      var a;
      if(products.numPallet==0){
        a=1;
      }else{
        a=products.numPallet;
      }
      pdf.add(
        new Table([
          [ {text:products.producto, alignment:'right'}, 
            {text: products.ciu, alignment:'right'}, 
            products.gtin,
            0, 
            products.numCajas/a, 
            products.cantidad/products.numCajas, 
            products.cantidad, 
            products.fechaCaducidad
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );
    }

    pdf.add(
      new Table([
        [ 
          {text:'', bold:true, colSpan:9, fontSize:11, alignment:'left',pageBreak: 'before'}, 
            '','','','','','','',''
        ]
      ]).widths([120, 40, 70, 40, 40,40,40,40,40]).alignment('center').fontSize(9). layout('noBorders').end
    );

    /*pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );
    
    if(this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Pallets', colSpan:9, bold:true}, 
          '','','','','','','',''
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let pallets of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
        pdf.add(
          new Table([
            [ {text: pallets.producto, bold:true}, 
            {text: pallets.ciu, bold:true}, 
            {text: pallets.gtin, bold:true}, 
            {text: pallets.tipoEmpaque, bold:true}, 
            {text: pallets.cantidad, bold:true}, 
            {text: pallets.codigoPallet, bold:true} ,
            {text: ' ', bold:true} ,
            {text: pallets.lote, bold:true} ,
            {text: pallets.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
        );
      }
    }*/

    pdf.add(
      pdf.ln(2)
    );
    
    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          //{text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,80,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Cajas', bold:true}
        ]
      ]).widths(['*']).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let cajas of this.responseDocsProductosCajas.docDetalleProductoCajasList) {
        pdf.add(
          new Table([
            [ {text: cajas.producto, bold:true}, 
            {text: cajas.ciu, bold:true}, 
            {text: cajas.gtin, bold:true}, 
            {text: cajas.tipoEmpaque, bold:true}, 
            {text: cajas.cantidad/cajas.numCajas, bold:true}, 
            //{text: cajas.codigoPallet, bold:true} ,
            {text: cajas.codigoCaja.includes("X") ? cajas.codigoCaja.split("X").join("") : cajas.codigoCaja, bold:true} ,
            {text: cajas.lote, bold:true} ,
            {text: cajas.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,80,40,50]).alignment('center').fontSize(8).end
        );
      }
    }

    /*for (let products of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 120, 80, 80, 80, 80]).alignment('center').fontSize(9).end
      );
    }*/


    pdf.footer(
      new Columns([
        new Table(
        [
          [ 
            {text:'Controla tus inventarios y automatiza las comunicaciones de tu almacen con Traceit Stock   https://stock.traceit.net' }]
        ]).widths(['*']).alignment('center').layout('noBorders').fontSize(7).end
      ]).end


    );

    if ( screen.width <= 576)
		{
			pdf.create().download();
		}
		if (screen.width > 576 && screen.width <= 768)
		{
      pdf.create().download();
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			pdf.create().open();
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
      pdf.create().open();
      //document.getElementById("frame1").src = pdf.create().getDataUrl();
		} 
		if (screen.width > 1200){
			pdf.create().open();
    }
  }

  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            this.generarPDF();
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            this.generarPDF();
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

}
