import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DialogoProductoDesconocidoComponent } from '../dialogo-producto-desconocido/dialogo-producto-desconocido.component';

@Component({
  selector: 'app-dialogo-producto-no-registrado',
  templateUrl: './dialogo-producto-no-registrado.component.html',
  styleUrls: ['./dialogo-producto-no-registrado.component.css']
})
export class DialogoProductoNoRegistradoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoProductoNoRegistradoComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openDialogProductoDesconocido(): void {
    const dialogRef =this.dialog.open(DialogoProductoDesconocidoComponent,{
      width: 'auto',
      height: 'auto',
      data: {
        codigo: this._data.codigo
    }
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

}
