import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ServicesComponent } from '../../Interfaces/Services/general.service';

@Component({
  selector: 'app-dialogo-recepcion-registrada',
  templateUrl: './dialogo-recepcion-registrada.component.html',
  styleUrls: ['./dialogo-recepcion-registrada.component.css']
})
export class DialogoRecepcionRegistradaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoRecepcionRegistradaComponent>,
    private _router: Router,
    private _servicio: ServicesComponent,
    @Inject(MAT_DIALOG_DATA) public message: string
  ) { }

  ngOnInit() {
  }

  recargarHome(){
    this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
    this._router.navigate(["Home"]));
    this.dialogRef.close(true);
    this._servicio.codigoQR = " ";
  }

}
