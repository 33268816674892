import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../Interfaces/Services/general.service';
import { Router } from '@angular/router';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayRef } from '@angular/cdk/overlay';

//Servicio para datos de direccion
import { ServicesComponent } from '../../Interfaces/Services/general.service';

import {
  SaveEstadosSiNoExisteRequest,
  SaveEstadosSiNoExisteResponse,
  SearchComboPaisEstadoRequest,
  SearchComboPaisEstadoResponse,
  SearchExistenciaEstadoRequest,
  SearchExistenciaEstadoResponse,
} from '../../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-dialogo-agregar-direccion',
  templateUrl: './dialogo-agregar-direccion.component.html',
  styleUrls: ['./dialogo-agregar-direccion.component.css']
})
export class DialogoAgregarDireccionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoAgregarDireccionComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
    public _servicio : ServicesComponent,
		private _router: Router,
    private _overlay: OverlayService,
  ) { }

  pais : number = 241;
  nombreEstado : string ="";
  estado : number = 36;
  direccion : string = '';
  codigoPostal : string = '';
  ciudad : string = '';
  responsePaisEstado = new SearchComboPaisEstadoResponse();
  responseGuardarEstado = new SaveEstadosSiNoExisteResponse();
  responseExistenciaEstado = new SearchExistenciaEstadoResponse();
  overlayRef: OverlayRef;

  dataEstado:string='';
  dataPais:string='';

  //NG AUTOCOMPLETE PAIS
  public keyword = "data";
  initialPais = {
    id: 241,
    data: 'Seleccione un Pais',
  }

  initialEstado = {
    id: 36,
    data: 'Seleccione un Estado',
  }

  selectEvent(item) {
    this.pais = item.id;
    this.dataPais = item.data;
    this.nombreEstado = '';
    this.BusquedaCombos();
  }
  onClearedSearch(){
    this.nombreEstado = '';
    this.pais = 241;
  }

  //NG AUTOCOMPLETE ESTADO
  public keywordE = "data";
  selectEventE(item) {
    this.nombreEstado = '';
    this.estado = item.id;
    this.dataEstado = item.data;
  }

  onChangeSearchE(item){
    this.nombreEstado = item;
  }

  onClearedSearchE(){
    this.nombreEstado = '';
    this.estado = 36;
  }

  ngOnInit() {
    this.ciudad = this._servicio.ciudad;
    this.codigoPostal = this._servicio.codigoPostal;
    this.direccion = this._servicio.direccion;

    this.initialPais.id=parseInt(this._servicio.nombrePais);
    this.pais = parseInt(this._servicio.nombrePais);
    this.dataPais=this._servicio.dataPais;
    this.initialPais.data=this._servicio.dataPais;
    this.initialEstado.id=parseInt(this._servicio.nombreEstado);
    this.estado = parseInt(this._servicio.nombreEstado);
    this.dataEstado=this._servicio.dataEstado;
    this.initialEstado.data=this._servicio.dataEstado;
    this.BusquedaCombos();
  }

  BusquedaCombos() {
    var request = new SearchComboPaisEstadoRequest();
    request.paisId = this.pais;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, 1);

    this.dataService.postData<SearchComboPaisEstadoResponse>("Movimientos/searchPaisEstadoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responsePaisEstado = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaCombos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    )
  }

  datosDireccion(){

    var request = new SearchExistenciaEstadoRequest();
    request.pais = this.pais;
    if(this.nombreEstado!=''){
      request.nombreEstado = this.nombreEstado;
    }
    else{
      request.nombreEstado = this.estado.toString();
    }
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchExistenciaEstadoResponse>("Movimientos/searchExistenciaEstado", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseExistenciaEstado = data;
        
        //Si no existe el dato
        if(this.responseExistenciaEstado.existenciaEstadoList.bandera == false){
          var requestUpdate = new SaveEstadosSiNoExisteRequest();
          requestUpdate.pais = this.pais;
          requestUpdate.nombreEstado = this.nombreEstado;

          this.dataService.postData<SaveEstadosSiNoExisteResponse>("Movimientos/saveEstadosSiNoExiste", sessionStorage.getItem("token"), requestUpdate).subscribe(
              data => {
                  if (data.messageEsp != "") {
                      this.openSnack(data.messageEsp, "Aceptar");
                      this._overlay.close(this.overlayRef);
                  } else {
                    this._servicio.nombrePais = this.pais.toString();
                    this._servicio.nombreEstado = this.estado.toString();
                    this._servicio.dataPais = this.dataPais;
                    this._servicio.dataEstado = this.dataEstado;
                    this._servicio.ciudad = this.ciudad;
                    this._servicio.codigoPostal = this.codigoPostal;
                    this._servicio.direccion = this.direccion;
                    this.openSnack("Estado agregado, favor de seleccionarlo", "Aceptar");
                    this.openDialogAddDireccion();
                    this._overlay.close(this.overlayRef)
                  }
              },
              error => {
                  if (error.error.hasOwnProperty("messageEsp")) {
                      this.relogin("SaveDocs");
                  } else {
                      this.openSnack("Error al mandar la solicitud", "Aceptar");
                  }
                  this._overlay.close(this.overlayRef);
              }
          );
        }
        //Si existe, solo guarda las variables
        else{
          console.log('Si existe');
          this._servicio.nombrePais = this.pais.toString();
          this._servicio.nombreEstado = this.estado.toString();
          this._servicio.dataPais = this.dataPais;
          this._servicio.dataEstado = this.dataEstado;
          this._servicio.ciudad = this.ciudad;
          this._servicio.codigoPostal = this.codigoPostal;
          this._servicio.direccion = this.direccion;
          this._overlay.close(this.overlayRef)
        }
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  openDialogAddDireccion(): void {
    console.log("Tamaño: "+screen.width);
		if ( screen.width <= 576)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        width: 'auto',
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        width: 'auto',
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		} 
		if (screen.width > 1200){
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
    } 
  }

}
