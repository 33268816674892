import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import {
  SearchDataMovimientoTransportistaRequest,
  SearchDataMovimientoTransportistaResponse,

  EditDataMovimientoTransportistaRequest,
  EditDataMovimientoTransportistaResponse
} from '../../Interfaces/Models/MovimientosModels';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dialogo-info-transporte',
  templateUrl: './dialogo-info-transporte.component.html',
  styleUrls: ['./dialogo-info-transporte.component.css']
})
export class DialogoInfoTransporteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoInfoTransporteComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
  ) { }

  movimientoId : number = 0;
  transportista: string = "";
  numReferencia: string = "";
  responseData = new SearchDataMovimientoTransportistaResponse();
  overlayRef: OverlayRef;

  today= new Date();
  fechaEmbarque: string = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '0600');
  fechaEmbarqueModel: string = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '0600');

  ngOnInit() {
    this.movimientoId = parseInt(this._data.id);
    if(this.movimientoId == 0) {
      if(this._data.hasOwnProperty("infoTransportista")) {
        this.fillAddress();
      }
    } else {
      this.Busqueda();
    }
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoTransportistaRequest();
    request.movimientoId = this.movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportista", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this.transportista = this.responseData.movimientosDataTransportistaList.transportista;
        this.numReferencia = this.responseData.movimientosDataTransportistaList.numReferencia;
        this.fechaEmbarqueModel = this.responseData.movimientosDataTransportistaList.fechaEmbarque;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  cleanTransportista(){
    this.transportista = "";
    this.numReferencia = "";
    this.fechaEmbarque = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '0600');
  }

  fillAddress() {
    this.transportista = this._data.infoTransportista.transportista;
    this.numReferencia = this._data.infoTransportista.numReferencia;
    this.fechaEmbarqueModel = this._data.infoTransportista.fechaEmbarque;
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "SaveTransportista":
                    this.SaveTransportista();
                    break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "SaveTransportista":
                    this.SaveTransportista();
                    break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  SaveTransportista(){
    // No guardar en caso de que no se encuentre el movimiento id
    if(this.movimientoId == 0){
      this.dialogRef.close({
        infoTransportista: {
          transportista: this.transportista,
          numReferencia: this.numReferencia,
          fechaEmbarque: this.fechaEmbarqueModel
        }
      });
      return;
    }

    var requestUpdate = new EditDataMovimientoTransportistaRequest();
    requestUpdate.movimientoId = this.movimientoId;
    requestUpdate.transportista = this.transportista;
    requestUpdate.numReferencia = this.numReferencia;
    requestUpdate.fechaEmbarque = this.fechaEmbarqueModel;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);


    this.dataService.postData<EditDataMovimientoTransportistaResponse>("Movimientos/editDataMovimientoTransportista", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Datos de transportista guardados con éxito", "Aceptar");
                this.dialogRef.close(true);
            }
            this._overlay.close(this.overlayRef);
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("SaveTransportista");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }
}
