import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar, MatSort } from '@angular/material';
import { OverlayService } from '../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';

import { DataServices } from '../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../Interfaces/Models/LoginModels';
import { Router } from '@angular/router';
import { enviroments } from "../Interfaces/Enviroments/enviroments";
import { DateAdapter } from '@angular/material/core';

//Servicio para verificar seleccion
import { ServicesComponent } from '../Interfaces/Services/general.service';
import { ExporterService } from '../Interfaces/Services/exporter.service';


//PDF
import { 
  Columns,
  Img,
    PdfMakeWrapper, 
    Table,
    Txt,
    Cell
  } from 'pdfmake-wrapper';

//DataServices and Models
import {
  MovimientosData,
  SearchMovimientoRequest,
  SearchMovimientoResponse,

  SearchComboMovimientoRequest,
  SearchComboMovimientoResponse,
  SearchDataMovimientoGeneralRequest,
  SearchDataMovimientoGeneralResponse,
  SearchDataMovimientoDestinatarioRequest,
  SearchDataMovimientoDestinatarioResponse,
  SearchDataMovimientoRemitenteRequest,
  SearchDataMovimientoRemitenteResponse,
  SearchDataMovimientoTransportistaRequest,
  SearchDataMovimientoTransportistaResponse,
  SearchDataMovimientoInfoLegalRequest,
  SearchDataMovimientoInfoLegalResponse,
  SearchDocDetalleProductosRequest,
  SearchDocDetalleProductosResponse,
  SearchDocDetalleProductosIndiRequest,
  SearchDocDetalleProductosCajasResponse,
  SearchDocDetalleTotalProdResponse,
  SearchDocDetalleTotalCajasResponse,
  SearchDocDetalleTotalCantidadResponse,
  SearchDocDetalleTotalPesoResponse,
  SearchDocDetalleFechaMinResponse,
  SearchDataMovimientoObservacionesRequest,
  SearchDataMovimientoObservacionResponse
} from '../Interfaces/Models/MovimientosModels';
import { DatePipe } from '@angular/common';
import { concat } from 'rxjs';
import { DialogoImprimirComponent } from '../header/dialogo-imprimir/dialogo-imprimir.component';
import { AddressDataEdition } from '../Interfaces/Models/AddressModels';

declare var BrowserPrint: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit {

  active: boolean = false;

  constructor(
    private _dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    public datepipe: DatePipe,
    public _servicio : ServicesComponent,
    private excelService: ExporterService,
    private dateAdapter: DateAdapter<Date>
  ){
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  //Variables para la vista
  mostrarColumna: boolean = false;

  movimientoId:number=0;
  nombreAgrupacion: string;
  numeroPallet: number;
  numeroCajas: number;
  productoId: number = 0;
  cantidad: number;
  tipoMovimientoId: number = 0;
  nombreRemitente: string;
  apellidoRemitente: string;
  nombreDestinatario: string;
  apellidoDestinatario: string;
  destinatario_PK_UsuarioId: number;
  fechaIngreso: string;
  fechaCaducidad: string = '';

  anio: string = new Date().getFullYear().toString();
  // seteo de hoy
  aDate = new Date();
  fechaIngresoDe: string = "";
  fechaIngresoHasta: string = this.anio.concat('-12-31'.toString());

  response = new SearchMovimientoResponse();
  overlayRef: OverlayRef;
  emptyList: boolean = false;
  
  //Variables para los combos
  responseCombos = new SearchComboMovimientoResponse();
  responseEtiqueta = new SearchDataMovimientoGeneralResponse();

  responseDataDes = new SearchDataMovimientoDestinatarioResponse();
  responseDataRem = new SearchDataMovimientoRemitenteResponse();
  responseDataTrans = new SearchDataMovimientoTransportistaResponse();
  responseDataInfo = new SearchDataMovimientoInfoLegalResponse();
  responseDataInfoEtiqueta:any = {};

  responseDocsProductos = new SearchDocDetalleProductosResponse();
  responseDocsProductosCajas = new SearchDocDetalleProductosCajasResponse();
  responseDocsTotalProd = new SearchDocDetalleTotalProdResponse();
  responseDocsTotalCajas = new SearchDocDetalleTotalCajasResponse();
  responseDocsTotalCantidad = new SearchDocDetalleTotalCantidadResponse();
  responseDocsTotalPeso = new SearchDocDetalleTotalPesoResponse();
  responseDocsFechaMin= new SearchDocDetalleFechaMinResponse();

  responseDataComentario = new SearchDataMovimientoObservacionResponse();

  itemsPagina: number[] = enviroments.pageSize;

  quienes : number = 1;
  list = [{
    "id": 1,
    "data": "Remitente"
  }, {
    "id": 2,
    "data": "Destinatario"
  }]

  //Representa la instancia para el sorting de columnas (Clasificación por)
  @ViewChild(MatSort) sort: MatSort;

  //Representa componente de paginación para la tabla
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //variables para configurar la tabla  (select es para Checkbox, columnas de la tabla)
  displayedColumns: string[] = ['select',
                                'movimientoId',
                                'nombreAgrupacion', 
                                'numeroCajas',
                                'producto',  
                                'cantidad',
                                //'numeroMerma', 
                                'tipoMovimiento', 
                                'nombreRemitente',
                                'apellidoRemitente', 
                                'nombreDestinatario',
                                'apellidoDestinatario',
                                'quien',
                                'fechaIngreso',
                                // 'fechaCaducidad',
                                'cantIndMov',
                                'lote'];
  dataSource = new MatTableDataSource<MovimientosData>(this.response.movimientosDataList);
  selection = new SelectionModel<MovimientosData>(false, []);

  // Variables para imprimir en zebra
  printerList: any[] = [];
  devices: any[] = [];
  selected_device: any;
  fileData: FormData = new FormData();

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: MovimientosData): string {
      if (!row) {
          return "{this.isAllSelected() ? 'select' : 'deselect'} all";
      }
      //Enviar al servicio el tamaño de la selección de los check's
      this._servicio.setSeleccion(this.selection.selected.length);
      this._servicio.setElemento(this.selection.selected.map(item => this.movimientoId = item.movimientoId));
      return "${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}";
  }

  ngOnInit() {
    this.BusquedaCombos();
    this._servicio.nombreAg = "";
    this._servicio.refInAg = "";
    this._servicio.refExAg = "";
    this._servicio.setSeleccion(0);

    this.aDate.setDate(this.aDate.getDate()/* - 1*/);
    this.fechaIngresoDe = this.aDate.toISOString();
  }

  //#region Métodos

  /*transformarFechaDe(){
    return this.datepipe.transform(this.fechaIngresoDe, 'dd/MM/yyyy');
  }

  transformarFechaHasta(){
    return this.datepipe.transform(this.fechaIngresoHasta, 'dd/MM/yyyy');
  }*/

  BusquedaCombos() {
    var request = new SearchComboMovimientoRequest();
    request.tipoMovimiento = parseInt(sessionStorage.getItem("idUser"));

    this.dataService.postData<SearchComboMovimientoResponse>("Movimientos/searchMovimientoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseCombos = data;
        this.Busqueda();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaCombos");
        } else {
          console.log(error);
        }
      }
    )
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    const dateAuxiliar = new Date(this.fechaIngresoDe);
    dateAuxiliar.setDate(dateAuxiliar.getDate() - 1);
    var request = new SearchMovimientoRequest();
    request.producto = this.productoId;
    request.acopiosId = sessionStorage.getItem('acopiosIds');
    request.tipoMovimientoId = this.tipoMovimientoId;
    request.fechaCaducidad = this.fechaCaducidad;
    request.fechaIngresoDe =  dateAuxiliar.toISOString();
    request.fechaIngresoHasta = this.fechaIngresoHasta
    request.usuario = parseInt(sessionStorage.getItem("idUser"));
        
    setTimeout(() => {
        this.overlayRef = this._overlay.open();
    }, 1);
        
    this.dataService.postData<SearchMovimientoResponse>("Movimientos/searchMovimiento", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.response = data;
        if (this.response.movimientosDataList.length > 0)
          this.emptyList = false;
        else
          this.emptyList = true;
          this.dataSource.data = this.response.movimientosDataList;
          this.selection.clear();
          this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  exportAsXLSX():void{
    var datosExportar = this.dataSource.data.map( item => { 
      return { 'Agrupación': item.nombreAgrupacion , 
              Merma : item.numeroPallet,
              Cajas: item.numeroCajas,
              Producto: item.producto,
              Cantidad:  item.cantidad,
              Tipo: item.tipoMovimiento,
              Remitente: item.nombreRemitente,
              Destinatario: item.nombreDestinatario,
              'Fecha Ingreso':  item.fechaIngreso,
              'Fecha Caducidad': item.fechaCaducidad,
              'Lote': item.lote,
            }; 
    });
    this.excelService.exportToExcel(datosExportar, 'Exportacion de movimientos');
  }

  /*exportAsXLSXFiltered():void{
    this.excelService.exportToExcel(this.dataSource.filteredData, 'Exportacion de Productos');
  }*/

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  //Funcion despues de obtener todos los datos se establece el paginado
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  //Obtener las impresoras zebra agregadas
  setup(list, listdevices)
  {
    //Get the default device from the application as a first step. Discovery takes longer to complete.
    BrowserPrint.getDefaultDevice("printer", function(device)
        {       
          //Discover any other devices available to the application
          listdevices.push(device)
          BrowserPrint.getLocalDevices(function(device_list){
            for(var i = 0; i < device_list.length; i++)
            {
              listdevices.push(device_list[i]);
              list.push({name: device_list[i].name, uid: device_list[i].uid})
            }
          }, function(){console.log("Error getting local devices")},"printer");
          
        }, function(error){
          console.log("Error getting local devices: ", error);
        })
  }

  onDeviceSelected(selected) {
    for(var i = 0; i < this.devices.length; ++i){
      if(selected == this.devices[i].uid)
      {
        this.selected_device = this.devices[i];
        return;
      }
    }
  }

  async BusquedaEtiqueta(){
    let _dialogoRef = this._dialog.open(DialogoImprimirComponent, {
      panelClass: "dialog-min",
      data: {Devices: this.printerList}
    });

    _dialogoRef.afterClosed().subscribe(res => {
      setTimeout(() => {
        this.overlayRef = this._overlay.open();
      }, .001);
      this.buscarInfoEtiqueta(res);
    });
  }

  buscarInfoEtiqueta(res) {
    let request:any = {};
    request.movimientoId = this.movimientoId;
    let codeMov:any = {};

    this.dataService.postData<any>("Movimientos/searchDataMovimientoInfoEtiqueta", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        let fechaOpera:any = new Date(data.movimientosDataInfoEtiquetaList.fecha);
        fechaOpera = fechaOpera.getFullYear()+"/"+(((fechaOpera.getMonth() + 1) + "").length > 1 ? fechaOpera.getMonth() + 1 : '0'+(fechaOpera.getMonth() + 1))+"/"+ ((fechaOpera.getDate()+"").length > 1 ? fechaOpera.getDate() : '0'+fechaOpera.getDate()) + ' ' + ((fechaOpera.getHours()+"").length > 1 ? fechaOpera.getHours() : '0'+fechaOpera.getHours()) + ':' + ((fechaOpera.getMinutes()+"").length > 1 ? fechaOpera.getMinutes() : '0'+fechaOpera.getMinutes());
        
        codeMov = JSON.parse(data.movimientosDataInfoEtiquetaList.codigoQR);

        let labelZPL = this.printLabel(1, 
          codeMov.T, 
          codeMov.P, 
          codeMov.I, codeMov.F, 
          codeMov.ID, 
          data.movimientosDataInfoEtiquetaList.nombreComp,/*"company",*/ 
          data.movimientosDataInfoEtiquetaList.nombreProducto,/*"productName", */
          data.movimientosDataInfoEtiquetaList.operador + ', ' + fechaOpera,/*"packagin", */
          "",/*"ranges", */
          "", "", "", "", 
          data.movimientosDataInfoEtiquetaList.cajaId.split(',')[0],/*"etiquetaId", */
          "------"/*"instructions"*/, res.x, res.y);

        if(res.printerId == 0){
          this.openSnack("No se realizará la impresión de la etiqueta, seleccionar una impresora", "Aceptar");
          // return;
        }else{
          this.onDeviceSelected(res.printerId);
          //console.log(labelZPL);
          //console.log("There will be " + copies + " copies.");
          for(var i = 0; i < 1; i++)
            this.writeToSelectedPrinter(labelZPL)
        }
        this.showTmpPdfLabel(labelZPL);
      },
      error => {
        this.openSnack("Error al mandar la solicitud", "Aceptar");
        this._overlay.close(this.overlayRef);
      }
    );
  }

  showTmpPdfLabel(labelZPL: any): void {
    this.fileData = new FormData();
    let zpl = new Blob([labelZPL], { type: "text/plain" } );
    this.fileData.append('file', zpl);

    var xhr = new XMLHttpRequest();
        xhr.open('POST', enviroments.urlZebra);
        xhr.setRequestHeader('Accept', 'application/pdf');
        xhr.setRequestHeader('X-Quality', 'grayscale');
        xhr.setRequestHeader('X-Linter', 'On');
        xhr.responseType = 'blob';
        xhr.send(this.fileData);
        xhr.onreadystatechange = function() { 
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              // Descarga directa
              /*var wurl = window.URL;
                var url = wurl.createObjectURL(xhr.response);
                var filename = 'label.pdf';
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                //a.style = 'display: none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                wurl.revokeObjectURL(url);*/

                // Preview en nueva ventana
                var file = new Blob([xhr.response], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else if (xhr.status >= 400 && xhr.status <= 599) {
                var reader = new FileReader();
                reader.onload = function(e) {
                  console.log("status error: ",reader.result);
                };
                reader.readAsText(xhr.response);
            } else if (xhr.status == 0) {
                // see note in refreshLabel() for details on when this might happen
                console.log("ERROR: Temporarily down for maintenance");
            }
        }
      };
  }

  printLabel(copies, operationType, operationScanned, first, last, id, superiorText, superiorText2, leftText, leftText2, rightText, rightText2, bottomText, bottomText2, etiquetaID, instrucciones, medianilx, medianily) {
    //T: C - caja, P - Pallet
    //P: Cantidad de unidades escaneadas
    //I: veryFirst
    //F: veryLast
    //Id: agrupacion - pallet - box


    /*

      Primario
       { id: 1, value: "Producto" }
       { id: 2, value: "Rangos" }
       { id: 3, value: "Datos de empaque" }
       { id: 4, value: "compañia" }
      Secundario
       { id: 1, value: "Producto" }
       { id: 2, value: "Rangos" }
       { id: 3, value: "Datos de empaque" }
       { id: 4, value: "compañia" }
      en db tengo por cada lado
      _topPrimary,
      _topSecondary,
      _rightPrimary,
      _rightSecondary,
      _bottomPrimary,
      _bottomSecondary,
      _leftPrimary,
      _leftSecondary,
     */
    //console.log("inciar construcción de etiqueta");
    let labelZPL = "^XA" +
      "^CI28"+
      "^PW4000" +
      `^LH"${medianilx},${medianily}`+
      "^A1N,50,40" +
      "^FO750,50,1" +
      "^FD" + "STOCK" +
      "^FS" +
      "^A1N,20,12" +
      "^FO100,72,0" +
      "^FD" + etiquetaID +
      "^FS" +
      "^A1N,25,15" +
      "^FO100,120,0" +
      "^FD" + superiorText + //Compañia
      "^FS" +
      "^A1N,15,10" +
      "^FO100,150,0" +
      "^FD" + superiorText2 + //Nombre del producto de la familia
      "^FS" +
      "^A1N,15,10" +
      "^FO225,830,0" +
      "^FD" + superiorText2 + //Nombre del producto de la familia
      "^FS" +
      "^A1N,15,10" +
      "^FO225,1030,0" +
      "^FD" + superiorText2 + //Nombre del producto de la familia
      "^FS" +
      "^FO120,200" +
      //Data matriz del lado superior izquiedo
      "^BXN,7,200^FD"+ enviroments.qrUrl + "tracking?qr={\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //DM
      "^FS" +
      //QR del lado superior derecho
      "^FO500,200" +
      "^BQN,2,4,^FDQA,"+ enviroments.qrUrl + "tracking?qr={\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //QR
      "^FS" +
      //Datamatriz del primer hijo (Izquierda)
      "^FO50,825" +
      "^BXN,4,200^FD"+ enviroments.qrUrl + "tracking?qr={\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //DM
      "^FS" +
      //QR del primer hijo (Derecha)
      "^FO650,825" +
      "^BQN,2,2,^FDQA,"+ enviroments.qrUrl + "tracking?qr={\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //QR
      "^FS" +
      //Datamatriz del segundo hijo (Izquierda)
      "^FO50,1025" +
      "^BXN,4,200^FD"+ enviroments.qrUrl + "tracking?qr={\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //DM
      "^FS" +
      //QR del segundo hijo (derecha)
      "^FO650,1025" +
      "^BQN,2,2,^FDQA,"+ enviroments.qrUrl + "tracking?qr={\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //QR
      "^FS" +
      "^A2R,20,12" +
      "^FO40,150,1" +
      //Fecha lado izquiero texto vertical (datamatrix superior)
      "^FD" + leftText +
      "^FS" +
      "^A1N,23,13" +
      "^FO750,500,1" +
      //Titulo count
      "^FDCount" +
      "^FS" +
      "^A1N,23,13" +
      "^FO100,500,0" +
      "^FDCode Ranges";

      
      /*if (operationType == "P") {
        let aRngsX = leftText2.split(';');
        first = aRngsX[0].split('-')[0];
      }*/
      leftText2 = first + " - " + last;
      let aRngs = leftText2.split(';');
    for (var x = 0; x < aRngs.length; x++)
    {
      if (aRngs[x] != "")
      {
        labelZPL += "^FS" +
        "^A1N,20,10" +
        "^FO100," + (550 + (x * 20)) + ",0" +
        "^FD" + aRngs[x] + "                      " + operationScanned +
        "^FS" +
        "^A1N,20,10" +
        "^FO220," + (850 + (x * 20)) + ",0" +
        "^FD" + aRngs[x] +
        "^FS" +
        "^A1N,20,10" +
        "^FO220," + (1050 + (x * 20)) + ",0" +
        "^FD" + aRngs[x];
      }
    }
     
    labelZPL += "^XZ";

    return labelZPL;
  }

  writeToSelectedPrinter(dataToWrite) {
    this.selected_device.send(dataToWrite, undefined, this.errorCallback);
  }

  errorCallback = function(errorMessage) {
    console.log("Error: " + errorMessage);  
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  /*BusquedaEtiqueta() {
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = parseInt(this._servicio.getElemento());
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseEtiqueta = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/
  //#endregion

  openVerDetalles(){
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.BusquedaDes();
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaDes() {
    
    var request = new SearchDataMovimientoDestinatarioRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoDestinatarioResponse>("Movimientos/searchDataMovimientoDestinatario", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataDes = data; 
        this.BusquedaRem();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDes");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaRem(etiqueta = 0) {
    
    var request = new SearchDataMovimientoRemitenteRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoRemitenteResponse>("Movimientos/searchDataMovimientoRemitente", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataRem = data;
        this.BusquedaTrans();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaRem");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaTrans() {
    
    var request = new SearchDataMovimientoTransportistaRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportista", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataTrans = data;
        this.BusquedaInfo();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaTrans");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaInfo() {
    
    var request = new SearchDataMovimientoInfoLegalRequest;
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoInfoLegalResponse>("Movimientos/searchDataMovimientoInfoLegal", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataInfo = data;
        this.BusquedaDocsProductos();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsProductos() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductos", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        //this.BusquedaDetalleProductos();
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDetalleProductos2() { // Productos tabla Cajas
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = '' + this.movimientoId;

    this.dataService.postData<SearchDocDetalleProductosCajasResponse>("Movimientos/searchDocDetalleProductosCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosCajas = data;
        this.BusquedaDocsTotalProd();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalProd() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalProdResponse>("Movimientos/searchDocDetalleTotalProd", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalProd = data;
        //this.BusquedaDocsTotalPallet();
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalProd");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCajas() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCajasResponse>("Movimientos/searchDocDetalleTotalCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCajas = data;
        this.BusquedaDocsTotalCantidad();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCajas");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCantidad() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCantidadResponse>("Movimientos/searchDocDetalleTotalCantidad", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCantidad = data;
        this.BusquedaDocsTotalPeso();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCantidad");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPeso() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPesoResponse>("Movimientos/searchDocDetalleTotalPeso", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPeso = data;
        this.BusquedaDocsFechaMin();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPeso");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsFechaMin() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleFechaMinResponse>("Movimientos/searchDocDetalleFechaMin", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsFechaMin = data;
        this.BusquedaComentario();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsFechaMin");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
  //funcion fecha se creo el 25/04/2022 por Frida Ocampo
  padTo2Digits (num) {
    return num.toString().padStart(2,'0');
  }
  formatDate(date){
    return [
      this.padTo2Digits(date.getDate()),
      this.padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaComentario() {
      
    var request = new SearchDataMovimientoObservacionesRequest();
    request.movimientoId = this.movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcion", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataComentario = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();
    pdf.info({
      title: 'Guía de Embarque',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 60, 20, 30 ]);


    let tableHeader = new Table([
        [ {text:this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaHeader, bold:true, fontSize:18}
        ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;

    if(this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaHeader == "") {
      tableHeader = new Table([
        [ new Cell( new Txt(' ').bold().fontSize(12).end).fillColor("#012152").end ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;
    }
    pdf.header(
      new Columns([
        tableHeader,
        await new Img('../../assets/img/img-logo-stock-3.png').width('100').margin([-20,20,20,0]).link(""+enviroments.logoUrl).build()
      ]).end,
    );
    pdf.images;

    pdf.add(
      pdf.ln(1)
    );

    let textCajaId = this.responseDocsProductosCajas.docDetalleProductoCajasList.length > 0 ? this.responseDocsProductosCajas.docDetalleProductoCajasList[0].codigoCaja.split(',')[0] : '';
    let date = new Date(this.fechaIngresoDe);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    pdf.add(
      new Columns([
        new Table([
          ['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],
          [ {text: 'Guía de Embarque', bold:true, alignment:'left', fontSize:16, colSpan: 3} ],
          ['','',''],['','',''], ['','',''],  ['','',''],['','',''],  ['','',''],  ['','',''],  
          [ {text:'Fecha: ', alignment:'right', bold:true}, 
          {text: this.formatDate(date), alignment:'left', color: 'gray'},
            ''
          ],[ {text:'OP Interna: ', alignment:'right', bold:true}, 
          {text: this.selection.selected[0].referenciaInterna, alignment:'left', color: 'gray'},
          ''
        ],
          [ {text:'No. cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.numeroC, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'OP Cliente: ', alignment:'right', bold:true}, 
            {text: this.selection.selected[0].referenciaExterna, alignment:'left', color: 'gray'},
            ''
          ]
        ]).widths([80, 150, 40]).fontSize(9).layout('noBorders').end,
        new Table([
          [ {qr: enviroments.qrUrl + this.selection.selected[0].codigoQR, alignment:'center', fit:100, colSpan:2},
            ''
          ],
          [ {text: textCajaId, color: 'gray', colSpan:2}]
        ]).widths([80, 150]).alignment('center').fontSize(9).layout('noBorders').end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    // Domicilio Destinatario
    let fullDomicilioDest = this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD;
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '') 
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '');
    // Domicilio Remitente
    let fullDomicilioRem = this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR;
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? ', C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? 'C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '') 
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '');

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Destinatario: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreRemitente + ' - '+
            this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioDest, alignment:'left', color: 'gray'}
          ],
          [ {text:'CP: ', alignment:'right', bold:true}, 
            {text: (this.responseDataDes.movimientosDataDestinatarioRecepList.cpD == "0" ? "" : this.responseDataDes.movimientosDataDestinatarioRecepList.cpD), alignment:'left', color: 'gray'}
          ]/*,
          [ {text:'Tel: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.telefonoD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, alignment:'left', color: 'gray'}
          ]*/
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Remitente: ', alignment:'right', bold:true, fontSize:11}, 
            {text: 
                  this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR + ' - '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioRem, alignment:'left', color: 'gray'} 
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {
              text: this.responseDataRem.movimientosDataRemitenteRecepList.atendioUsuario, alignment:'left', color: 'gray'
            }
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Transporte: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.transportista, alignment:'left', color: 'gray'}
          ],
          [ {text:'Fecha embarque: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, alignment:'left', color: 'gray'}
          ]
          ,
          [ {text:'Referencia: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.numReferencia, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Observaciones: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.observacion, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dimensiones por caja (cms): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.dimensionesCaja, alignment:'left', color: 'gray'}
          ],
          [ {text:'Peso por caja (Kg): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.pesoCaja, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Resumen de Productos/Pedido: ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
          ],
          [ {text:'Producto/Familia: ', bold:true}, 
            {text: 'Marca: ', bold:true},
            {text: 'GTIN: ', bold:true},
            {text: 'Código Pallets: ', bold:true},
            {text: 'Cajas por pallet: ', bold:true},
            {text: 'Unidades por caja: ', bold:true},
            {text: 'Total de unidades: ', bold:true},
            {text: 'Caducidad prox: ', bold:true},
          ]
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(9).end,
      ]).end
    );

    //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      var a;
      if(products.numPallet==0){
        a=1;
      }else{
        a=products.numPallet;
      }
      pdf.add(
        new Table([
          [ {text:products.producto, alignment:'right'}, 
            {text: products.ciu, alignment:'right'}, 
            products.gtin,
            0, 
            products.numCajas/a, 
            products.cantidad/products.numCajas, 
            products.cantidad, 
            products.fechaCaducidad
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );
    }

   /* pdf.add( //Si en un futuro se desea separar tablas descomentar de 1227 -1234
      new Table([
        [ 
          {text:'', bold:true, colSpan:9, fontSize:11, alignment:'left',pageBreak: 'before'}, 
            '','','','','','','',''
        ]
      ]).widths([120, 40, 70, 40, 40,40,40,40,40]).alignment('center').fontSize(9). layout('noBorders').end
    );*/ 

    /*pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );
    
    if(this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Pallets', colSpan:9, bold:true}, 
          '','','','','','','',''
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let pallets of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
        pdf.add(
          new Table([
            [ {text: pallets.producto, bold:true}, 
            {text: pallets.ciu, bold:true}, 
            {text: pallets.gtin, bold:true}, 
            {text: pallets.tipoEmpaque, bold:true}, 
            {text: pallets.cantidad, bold:true}, 
            {text: pallets.codigoPallet, bold:true} ,
            {text: ' ', bold:true} ,
            {text: pallets.lote, bold:true} ,
            {text: pallets.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
        );
      }
    }*/

    pdf.add(
      pdf.ln(2)
    );
    
    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          // {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, /*70,*/ 40,50, 40, 80,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Cajas', bold:true}
        ]
      ]).widths(['*']).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let cajas of this.responseDocsProductosCajas.docDetalleProductoCajasList) {
        //Separa el codigo por comas en caso de tener mas de uno 
        //Por cada codigo hace una fila 
          pdf.add(
            new Table([
              [ {text: cajas.producto, bold:true}, 
              {text: cajas.ciu, bold:true}, 
              // {text: cajas.gtin, bold:true}, 
              {text: cajas.tipoEmpaque, bold:true}, 
              {text: cajas.codigoPallet, bold:true} ,
              {text: cajas.cantidad/cajas.numCajas, bold:true}, 
              {text: cajas.codigoCaja, bold:true} ,
              {text: cajas.lote, bold:true} ,
              {text: cajas.fechaCaducidad, bold:true} 
            ]
          ]).widths([100, 50,/* 70,*/ 40, 50, 40, 80,40,50]).alignment('center').fontSize(8).end
          );
      }
    }

    /*for (let products of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 120, 80, 80, 80, 80]).alignment('center').fontSize(9).end
      );
    }*/


    pdf.footer(
      new Columns([
        new Table(
        [
          [ 
            {text:'Controla tus inventarios y automatiza las comunicaciones de tu almacen con Traceit Stock   https://stock.traceit.net' }]
        ]).widths(['*']).alignment('center').layout('noBorders').fontSize(7).end
      ]).end


    );

    if ( screen.width <= 576)
		{
			pdf.create().download();
		}
		if (screen.width > 576 && screen.width <= 768)
		{
      pdf.create().download();
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			pdf.create().open();
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
      pdf.create().open();
      //document.getElementById("frame1").src = pdf.create().getDataUrl();
		} 
		if (screen.width > 1200){
			pdf.create().open();
    }
  }


  async generatePDFPrueba(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Detalle Envío',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 60, 20, 30 ]);

    pdf.header(
      new Columns([
        new Table(
        [
          [ {text: 'Abarrotes La Estrella SA de CV', bold:true, fontSize:18}
          ]
        ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,0,0]).end,
        await new Img('../../assets/img/logo-doc-stock.png').width('100').margin([-20,20,20,0]).build()
      ]).end,
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          ['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],
          [ {text: 'Detalle', bold:true, alignment:'right', fontSize:16},
            {text: 'Pedido',  alignment:'left', color: 'gray', fontSize:16},
            '' 
          ],
          ['','',''],['','',''], ['','',''],  ['','',''],['','',''],  ['','',''],  ['','',''],  
          [ {text:'Orden de Pedido: ', alignment:'right', bold:true}, 
            {text: 'p.34543', alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'No. cliente: ', alignment:'right', bold:true}, 
            {text: 'p.C231', alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Cliente: ', alignment:'right', bold:true}, 
            {text: 'Lechera Sello Rojo'+' '+
                   'SA de CV', alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Pedido cliente: ', alignment:'right', bold:true}, 
            {text: 'p.1212', alignment:'left', color: 'gray'},
            ''
          ]
        ]).widths([80, 150, 40]).fontSize(9).layout('noBorders').end,
        new Table([
          [ {qr: 'this.responseData.movimientosDataGeneralRecepList.codigoQR', alignment:'center', fit:100, colSpan:2},
            ''
          ],
          [ {text: '390084335392', color: 'gray', colSpan:2}]
        ]).widths([80, 150]).alignment('center').fontSize(9).layout('noBorders').end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Destinatario: ', alignment:'right', bold:true, fontSize:11}, 
            {text: 'Lechera Sello Rojo'+' '+
                   'SA de CV', alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text:'Av. De los Laureles 2345, Calister'+', '+
            'Zamora'+', '+
            'Michoacan de Ocampo'+', '+
            'México', alignment:'left', color: 'gray'}
          ],
          [ {text:'CP: ', alignment:'right', bold:true}, 
            {text: 'p.3453', alignment:'left', color: 'gray'}
          ],
          [ {text:'Tel: ', alignment:'right', bold:true}, 
            {text: 'p.33 3878 9034', alignment:'left', color: 'gray'}
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: 'José Carlos'+' '+
            'Mendoza Perez', alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Remitente: ', alignment:'right', bold:true, fontSize:11}, 
            {text: 'Abarrotes la Estrella'+' '+
                   'SA de CV', alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: 'Av. Valparaiso #3045, Valle de los Cipreses'+', C.P. '+
                  ' 2134'+', '+
                  ' Zapopan'+', '+
                  ' Jalisco'+', '+
                  ' México', alignment:'left', color: 'gray'} 
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: 'Esteban'+' '+
                   'Estrada Sandoval', alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Transporte: ', alignment:'right', bold:true, fontSize:11}, 
            {text: 'Tarahumara', alignment:'left', color: 'gray'}
          ],
          [ {text:'Fecha embarque: ', alignment:'right', bold:true}, 
            {text: '12/03/2020', alignment:'left', color: 'gray'}
          ]
          ,
          [ {text:'Referencia: ', alignment:'right', bold:true}, 
            {text:'p.G231', alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Observaciones: ', alignment:'right', bold:true, fontSize:11}, 
            {text: 'Entrega después de la 12:00 PM', alignment:'left', color: 'gray'}
          ],
          [ {text:'Dimensiones por caja (cms): ', alignment:'right', bold:true}, 
            {text: '40 x 30 x 20, 30 x 20 x 15', alignment:'left', color: 'gray'}
          ],
          [ {text:'Peso por caja (Kg): ', alignment:'right', bold:true}, 
            {text: '200, 230', alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Resumen de Productos/Pedido ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
          ],
          [ {text:'Producto/Familia: ', bold:true}, 
            {text: 'Marca: ', bold:true},
            {text: 'GTIN: ', bold:true},
            {text: 'Pallets: ', bold:true},
            {text: 'Cajas por pallet: ', bold:true},
            {text: 'Unidades por caja: ', bold:true},
            {text: 'Total de unidades: ', bold:true},
            {text: 'Caducidad prox: ', bold:true},
          ]
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(9).end,
      ]).end
    );

    //Productos FOR
    //for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          [ {text:'Stawberries 1 libra', alignment:'right'}, 
            {text: 'Mainland', alignment:'right'}, 
            '850582002004', 
            '1', 
            '10', 
            '544', 
            '5440', 
            '12/06/2021'
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );

      pdf.add(
        new Table([
          [ {text:'Blueberries 6 oz', alignment:'right'}, 
            {text: 'Mainland', alignment:'right'}, 
            '850582008000', 
            '1', 
            '5', 
            '600', 
            '3000', 
            '13/06/2021'
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );

      pdf.add(
        new Table([
          [ {text:'Stawberries 2 libra', alignment:'right'}, 
            {text: 'Mainland', alignment:'right'}, 
            '850582005630', 
            '1', 
            '10', 
            '300', 
            '3000', 
            '14/06/2021'
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );
    //}

    pdf.add(
      new Table([
        [ 
          {text:'', bold:true, colSpan:9, fontSize:11, alignment:'left',pageBreak: 'before'}, 
            '','','','','','','',''
        ]
      ]).widths([120, 40, 70, 40, 40,40,40,40,40]).alignment('center').fontSize(9). layout('noBorders').end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );

    pdf.add(
      pdf.ln(2)
    );
    
    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );
    /*for (let products of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 120, 80, 80, 80, 80]).alignment('center').fontSize(9).end
      );
    }*/

    pdf.footer(
      new Columns([
        new Table(
        [
          [ 
            {text:'Controla tus inventarios y automatiza las comunicaciones de tu almacen con Traceit Stock',alignment:'right' },
            {text:'https://stock.traceit.net',alignment:'left', color:'blue'}
          ]
        ]).widths(['65%','35%']).alignment('center').layout('noBorders').fontSize(7).end
      ]).end


    );

    if ( screen.width <= 576)
		{
			pdf.create().download('Etiqueta');
		}
		if (screen.width > 576 && screen.width <= 768)
		{
      pdf.create().download('Etiqueta');
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			pdf.create().open('Etiqueta');
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			pdf.create().open();pdf.create().download('Etiqueta');
		} 
		if (screen.width > 1200){
			pdf.create().open();
    }
  }

}