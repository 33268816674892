import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-app-info-dialog',
  templateUrl: './app-info-dialog.component.html',
  styleUrls: ['./app-info-dialog.component.less']
})
export class AppInfoDialogComponent implements OnInit {

  hasDevices: boolean;
  hasPermission: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: any,
  ) { 
    this.hasDevices = data.hasDevices;
    this.hasPermission = data.hasPermission;
  }

  ngOnInit() {
  }

}
