import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog, MatSnackBar } from '@angular/material';
import { DialogoAgregarDireccionComponent } from '../dialogo-agregar-direccion/dialogo-agregar-direccion.component';
import { DialogoRegistroExitosoComponent } from '../dialogo-registro-exitoso/dialogo-registro-exitoso.component';
import { Content } from '@angular/compiler/src/render3/r3_ast';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';

//Servicio para datos de direccion
import { ServicesComponent } from '../../Interfaces/Services/general.service';

import {
  SaveUsuarioRequest,
  SaveUsuarioResponse,
} from '../../Interfaces/Models/MovimientosModels';
import { DialogoTerminosComponent } from '../dialogo-terminos/dialogo-terminos.component';

@Component({
  selector: 'app-dialogo-crear-cuenta',
  templateUrl: './dialogo-crear-cuenta.component.html',
  styleUrls: ['./dialogo-crear-cuenta.component.css']
})
export class DialogoCrearCuentaComponent implements OnInit {

  @Output() Login = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogoCrearCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    public dialog: MatDialog,
    private snack: MatSnackBar,
    public _servicio : ServicesComponent,
    private dataService: DataServices,
    private _overlay: OverlayService,
    private _router: Router,
  ) { }

  contadorUsuario: number = 1;
  overlayRef: OverlayRef;

  nombreComp: string = '';
  razonSocial: string = '';
  correoComp: string = '';
  telefono: string = '';

  email1: string = '';
  pass1: string = '';

  email2: string = '';
  pass2: string = '';

  email3: string = '';
  pass3: string = '';

  email4: string = '';
  pass4: string = '';

  email5: string = '';
  pass5: string = '';

  checked:boolean = false;

  vacio:string = "";

  ngOnInit() {
  }



  sumarUsuario(){
    if(this.contadorUsuario<=5) {
      this.contadorUsuario = this.contadorUsuario +1;
    }
    else{
      this.openSnack("No puedes añadir más usuario", "Aceptar");
    }
    
  }

  openDialogAddDireccion(): void {
    console.log("Tamaño: "+screen.width);
		if ( screen.width <= 576)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        width: 'auto',
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        width: 'auto',
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
		} 
		if (screen.width > 1200){
			const dialogRef =this.dialog.open(DialogoAgregarDireccionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Exito');
        }
        else{
          console.log('Error');
        }
      });
    } 
  }

  openDialogRegistroExitoso(): void {
    const dialogRef =this.dialog.open(DialogoRegistroExitosoComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Exito');
      }
      else{
        console.log('Error');
      }
    });
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  SaveUsuario(){
    //Validacion de los datos generales de las compañias
   /* if (this.nombreComp.trim() == "" || this.razonSocial.trim() == "" ||
    this.correoComp.trim() == "" || this.telefono.trim() == "" ||
    this.email1.trim() == "" || this.pass1.trim() == "" ||
    this._servicio.nombrePais.trim() == "" || this._servicio.nombreEstado.trim() == "" || 
    this._servicio.ciudad.trim() == "" || this._servicio.codigoPostal.trim() == "" || this._servicio.direccion.trim() == "") {
    this.openSnack("Captura los datos necesarios para el registro", "Aceptar");
    return;
    }*/

    var requestUpdate = new SaveUsuarioRequest();
    /*console.log(this.nombreComp);
    console.log(this.razonSocial);
    console.log(this.correoComp);
    console.log(this.telefono);
    console.log(this._servicio.direccion); //Se recibe de servicio
    console.log(this._servicio.nombrePais); //Se recibe de servicio

    console.log(this._servicio.codigoPostal); //Se recibe de servicio
    console.log(this._servicio.ciudad); //Se recibe de servicio
    console.log(this._servicio.nombreEstado); //Se recibe de servicio

    console.log(this.email1);
    console.log(CryptoJS.SHA256(this.pass1).toString());

    console.log(this.email2);
    console.log(CryptoJS.SHA256(this.pass2).toString());

    console.log(this.email3);
    console.log(CryptoJS.SHA256(this.pass3).toString());

    console.log(this.email4);
    console.log(CryptoJS.SHA256(this.pass4).toString());

    console.log(this.email5);
    console.log(CryptoJS.SHA256(this.pass5).toString());

    console.log(sessionStorage.getItem("idUser"));*/

    
    requestUpdate.nombreComp = this.nombreComp;
    requestUpdate.razonSocial =  this.razonSocial;
    requestUpdate.correoComp = this.correoComp;
    requestUpdate.telefono = this.telefono;
    requestUpdate.direccion = this._servicio.direccion; //Se recibe de servicio
    requestUpdate.nombrePais = this._servicio.nombrePais.toString(); //Se recibe de servicio

    requestUpdate.codigoPostal = this._servicio.codigoPostal; //Se recibe de servicio
    requestUpdate.ciudad = this._servicio.ciudad; //Se recibe de servicio
    requestUpdate.nombreEstado = this._servicio.nombreEstado.toString(); //Se recibe de servicio

    requestUpdate.email1 = this.email1;
    requestUpdate.pass1 = CryptoJS.SHA256(this.pass1).toString();

    requestUpdate.email2 = this.email2;
    requestUpdate.pass2 = CryptoJS.SHA256(this.pass2).toString();

    requestUpdate.email3 = this.email3;
    requestUpdate.pass3 = CryptoJS.SHA256(this.pass3).toString();

    requestUpdate.email4 = this.email4;
    requestUpdate.pass4 = CryptoJS.SHA256(this.pass4).toString();

    requestUpdate.email5 = this.email5;
    requestUpdate.pass5 = CryptoJS.SHA256(this.pass5).toString();

    if(sessionStorage.getItem("idUser")==null){
      requestUpdate.usuario = 0;
    }else{
      requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));
    }

    setTimeout(() => {
			this.overlayRef = this._overlay.open();
		  }, .001);

    this.dataService.postData<SaveUsuarioResponse>("Movimientos/saveUsuario", sessionStorage.getItem("token"), requestUpdate).subscribe(
      data => {
          if (data.messageEsp != "") {
              this.openSnack(data.messageEsp, "Aceptar");
          } else {
            this.openSnack("Usuario registrado exitosamente", "Aceptar");
            this.openDialogRegistroExitoso();

            var request:any = {};
            request.password = CryptoJS.SHA256(this.pass1).toString();
            request.user =this.email1;
            request.id = '0';

            this.dataService.postDataApi<any>().subscribe(
              dataApi => {
                request.lat = dataApi.latitude;
                request.lon = dataApi.longitude;
                this.dataService.postData<LoginUserResponse>("User/loginUser", "", request).subscribe(
                  data2 => {
                    if (data2.messageEsp != "") {
                      event.stopPropagation(); event.preventDefault();
                      this._router.navigate(['Login']);
                      this.openSnack(data2.messageEsp, "Aceptar");
                      return false;
                    }
    
                    data2.userData.userPermissions.forEach((it, id) => {
                      sessionStorage.setItem(it.namePermission, it.permissionId.toString());
                    });
    
                    sessionStorage.setItem("token", data2.token);
                    sessionStorage.setItem("name", data2.userData.userData.name);
                    sessionStorage.setItem("idUser", data2.userData.userData.idUser.toString());
                    sessionStorage.setItem("company", data2.userData.userData.company.toString());
                    sessionStorage.setItem("email", this.email1);
                    sessionStorage.setItem("password", CryptoJS.SHA256(this.pass1).toString());
    
                    document.body.style.overflow = "hidden";
                    this.Login.emit(true);
                    this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
                    this._router.navigate(["/Login"]));
                    //////////////////////////////
                    window.location.reload();
                  },
                  error => {
                    event.stopPropagation(); event.preventDefault();
                    console.log(error);
                    this._router.navigate(['Login']);
                    this.openSnack("Error al mandar la solicitud", "Aceptar");
                    return false;
                  }
                );
              },
              error => {
                this.dataService.postData<LoginUserResponse>("User/loginUser", "", request).subscribe(
                  data2 => {
                    if (data2.messageEsp != "") {
                      event.stopPropagation(); event.preventDefault();
                      this._router.navigate(['Login']);
                      this.openSnack(data2.messageEsp, "Aceptar");
                      return false;
                    }
    
                    data2.userData.userPermissions.forEach((it, id) => {
                      sessionStorage.setItem(it.namePermission, it.permissionId.toString());
                    });
    
                    sessionStorage.setItem("token", data2.token);
                    sessionStorage.setItem("name", data2.userData.userData.name);
                    sessionStorage.setItem("idUser", data2.userData.userData.idUser.toString());
                    sessionStorage.setItem("company", data2.userData.userData.company.toString());
                    sessionStorage.setItem("email", this.email1);
                    sessionStorage.setItem("password", CryptoJS.SHA256(this.pass1).toString());
    
                    document.body.style.overflow = "hidden";
                    this.Login.emit(true);
                    this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
                    this._router.navigate(["/Login"]));
                    //////////////////////////////
                    window.location.reload();
                  },
                  error => {
                    event.stopPropagation(); event.preventDefault();
                    console.log(error);
                    this._router.navigate(['Login']);
                    this.openSnack("Error al mandar la solicitud", "Aceptar");
                    return false;
                  }
                );
              }
            );
          }
          this._overlay.close(this.overlayRef);
      },
      error => {
          if (error.error.hasOwnProperty("messageEsp")) {
              this.relogin("SaveUsuario");
          } else {
              this.openSnack("Error al mandar la solicitud", "Aceptar");
          }
          this._overlay.close(this.overlayRef);
      }
    )
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "SaveUsuario":
                  this.SaveUsuario();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "SaveUsuario":
                  this.SaveUsuario();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Abrir ventana de Dialogo de terminos y condiciones
	openDialogTerminos(): void {
		const dialogRef =this.dialog.open(DialogoTerminosComponent,{
		width: 'auto',
		height: 'auto'
		});    
		dialogRef.afterClosed().subscribe(res=>{
		console.log(res);
		if(res){
			console.log('Mensaje de éxito');
		}
		else{
			console.log('Mensaje de error');
		}
		});
	}
}
