import { OverlayRef } from '@angular/cdk/overlay';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { EditDataMovimientoNombreRequest, EditDataMovimientoNombreResponse, SearchDataMovimientoNombreRecepRequest, SearchDataMovimientoNombreResponse } from '../../Interfaces/Models/MovimientosModels';
import { DataServices, ServicesComponent } from '../../Interfaces/Services/general.service';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { DialogoRecepcionRegistradaComponent } from '../dialogo-recepcion-registrada/dialogo-recepcion-registrada.component';

@Component({
  selector: 'app-dialogo-nombre-recepcion',
  templateUrl: './dialogo-nombre-recepcion.component.html',
  styleUrls: ['./dialogo-nombre-recepcion.component.css']
})
export class DialogoNombreRecepcionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoNombreRecepcionComponent>,
    private _router: Router,
    public dialog: MatDialog,
    private _servicio: ServicesComponent,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private dataService: DataServices,
    private snack: MatSnackBar,
    private _overlay: OverlayService,
  ) { }

  ngOnInit() {
    this.Busqueda();
  }

  responseData = new SearchDataMovimientoNombreResponse();
  overlayRef: OverlayRef;

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
      
    var request = new SearchDataMovimientoNombreRecepRequest();
    request.codigoCompleto = this._servicio.codigoQR;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoNombreResponse>("Movimientos/searchDataNombreRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }


  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }


  SaveNombre(){

    var requestUpdate = new EditDataMovimientoNombreRequest();
    requestUpdate.codigoCompleto = this._servicio.codigoQR;
    requestUpdate.nombre = this.responseData.movimientosDataNombreRecepList.nombreAgrupacion;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<EditDataMovimientoNombreResponse>("Movimientos/editDataMovimientoNombre", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Nombre guardado con éxito", "Aceptar");
                this.dialogRef.close(true);
                this.openDialogRecepcionRegistrada();
            }
            this._overlay.close(this.overlayRef);
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("SaveDestinatario");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }

  openDialogRecepcionRegistrada(): void {
    const dialogRef =this.dialog.open(DialogoRecepcionRegistradaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

  Recargar(): void{
    this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
    this._router.navigate(["Home"]));
    this.dialogRef.close(true);
    this._servicio.codigoQR = " ";
  }

}
