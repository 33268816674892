import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialogo-terminos',
  templateUrl: './dialogo-terminos.component.html',
  styleUrls: ['./dialogo-terminos.component.css']
})
export class DialogoTerminosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
