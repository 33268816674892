import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { DataServices, ServicesComponent } from '../../Interfaces/Services/general.service';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { DialogoCrearCuentaComponent } from '../dialogo-crear-cuenta/dialogo-crear-cuenta.component';

@Component({
  selector: 'app-dialogo-aviso',
  templateUrl: './dialogo-aviso.component.html',
  styleUrls: ['./dialogo-aviso.component.css']
})
export class DialogoAvisoComponent implements OnInit {

  constructor(
    private snack: MatSnackBar,
		private _router: Router,
		private dataService: DataServices,
		private _dialog: MatDialog,
		private _overlay: OverlayService,
        public _servicio : ServicesComponent
  ) { }

  ngOnInit() {
  }

  //Abrir ventana de Dialogo de Crear Cuenta
	openDialogCrearCuenta(): void {
		console.log("Tamaño: "+screen.width);
		if ( screen.width <= 576)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
		if (screen.width > 1200){
			const dialogRef =this._dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
	}
}
