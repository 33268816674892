import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { PdfMakeWrapper, Table} from 'pdfmake-wrapper';

//Servicio para borrar la seleccion
import { ServicesComponent } from '../../Interfaces/Services/general.service';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';

import {
  SaveMovimientoRequest,
  SaveMovimientosResponse,
  SearchDataEtiquetaRequest,
  SearchDataMovimientoGeneralResponse
} from '../../Interfaces/Models/MovimientosModels';
import { DialogoCreeCuentaComponent } from '../../header-invitado/dialogo-cree-cuenta/dialogo-cree-cuenta.component';
import { DialogoEnviarComponent } from '../../header/dialogo-enviar/dialogo-enviar.component';

@Component({
  selector: 'app-dialogo-crear-agrupacion',
  templateUrl: './dialogo-crear-agrupacion.component.html',
  styleUrls: ['./dialogo-crear-agrupacion.component.css']
})
export class DialogoCrearAgrupacionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoCrearAgrupacionComponent>,
    public _servicio : ServicesComponent,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    public datepipe: DatePipe,
  ) { }

  nombreAgrupacion: string = "";

  today= new Date();
  fechaIngreso = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss a', 'en-US', '0500');
  referenciaInt: number = 0;
  referenciaExt: number = 0;
  latitude : string ="";
  longitude : string = "";
  overlayRef: OverlayRef;

  vacio: string = "";

  responseEtiqueta = new SearchDataMovimientoGeneralResponse();

  ngOnInit() {
    this.getLocation();
  }

  getLocation() {
    this._servicio.getPosition().then(pos => {
        this.latitude = pos.lat;
        this.longitude = pos.lng;
    });
  }

  SaveMovimiento(){

    this.getLocation();

    var requestUpdate = new SaveMovimientoRequest();
    requestUpdate.nombreAgrupacion = this.nombreAgrupacion;
    requestUpdate.fechaIngreso = this.fechaIngreso;
    requestUpdate.referenciaInt = this.referenciaInt;
    requestUpdate.referenciaExt = this.referenciaExt;
    requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));
    requestUpdate.latitud = this.latitude.toString();
    requestUpdate.longitud = this.longitude.toString();

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SaveMovimientosResponse>("Movimientos/saveMovimiento", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Datos guardados con éxito", "Aceptar");
                this._servicio.nombreAg = this.nombreAgrupacion;
                this._servicio.refInAg = this.referenciaInt;
                this._servicio.refExAg = this.referenciaExt;
                this._servicio.activar = false;
                this.BusquedaEtiqueta();
                if(sessionStorage.getItem("name") == ''){
                  this.openDialogRegistrate();
                }
            }
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("SaveMovimiento");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaEtiqueta() {
    var request = new SearchDataEtiquetaRequest();
    request.nombreAgru = this.nombreAgrupacion;
    request.referenciaInt = this.referenciaInt;
    request.referenciaExt = this.referenciaExt;

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataEtiqueta", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseEtiqueta = data;
        this._servicio.setSeleccion(1);
        this._servicio.setElemento(data.movimientosDataGeneralRecepList.movimientoId);

        const dialogRef =this.dialog.open(DialogoEnviarComponent,{
          width: 'auto',
          height: 'auto',
          disableClose: true,
          data: {
              id: data.movimientosDataGeneralRecepList.movimientoId
          }
        });  

        dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
            console.log('Datos de movimiento encontrados');
        }
        else{
            console.log('Error');
        }
        });
        
        this.generateEtiquetaPDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  async generateEtiquetaPDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Detalle Envío',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([ 20, 20, 20, 20 ]);

    pdf.add(
      new Table([
        [ '','****','TOP','****','',
          {text:' ', rowSpan:8, noBorders:false},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:'T\nr\na\nc\ne\n-\nI\nt\n',fontSize:'15',bold:true, rowSpan:8, marginTop:70},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:' ', rowSpan:8},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5}
        ],
        [ {text:'N\no\nm\nb\nr\ne\n \nd\ne\n \np\nr\no\nd\nu\nc\nt\no',fontSize:'10', rowSpan:5, wrap:'vertical'},
          {text:this.responseEtiqueta.movimientosDataGeneralRecepList.nombreAgrupacion,fontSize:'20',bold:true, colSpan:3},'','',
          {text:'D\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:2},'','','','','','','','','','','',''],
        [ '',
          {qr:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, colSpan:3, rowSpan:3, marginTop:35},'','','','','','','','','','','','','','',''],
        ['','','','','','','','','','','','','','','','',''],
        ['','','','',{text:'E\nN\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:2},'','','','','','','','','','','',''],
        [ '',
          {text:'1 de 9', colSpan:3},'','','','','','','','','','',
          {qr:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, rowSpan:2, colSpan:2, fit:50},'','',
          {qr:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, rowSpan:2, colSpan:2, fit:50},''],
        [ '',
        {text:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, colSpan:3},'','','','','','','','','','','','','','',''],
        [ {text:' ', colSpan:5},'','','','','','','','','','','',
        {text:'1 de 1', colSpan:2},'','',
        {text:'1 de 1', colSpan:2},'']
      ]).widths(['auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto'])
        .alignment('center')
        .layout('noBorders').end
    );
    pdf.create().open();
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin: any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "SaveMovimiento":
                  this.SaveMovimiento();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "SaveMovimiento":
                  this.SaveMovimiento();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir un mensaje que durará 5 segundos en la pantalla (Superior / Centro)
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  openDialogRegistrate(): void {
    const dialogRef =this.dialog.open(DialogoCreeCuentaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Mensaje de éxito');
      }
      else{
        console.log('Mensaje de error');
      }
    });
  }

}
