import { Component, OnInit, Inject, ViewChild, ViewEncapsulation, ViewChildren, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { PdfMakeWrapper,
  Table, 
  Img, 
  Columns,
  Cell,
  Txt, } from 'pdfmake-wrapper';
import { DialogoInfoRemitenteComponent } from '../dialogo-info-remitente/dialogo-info-remitente.component';
import { DialogoInfoDestinatarioComponent } from '../dialogo-info-destinatario/dialogo-info-destinatario.component';
import { DialogoInfoLegalComponent } from '../dialogo-info-legal/dialogo-info-legal.component';
import { DialogoRecepcionRegistradaComponent } from '../dialogo-recepcion-registrada/dialogo-recepcion-registrada.component';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import {
  SearchDataMovimientoGeneralProdRecepRequest,
  SearchDataMovimientoGeneralProdRecepResponse,

  SearchDataMovimientoGeneralRecepRequest,
  SearchDataMovimientoGeneralRecepResponse,
  SearchDataMovimientoGeneralRecepResponse2,

  SearchDataMovimientoDestinatarioRecepRequest,
  SearchDataMovimientoDestinatarioRecepResponse,
  SearchDataMovimientoRemitenteRecepRequest,
  SearchDataMovimientoRemitenteRecepResponse,
  SearchDataMovimientoInfoLegalRecepRequest,
  SearchDataMovimientoInfoLegalRecepResponse,

  SearchDataMovimientoTransportistaRecepRequest,
  SearchDataMovimientoTransportistaResponse,

  SearchDocDetalleProductosRecepRequest,
  SearchDocDetalleProductosResponse,
  SearchDocDetalleTotalProdResponse,
  SearchDocDetalleTotalPalletResponse,
  SearchDocDetalleTotalCajasResponse,
  SearchDocDetalleTotalCantidadResponse,
  SearchDocDetalleTotalPesoResponse,
  SearchDocDetalleFechaMinResponse,

  EditDataAgruAEnvioRequest,
  EditDataAgruAEnvioResponse,
   SearchDataMovimientoObservacionesRecepRequest, 
   SearchDataMovimientoObservacionResponse, 
   SearchDocDetalleProductosIndiRequest, 
   SearchDocDetalleProductosIndiResponse,
   SearchDocDetalleProductosCajasResponse,
   SearchDataMovimientoDestinatarioResponse,
   SearchDataMovimientoDestinatarioRequest,
   SearchDataMovimientoRemitenteRequest,
   SearchDataMovimientoRemitenteResponse,
   SearchDataMovimientoTransportistaRequest,
   SearchDataMovimientoInfoLegalRequest,
   SearchDataMovimientoInfoLegalResponse,
   SearchDocDetalleProductosRequest,
   SearchDataMovimientoObservacionesRequest
} from '../../Interfaces/Models/MovimientosModels';
import { ScanQrComponent } from '../scan-qr/scan-qr.component';
import { ServicesComponent } from '../../Interfaces/Services/general.service';
import { DialogoCreeCuentaComponent } from '../../header-invitado/dialogo-cree-cuenta/dialogo-cree-cuenta.component';
import { formatDate } from '@angular/common';
import { DialogoObservacionesComponent } from '../dialogo-observaciones/dialogo-observaciones.component';
import { DialogoObservacionesRecepComponent } from '../dialogo-observaciones-recep/dialogo-observaciones-recep.component';
import { DialogoNombreRecepcionComponent } from '../dialogo-nombre-recepcion/dialogo-nombre-recepcion.component';
import { DialogoMermaComponent } from '../dialogo-merma/dialogo-merma.component';
import { DialogoVerDetalleProductosComponent } from '../dialogo-ver-detalle-productos/dialogo-ver-detalle-productos.component';
import { enviroments } from '../../Interfaces/Enviroments/enviroments';

import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { ProductoresByAcopioModels } from '../../Interfaces/Models/ProductoresByAcopioModels'; 
import { Session } from 'protractor';

@Component({
  selector: 'app-dialogo-recibir-bus',
  templateUrl: './dialogo-recibir-bus.component.html',
  styleUrls: ['./dialogo-recibir-bus.component.less']
})
export class DialogoRecibirBusComponent implements OnInit {
  displayedColumns: string[] = [
    'Producto(s)',
    'Charolas',
    'Rotal de unidades'
  ];

  protected _productoresByAcopioId: ProductoresByAcopioModels[] = [];
  
  selectedValue: any;
    searchTxt: any;
    @ViewChild('search') searchElement: ElementRef;
    doSomething(any){
        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.searchElement.nativeElement.focus();
        },0);  
    }

  @ViewChild('sectorInput') sectorElement: ElementRef;
  @ViewChild('qrInput') qrElement: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogoRecibirBusComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    private _servicio: ServicesComponent,
    private _dialogRef: MatDialogRef<DialogoRecibirBusComponent>
  ) { }

  codigoQR : string = '';
  codigoQRInput : string = '';
  codigoI : string = '';
  codigoF : string = '';
  codigoIHEXA : number = 0;
  codigoFHEXA : number = 0;
  responseData = new SearchDataMovimientoGeneralRecepResponse();
  responseDataData = new SearchDataMovimientoGeneralRecepResponse2();
  responseData2 = new SearchDataMovimientoGeneralProdRecepResponse();
  responseDataDes = new SearchDataMovimientoDestinatarioRecepResponse();
  responseDataRem = new SearchDataMovimientoRemitenteRecepResponse();
  responseDataInfo = new SearchDataMovimientoInfoLegalRecepResponse();
  movimientoId: number = 0;
  nombreAgru:string = "";

  numeroPallet: any = "";
  numeroCajas: any = "";
  numeroProductos: any = { cantidad: 0 };
  tipoMovimiento: any = 0;

  numeroRemPallet: number = 0;
  numeroRemCajas: number = 1;
  numeroRemProductos: number = 0;

  anio: string = new Date().getFullYear().toString();
  fechaIngresoDe: string = this.anio.concat((new Date().getMonth()+1).toString().length > 1 ? '-' : '-0',(new Date().getMonth()+1).toString(), "-", new Date().getDate().toString());
  fechaIngresoHasta: string = this.anio.concat('-12-31'.toString());
  fechaIngresoMin: string = (parseInt(this.anio) - 5).toString().concat('-12-31'.toString());


  responseDataTrans = new SearchDataMovimientoTransportistaResponse();

  responseDocsProductos = new SearchDocDetalleProductosResponse();
  responseDocsProductosIndi = new SearchDocDetalleProductosIndiResponse();
  responseDocsProductosCajas = new SearchDocDetalleProductosCajasResponse();
  responseDocsTotalProd = new SearchDocDetalleTotalProdResponse();
  responseDocsTotalPallet = new SearchDocDetalleTotalPalletResponse();
  responseDocsTotalCajas = new SearchDocDetalleTotalCajasResponse();
  responseDocsTotalCantidad = new SearchDocDetalleTotalCantidadResponse();
  responseDocsTotalPeso = new SearchDocDetalleTotalPesoResponse();
  responseDocsFechaMin= new SearchDocDetalleFechaMinResponse();

  responseDataObservaciones = new SearchDataMovimientoObservacionResponse();
  responseDataComentario = new SearchDataMovimientoObservacionResponse();

  vacio: string = "";
  noRegistro: string= "";
  codigoCompleto: string = "";
  today= new Date();
  fechaHoy = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US', '0500');

  overlayRef: OverlayRef; 

  noEsnormal: boolean = false;

  contador:number=1;

  asignarQR:number=0;

  latitude:string="";
  longitude:string="";

  tipoRecepcion: number = 1;
  cosechero: string = "";
  sector: string = "";

  nProductosMerma: number = 0;
  nTotalMerma: number = 0;
  nProdsRestantes: number = 0;
  saveRecep: boolean = false;
  saveforce: boolean = false;
  lstProductosMerma: any = [];

  // Variables para el domicilio del destinatario
  domicilioDest: any = {};
  // Variables para el domicilio del remitente
  domicilioRem: any = {};
  // Variables para la info del transportista
  infoTransportista: any = {};
  // Variables para la info de observaciones
  infoObservaciones: any = {};

  // Variables para embalaje de reproceso
  embalajeId: number = 0;
  embalajeLst: any = [];
  prefijoEtiq:string = "";
  autoLote:boolean = false;
  editLote:boolean = false;
  consecutivoLote:number = 0;
  cajasPorPallet:number = 0;

  getLocation() {
    this._servicio.getPosition().then(pos => {
        this.latitude = pos.lat;
        this.longitude = pos.lng;
    });
  }

  ngOnInit() {
    this.codigoQR = this._data.codigoQR;
    let objJson = JSON.parse(this.codigoQR.toString());
    this.codigoI = objJson.I;
    this.codigoF = objJson.F;
    this.contador = this._data.contador;
    this.codigoIHEXA = this._data.codigoIHEXA;
    this.codigoFHEXA = this._data.codigoFHEXA;
    this.asignarQR = this._data.asignarQR;
    if(this._data.hasOwnProperty("tipoRecepcion")) {
      this.tipoRecepcion = this._data.tipoRecepcion;
    }
    if(this._data.hasOwnProperty("numeroProductos") && this._data.hasOwnProperty("numeroRemProductos")) {
      this.numeroProductos = this._data.numeroProductos;
      this.numeroRemProductos = this._data.numeroProductos.cantidad;
      this.nTotalMerma = this.numeroProductos.merma;
    }
    if(this._data.hasOwnProperty("nProdsRestantes")) {
      this.nProdsRestantes = this._data.nProdsRestantes;
      this.numeroRemProductos = this.nProdsRestantes;
    }
    this.Busqueda();
    if(this.codigoQR == '')
    {
      this.codigoQR = null;
    }
    //////////////////////////////////////////////////////////////////
    if(!this._data.hasOwnProperty('isAgro')){
      //Si la variable isAgro regresa vacía llamamo los productores
      this.getProductoresByAcopiosIds('');
    }
  }
  busquedaProductor: string = '';
  productorDestinatario: any;
  async modelChanged(event: any){
    await this.getProductoresByAcopiosIds(event);
  }

  verSeleccionado(event: any){
    console.log('Ver selec',  this.productorDestinatario);
  }
  
  acopiosIds: string;
  async getProductoresByAcopiosIds(_busqueda: string){
    this.acopiosIds = sessionStorage.getItem('acopiosIds');
    console.log('Estos son los acopios', this.acopiosIds);
    let auxLoading: any;
    setTimeout(() => {
      auxLoading = this._overlay.open();
     });
    let data = {
      acopiosIds: this.acopiosIds,
      busqueda: _busqueda
    }
    this.dataService.postData<any>("Acopio/getProductoresByAcopio", sessionStorage.getItem("token"), data).subscribe(
      data => {
        if(data['messageEsp'] == null || data['messageEsp'] == ''){
          //Indica que la petición fue exitosa
          this._productoresByAcopioId = data['searchListProductorByAcopio'];
        }
        setTimeout(() => {
            this._overlay.close(auxLoading);
        });
      },
      error => {
        setTimeout(() => {
            this._overlay.close(auxLoading);
        },1);
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
      }
    );
  }

  
  openDialogRecibir(): void {
    if(this.codigoQRInput.trim() == "") {
      this.snack.open('Ingrese o escaneé un código QR válido','Aceptar', { duration: 10000});
      return;
    }

    this.codigoQRInput = decodeURIComponent(this.codigoQRInput);
    let result = "";
    for(let x=0;x<this.codigoQRInput.length; x++) {
      result += this.codigoQRInput[x].replace("[", '"').replace("-", "/").replace("'", "-").replace("Ñ", ":").replace("ñ",":").replace("*","}").replace("¿", "=").replace("_", "?").replace("¨", "{");
    };
    this.codigoQRInput = result;

    if(!this.codigoQRInput.includes("{")) {
      let request:any = {};
      
      if(this.codigoQRInput.includes("/") || this.codigoQRInput.includes("-")) {
        this.codigoQRInput = this.codigoQRInput.replace("/", "-");
        request.codigoQR = this.codigoQRInput.includes("C-") ? this.codigoQRInput.split("-")[1].substring(1).trim() : this.codigoQRInput;
      } else {
        request.codigoQR = this.codigoQRInput.trim();
      }
      // Buscar por codigo de caja
      setTimeout(() => {
        this.overlayRef = this._overlay.open();
      }, .001);
      // Revisar si es agro
      if(this._data.hasOwnProperty("isAgro")) {
        request.isAgro = true;
      }
      request.isRecibe = true;
      this.dataService.postData<any>("Movimientos/searchMovimientoByCode", sessionStorage.getItem("token"), request).subscribe(
        data => {
          console.warn('Recibir', data);
          this._overlay.close(this.overlayRef);
          this.codigoQRInput = data.codigoQR;
          if(this.codigoQRInput == undefined || this.codigoQRInput == '') {
            this.snack.open('No se encontraron resultados','Aceptar', { duration: 10000});
          } else {
            this.openDialogRecibir();
          }
        },
        error => {
          this.snack.open('Error al consultar el código','Aceptar', { duration: 10000});
          console.log(error);
          this._overlay.close(this.overlayRef);
        }
      );
    } else {
      this._dialogRef.close();
      this.codigoQR = this.codigoQRInput.includes("qr=") ? this.codigoQRInput.split("qr=")[1].trim() : this.codigoQRInput.trim();
      if(this.codigoQR.includes('/')) {
        result = "";
        for(let x=0;x<this.codigoQR.length; x++) {
          result += this.codigoQR[x].replace('/', '-');
        };
        this.codigoQR = result;
      }
      this.codigoQRInput = '';
      let data:any = {
        codigoQR : this.codigoQR,
        codigoI : this.codigoI,
        codigoF : this.codigoF,
        contador : this.contador,
        codigoIHEXA : this.codigoIHEXA,
        codigoFHEXA : this.codigoFHEXA,
        asignarQR : this.asignarQR,
        tipoRecepcion: this.tipoRecepcion
      }
  
      
      if(this.tipoRecepcion == 2){
        data.numeroProductos = this._data.numeroProductos;
        data.numeroRemProductos = this._data.numeroProductos.cantidad;
        data.nProdsRestantes = this.nProdsRestantes;
      }
  
      // Revisar si es agro
      if(this._data.hasOwnProperty("isAgro")) {
        data.isAgro = true;
      }
  
      const dialogRef =this.dialog.open(DialogoRecibirBusComponent,{
      height: 'auto',
      disableClose: true,
      data: data
      });   
  
      dialogRef.afterClosed().subscribe(res=>{
      if(res){
          console.log('Listo para buscar movimientos a recibir');
      }
      else{
          console.log('Error');
      }
      });
    }
  }  


  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    var request: any = {};
    request.codigoQR = this.codigoQR;
    if(this.asignarQR!=null)
    {
      this.asignarQR=1;
    }
    else{
      this._servicio.codigoQR = this.codigoQR;
      this.asignarQR=1;
    }
    let objJson = JSON.parse(this.codigoQR.toString());
    if(objJson.T == "P" && ((this.tipoRecepcion == 1 || this.tipoRecepcion == 2) && !this._data.hasOwnProperty("isAgro") || this.tipoRecepcion == 2 && this._data.hasOwnProperty("isAgro"))) {
      this.saveRecep = true;
      this.responseDataData = new SearchDataMovimientoGeneralRecepResponse2();
      this.responseData = new SearchDataMovimientoGeneralRecepResponse();
      this.numeroPallet = 0;
      this.numeroCajas = 0;
      this.tipoMovimiento = this.tipoMovimiento;
      this.cosechero = "";
      this.sector = "";
      this.snack.open('Favor de leer una caja o una agrupación','Aceptar', { duration: 10000});
      return;
    } else {
      this.saveRecep = false;
    }

    request.codigoI = this.codigoI;
    request.codigoF = this.codigoF;
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      request.isAgro = true;
    }

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);
    this.dataService.postData<any>("Movimientos/searchDataMovimientoGeneralRecep2", sessionStorage.getItem("token"), request).subscribe(
      data => {
        if(data.movimientosDataGeneralRecepList.length != 0){
          this.responseDataData = data;
          this.nombreAgru = this.responseDataData.movimientosDataGeneralRecepList[0].nombreAgrupacion.toString();
          this.responseData.messageEng = data.messageEng;
          this.responseData.messageEsp = data.messageEsp;
          this.responseData.movimientosDataGeneralRecepList = data.movimientosDataGeneralRecepList[0];
          if(request.isAgro && this.tipoRecepcion == 1 && objJson.T == "P") {
            this.numeroPallet = 1;
          } else {
            this.numeroPallet = this.responseData.movimientosDataGeneralRecepList.numeroPallet;
          }
          //this.numeroCajas = this.responseData.movimientosDataGeneralRecepList.numeroCajas;
          this.tipoMovimiento = this.responseData.movimientosDataGeneralRecepList.tipoMovimiento;
          this.codigoQR = this.responseData.movimientosDataGeneralRecepList.codigoQR;
          this._servicio.codigoQR = this.codigoQR;

          let tmpdate = new Date(this.responseData.movimientosDataGeneralRecepList.fechaProduccion);
          let strdate = tmpdate.getFullYear()+"";
          strdate += (tmpdate.getMonth() + "").length > 1 ? "-"+(tmpdate.getMonth() + 1) : "-0"+(tmpdate.getMonth() + 1);
          strdate += (tmpdate.getDate()+"").length > 1 ? "-"+tmpdate.getDate() : "-0" + tmpdate.getDate();//dia
          this.fechaIngresoDe = this.responseData.movimientosDataGeneralRecepList.fechaProduccion == "0001-01-01T00:00:00" ? this.fechaIngresoDe : strdate;
          this.cosechero = this.responseData.movimientosDataGeneralRecepList.cosechero;
          this.sector = this.responseData.movimientosDataGeneralRecepList.sector;
        } else {
          this.responseDataData = new SearchDataMovimientoGeneralRecepResponse2();
          this.responseData = new SearchDataMovimientoGeneralRecepResponse();
          this.numeroPallet = 0;
          //this.numeroCajas = 0;
          this.tipoMovimiento = this.tipoMovimiento;
          this.cosechero = "";
          this.sector = "";
          this._overlay.close(this.overlayRef);
          this.snack.open('Movimiento no encontrado','Aceptar', { duration: 10000});
          return;
        }
        this.Busqueda2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

   //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
   Busqueda2() {

    if(parseInt(this.codigoI, 16) && parseInt(this.codigoF, 16)){
      let codigoIDecimal = parseInt(this.codigoI, 16);
      let codigoFDecimal = parseInt(this.codigoF, 16);

      this.codigoIHEXA = parseInt(codigoIDecimal.toString().slice(6));
      this.codigoFHEXA = parseInt(codigoFDecimal.toString().slice(6));

    }else{
      this.codigoIHEXA =0;
      this.codigoFHEXA =0;
    }
    
    var request:any = {};
    request.codigoQR = this.codigoQR;
    request.codigoI = this.codigoI;
    request.codigoF = this.codigoF;
    request.codigoIHEXA = this.codigoIHEXA.toString();
    request.codigoFHEXA = this.codigoFHEXA.toString();
    request.familiaProductoId = 0;
    if(this._data.hasOwnProperty("numeroProductos") && this._data.hasOwnProperty("numeroRemProductos")) {
      request.familiaProductoId = this._data.numeroProductos.hasOwnProperty("familiaProductoId") ? this._data.numeroProductos.familiaProductoId : 0;
      request.productoMovimientoId = this._data.numeroProductos.hasOwnProperty("productoMovimientoId") ? this._data.numeroProductos.productoMovimientoId : 0;
    }

    if(this.codigoIHEXA != 0 && this.codigoFHEXA != 0)
    {
      request.isHexa = true;
    }else{
      request.isHexa = false;
    }
    request.isAgro = false;
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      request.isAgro = true;
    }
    this.numeroCajas = 0;
    this.dataService.postData<SearchDataMovimientoGeneralProdRecepResponse>("Movimientos/searchDataMovimientoGeneralProductoRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData2 = data;
        if(this.responseData2.movimientosDataGeneralDesProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralOperaProdRecepList.length == 0
          && this.responseData2.movimientosDataGeneralProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralUnicoProdRecepList.length == 0){
          this.responseDataData = new SearchDataMovimientoGeneralRecepResponse2();
          this.responseData = new SearchDataMovimientoGeneralRecepResponse();
          this.numeroPallet = 0;
          this.numeroCajas = 0;
          this.tipoMovimiento = this.tipoMovimiento;
          this.cosechero = "";
          this.sector = "";
          this._overlay.close(this.overlayRef);
          this.saveRecep = true;
          this.snack.open('Favor de realizar el envío de la caja','Aceptar');
          return;
        }
        if(this.tipoRecepcion == 1) {
          this.numeroProductos = this.responseData2.movimientosDataGeneralProdRecepList[0]; 
          this.nProdsRestantes = this.numeroProductos.cantidad - (this.numeroProductos.merma + this.numeroProductos.productosRecibidos) <= 0 ? 0 : this.numeroProductos.cantidad - (this.numeroProductos.merma + this.numeroProductos.productosRecibidos)
          this.numeroRemProductos = this.nProdsRestantes;
          this.nTotalMerma = this.numeroProductos.merma;
        }
        this.responseData2.movimientosDataGeneralProdRecepList.forEach(item => {
          this.numeroCajas += item.numeroCajas;
        });
        this.saveRecep = this.numeroRemProductos == 0;
        
        if(this.responseData2.movimientosDataGeneralDesProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralOperaProdRecepList.length == 0
          && this.responseData2.movimientosDataGeneralProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralUnicoProdRecepList.length == 0){
          //Obtener valor de rango en formato Json
          if(JSON.parse('{ "return":'+this._servicio.codigoQR+' }')){
            let objJson = JSON.parse('{ "return":'+this._servicio.codigoQR+' }')
            let inicial = objJson.return.I;
            let final = objJson.return.F;
            let tipo = objJson.return.T;

            this.codigoQR = tipo;
            this.codigoI = inicial;
            this.codigoF = final;
          }

          if(this.contador==2)
          {
            this.snack.open('Registro no encontrados','Aceptar');
          }
          else{
            this.contador = 2;
            if(this.codigoQR==null ){
              this.codigoQR="";
              this.codigoI="";
              this.codigoF="";
              this.codigoIHEXA=0;
              this.codigoFHEXA=0;
            }
            this.openDialogRecibir();
            this.vacio="";
            this.movimientoId = 0;
          }
          this._overlay.close(this.overlayRef);
          this._dialogRef.close();
          
        }
        else{
          if(this.codigoQR.length >2)
          {
            this.vacio="*";
            this.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;
          }
          else{
            this.vacio="";
            this.movimientoId = 0;
          }
          
          this.contador=1;
        }
        
        // Recepcion forzada cuando la cantidad de productos del envio es completado
        if(this.movimientoId > 0 && request.isAgro && this.nProdsRestantes <= 0 && this.tipoRecepcion == 1) {
          this.saveforce = true;
        }

        this.updateInfoOperation();
        this._overlay.close(this.overlayRef);

        // obtener embalajes reproceso de acuerdo a la familia seleccionada previamente y el movimiento aún no se recibe de manera completa
        if(this.movimientoId > 0 && !request.isAgro && this.nProdsRestantes > 0 && this.tipoRecepcion == 2) {
          console.log("obtener embalajes reproceso");
          this.getEmbalajeLst();
        }
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda2");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  openDialogVerDetalleProductos(): void {
    this.numeroRemProductos = 0;
    
    let _dialogoRef = this.dialog.open(DialogoVerDetalleProductosComponent, {
      panelClass: "dialog-min",
      data: {responseData2: this.responseData2, numeroProductos: this.numeroProductos}
    });
    _dialogoRef.afterClosed().subscribe(res=>{
      this.numeroProductos = res.selectedProduct;
      if(!res.selectedProduct.hasOwnProperty("movimientoId")) {
        this.snack.open('Debe seleccionar un producto a recibir','Aceptar');
      } else {
        this.numeroRemProductos = res.selectedProduct.cantidad;
      }
    });
  }

  updateInfoOperation(opc = 0) {
    switch (opc) {
      case 1:
        let temprs = (this.numeroRemCajas * (this.numeroProductos.cantidad / this.numeroCajas)) - (this.nProductosMerma);
        if((this.nProdsRestantes - (temprs + this.nProductosMerma)) < 0) {
          this.numeroRemCajas = this.numeroRemCajas - 1;
        }
        this.numeroRemProductos = (this.numeroRemCajas * (this.numeroProductos.cantidad / this.numeroCajas)) - (this.nProductosMerma);
        if(!this._data.hasOwnProperty('isAgro') && this.tipoRecepcion == 2 && this.responseData.movimientosDataGeneralRecepList.movimientoId > 0) {
          this.numeroPallet = this.cajasPorPallet > 0 ? Math.ceil(this.numeroRemCajas/this.cajasPorPallet) : 1;
        }
        break;
      default:
        this.numeroRemCajas = Math.ceil((this.numeroRemProductos + this.nProductosMerma) / (this.numeroProductos.cantidad / this.numeroCajas));
        break;
    }
    //this.numeroRemPallet = Math.ceil(this.numeroRemCajas / (this.numeroCajas / this.numeroPallet));
    //this.numeroRemPallet = isNaN(this.numeroRemPallet) ? this.numeroPallet : this.numeroRemPallet;
    //this.numeroRemCajas = this.saveRecep ? 1 : this.numeroCajas;
  }

  getEmbalajeLst() {
    //this.qrElement.nativeElement.focus();
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);
    let request: any = {};
    request.ids = this.numeroProductos.familiaProductoId;
    this.dataService.postData<any>("Movimientos/searchPackagingByProduct", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        console.log("embalajelst data: ", data);
        console.log("this.numeroProductos: ", this.numeroProductos);
        this.embalajeLst = data.packagingLst;
        this.embalajeId = this.numeroProductos.embalajeId;
        if(this.embalajeLst.length > 0) {
          let embSelected = this.embalajeLst.filter(x => x.id == this.embalajeId);
          console.log("embSelected: ", embSelected);
          if(embSelected.length > 0) {
            this.prefijoEtiq = embSelected[0].prefix;
            this.autoLote = embSelected[0].autoLote ? true : false;
            this.editLote = embSelected[0].editLote ? true : false;
            this.consecutivoLote = embSelected[0].consecutiveLote;
            this.cajasPorPallet = embSelected[0].boxesPerPallet;

            this.numeroCajas = Math.ceil(this.numeroProductos.cantidad/embSelected[0].unitsPerBox);
            this.numeroRemCajas = this.numeroProductos.productosRecibidos == 0 ? 0 : this.numeroRemCajas;
            this.updateInfoOperation(1);
            // Actualizar y Obtener el consecutivo más reciente
            if(this.autoLote && !this.editLote && this.responseData.movimientosDataGeneralRecepList.movimientoId > 0) {
              request = {};
              request.productId = this.numeroProductos.familiaProductoId;
              request.id = this._data.id;
              setTimeout(() => {
                this.overlayRef = this._overlay.open();
              }, .001);
              this.dataService.postData<any>("Movimientos/SaveConsecutiveGenericProduct", sessionStorage.getItem("token"), request).subscribe(
                dataCons => {
                  this._overlay.close(this.overlayRef);
                  this.sector = this.prefijoEtiq != "" ? this.prefijoEtiq + '-' + dataCons.consecutiveEnd : dataCons.consecutiveEnd;
                  console.log("Consecutivo familia:", dataCons);
                },
                error => {
                  this.openSnack('Error al consultar los embalajes','Aceptar');
                  this._overlay.close(this.overlayRef);
                }
              );
            } else {
              this.sectorElement.nativeElement.focus();
            }
          } else {
            this.saveRecep = true;
            this.openSnack('No se ha seleccionado un embalaje de reproceso','Aceptar');
          }
        }
        console.log("this.embalajeId: ", this.embalajeId);
      },
      error => {
        this.openSnack('Error al consultar los embalajes','Aceptar');
        this._overlay.close(this.overlayRef);
      }
    );
  }

  openDialogInfoRemitente(productorDestinatario) {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataDest:any = {
      productorDestinatario: productorDestinatario,
      //referenciaInterna: this.referenciaInterna,
      //referenciaExterna: this.referenciaExterna
    }
    const dialogRef =this.dialog.open(DialogoInfoRemitenteComponent,{
      height: modalHeight,
      disableClose: true,
      data: dataDest
    }); 
  }

  openDialogInfoDestinatario(): void {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataDest:any = {
      id: this.movimientoId > 0 ? this.responseData.movimientosDataGeneralRecepList.movimientoId : 0,
      codigoQR: this.codigoQR
    }

    if(this.domicilioDest.hasOwnProperty("ranchoD")) {
      dataDest.domicilio = this.domicilioDest;
    }

    const dialogRef =this.dialog.open(DialogoInfoDestinatarioComponent,{
      height: modalHeight,
      disableClose: true,
      data: dataDest
    }); 
    dialogRef.afterClosed().subscribe(res=>{
      if(typeof(res) == "object"){
        if(res.domicilio.paisD == 241 && 
          res.domicilio.estadoD == 36 &&
          res.domicilio.ciudadD == "" &&
          res.domicilio.cpD == 0 &&
          res.domicilio.domicilioD == "" &&
          res.domicilio.nombreDestinatario == "" &&
          res.domicilio.apellidoDestinatario == "" &&
          res.domicilio.nombreCompaniaD == "" &&
          res.domicilio.rzCompaniaD == "" &&
          res.domicilio.telefonoD == "" &&
          res.domicilio.ranchoD == "" &&
          res.domicilio.sectorD == "") {
          // Sin domicilio
          this.domicilioDest = {};
        } else {
          // Con domicilio precargado
          this.domicilioDest = res.domicilio;
        }
      }
    });
  }

  openDialogScan(): void {
    this.codigoQRInput = "";
    let data: any = { tipoRecepcion: this.tipoRecepcion, recepEvent: this._dialogRef }
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      data.isAgro = true;
    }
    const dialogRef =this.dialog.open(ScanQrComponent,{
      width: 'auto',
      height: 'auto',
      data: data
    }); 

    if(this.tipoRecepcion == 1) { this._dialogRef.close(); }
  }


  //#region Métodos (click)

  openDialogInfoLegal(): void {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    const dialogRef =this.dialog.open(DialogoInfoLegalComponent,{
      height: modalHeight,
      disableClose: true,
      data: {
        id: this.movimientoId > 0 ? this.responseData.movimientosDataGeneralRecepList.movimientoId : "0",
        codigoQR: this.codigoQR
      }
    });    
    dialogRef.afterClosed().subscribe(res=>{
      if(res){
        console.log('Datos de movimiento encontrados');
      }
      else{
        console.log('Error');
      }
    });
  }

  openDialogObservaciones(): void {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataObs:any = {
      id: this.movimientoId > 0 ? this.responseData.movimientosDataGeneralRecepList.movimientoId : "0",
      codigoQR: this.codigoQR
    }

    if(this.infoObservaciones.hasOwnProperty("observacion")) {
      dataObs.infoObservaciones = this.infoObservaciones;
    }
    
		const dialogRef =this.dialog.open(DialogoObservacionesRecepComponent,{
      height: 'auto',
      disableClose: true,
      data: dataObs
    });    
    dialogRef.afterClosed().subscribe(res=>{
      if(typeof(res) == "object"){
        if(res.infoObservaciones.observacion == "") {
          // Sin info
          this.infoObservaciones = {};
        } else {
          // Con info precargado
          this.infoObservaciones = res.infoObservaciones;
        }
      }
    });
  }

  openDialogMermas(): void{
    let setWidthMermas = '55%';
    if ( screen.width <= 576) { setWidthMermas = '90%'; }
		if (screen.width > 576 && screen.width <= 768) { setWidthMermas = '80%'; }
		if (screen.width > 768 && screen.width <= 992) { setWidthMermas = '55%'; }
		if (screen.width > 992 && screen.width <= 1200) { setWidthMermas = '55%'; }
    if (screen.width > 1200) { setWidthMermas = '55%'; }
    
    if(!this.numeroProductos.hasOwnProperty("movimientoId")) {
      this.snack.open('Seleccione un producto','Aceptar');
      return;
    }

    let _dialogRef =this.dialog.open(DialogoMermaComponent,{
      height: 'auto',
      panelClass: "dialog-min",
      data: {
          id: this.codigoQR,
          tipoRecepcion: this.tipoRecepcion,
          nombreAgrupacion: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion,
          producto: this.numeroProductos,
          numeroPallet: this.numeroPallet,
          numeroCajas: this.numeroCajas,
          numeroRemPallet: this.numeroRemPallet,
          numeroRemCajas: 1,
          numeroProductos: this.numeroRemProductos,
          numeroRemProductos: this.nProdsRestantes - this.numeroRemProductos
      }
    });
    _dialogRef.afterClosed().subscribe(res=>{
      this.nProductosMerma = res;
      this.updateInfoOperation();
    });
  }

  /*async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Detalle Pedido',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([ 20, 20, 20, 20 ]);

    pdf.add(
      new Columns([
        new Table(
        [
          [ {text: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion, bold:true},
            {qr:this.responseData.movimientosDataGeneralRecepList.codigoQR, fit:75} 
          ],
          [ ' ','' ],
          [{text:'Referencia interna: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaInterna],
          [{text:'Referencia externa: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaExterna]
        ]).widths(['auto', 'auto']).alignment('center').layout('noBorders').end,
        new Table(
          [
            [ {text:'Destinatario', bold:true},
              {text:'Información de Transporte', bold:true}, 
              {text:'Remitente', bold:true} 
            ],
            [ {text:'Compañia / Razón Social: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, marginLeft:5, fontSize:'10'},
              {text:'Núm de Referencia: \n '+ this.responseDataTrans.movimientosDataTransportistaList.numReferencia, marginLeft:5, fontSize:'10'},
              {text:'Compañia / Razón Social: \n '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, marginLeft:5, fontSize:'10'}
            ],
            [ {text:'Dirección: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD, marginLeft:5, fontSize:'10'}, 
              {text:'Comp. Transportista: \n '+this.responseDataTrans.movimientosDataTransportistaList.transportista, marginLeft:5, fontSize:'10'},
              {text:'Contacto: \n '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreRemitente+' '+this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, marginLeft:5, fontSize:'10'}
            ],
            [ {text:'Contacto: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, marginLeft:5, fontSize:'10'}, 
              {text:'Fecha de Embarque: \n '+this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, marginLeft:5, fontSize:'10'},
              {text:' ', marginLeft:5, fontSize:'10'}
            ]
          ]).widths(['auto', 'auto','auto']).margin([-130,0,20,0]).end,
        await new Img('../../assets/img/logofondo3.png').width('100').build()
      ]).end
      
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Table([
        [ {text:'Origen de la Mercancía', bold:true},
          {text:'Información Legal', bold:true}
        ],
        [ {text:'Origen: '+this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR+', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR+', C.P. '+
            this.responseDataRem.movimientosDataRemitenteRecepList.cpR, marginLeft:5, fontSize:'10'},
          {text:'Importador: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.nombreInfo, marginLeft:5, fontSize:'10'}
        ],
        [ {text:'Nombre de compañia: '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR, marginLeft:5, fontSize:'10'}, 
          {text:'Dirección: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.direccionInfo, marginLeft:5, fontSize:'10'}
        ],
        [ {text:' ', marginLeft:5, fontSize:'10'}, 
          {text:'Contacto: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.contactoInfo, marginLeft:5, fontSize:'10'}
        ]
      ]).widths(['auto','auto']).end
    );

    pdf.add(
      pdf.ln(1)
    );
  //Cabeceras
    pdf.add(
      new Table([
        [{text:'Consolidado de Productos',bold:true,alignment:'left', colSpan:8}, '','','','','','',''],
        [{text:' ', colSpan:8}, '','','','','','',''],
        [ {text:'Producto', bold:true}, 
          {text:'Pallets', bold:true}, 
          {text:'Cajas', bold:true}, 
          {text:'Unidades', bold:true}, 
          {text:'Peso Bruto', bold:true}, 
          {text:'Dimensiones', bold:true}, 
          {text:'Caducidad Min.', bold:true}, 
          {text:'Lote', bold:true}
        ]
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );
  //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          [''+products.producto, ''+products.numPallet, ''+products.numCajas, ''+products.cantidad, ''+products.pesoBruto+' Kgs', ''+products.dimensiones, ''+products.fechaCaducidad, ''+products.lote],
        ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
      );
    }
  //Totales
    pdf.add(
      new Table([
        [ ' ', '', '', '', '', '', '', ''],
        [ {text:'Total', bold:true}, '', '', '', '', '', '', ''],
        [ ''+this.responseDocsTotalProd.docDetalleTotalProdList.totalProducto+' productos', ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet+' pallets', ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas+' cajas', 
          ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad+' unidades', ''+this.responseDocsTotalPeso.docDetalleTotalPesoList.totalPeso+' Kgs', '---', ''+this.responseDocsFechaMin.docDetalleFechaMinList.fechaMin, ''],
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );


    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      pdf.ln(22)
    );
    
    pdf.add(
      new Table(
      [
        [ {text: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion, bold:true, rowSpan:2},
          '', 
          {text:'Referencia interna: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaInterna, 
          {text:'Referencia externa: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaExterna,  
        ],
        [' ',' ',' ',' ',' ',' '],
        [ {text:'Pallets:', marginLeft:30}, ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet, 
          {text:'Cajas:', marginLeft:60}, ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas, 
          {text:'Total de Unidades:', marginLeft:30}, ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad]
      ]).widths(['auto', 'auto', 'auto', 'auto', 'auto', 'auto']).alignment('center').layout('noBorders').margin([150,0,150,0]).end
    );
    pdf.add(
      pdf.ln(1)
    );
    pdf.add(
      new Table([
        [ {text:' ', bold:true}, 
          {text:'Familia', bold:true}, 
          {text:'CIU', bold:true}, 
          {text:'Lote', bold:true}, 
          {text:'Caducidad', bold:true}, 
          {text:'Número de Serie', bold:true} 
        ]
      ]).widths([30, 100, 100, 100, 100, 100]).alignment('center').fontSize(11).margin([100,0,150,0]).end
    );
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 100, 100, 100, 100, 100]).alignment('center').fontSize(11).margin([100,0,150,0]).end
      );
      pdf.create().open();
    }
  }*/

  openDialogRecepcionRegistrada(): void {
    const dialogRef =this.dialog.open(DialogoRecepcionRegistradaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

  openDialogActualizarNombre(): void{
    const dialogRef =this.dialog.open(DialogoNombreRecepcionComponent,{
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");

    requestLogin.password = sessionStorage.getItem("password");
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "Busqueda2":
                  this.Busqueda2();
                  break;
                case "recibir":
                  this.recibir();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "Busqueda2":
                  this.Busqueda2();
                  break;
                case "recibir":
                  this.recibir();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  recibir(){
    if(this.numeroRemCajas <= 0) {
      this.openSnack("Este pedido fue recibido previamente", "Aceptar");
      return false;
    }
    var requestUpdate: any = {};
    requestUpdate.tipoRecepcion = this.tipoRecepcion;
    requestUpdate.cosechero = this.cosechero;
    requestUpdate.sector = this.sector;
    if(this.fechaIngresoDe != "") {
      if(this.fechaIngresoDe.split("-").length > 2) {
        let tmpdate = this.fechaIngresoDe.split("-");
        tmpdate[2] = tmpdate[2].length > 1 ? parseInt(tmpdate[2]) + "" : tmpdate[2];
        this.fechaIngresoDe = tmpdate.join("-");
      }
    }
    requestUpdate.acopioId = this.productorDestinatario.acopioId;
    requestUpdate.productorId = this.productorDestinatario.productorId;
    requestUpdate.fechaProduccion = new Date(this.fechaIngresoDe);
    requestUpdate.productosRecibidos = this.numeroRemProductos;
    requestUpdate.nCajasRecibidas = this.tipoRecepcion == 1 ? this.numeroCajas : this.numeroRemCajas;
    requestUpdate.nPalletsRecibidos = 0;
    requestUpdate.nProductosMerma = this.nProductosMerma;
    requestUpdate.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;
    requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));
    requestUpdate.fecha = this.fechaHoy;
    requestUpdate.totalProductosQR = this.responseData2.movimientosDataGeneralProdRecepList.length > 0 ? this.responseData2.movimientosDataGeneralProdRecepList[0].cantidad : 0;

    if(this._data.hasOwnProperty("numeroProductos") && this._data.hasOwnProperty("numeroRemProductos")) {
      requestUpdate.familiaProductoId = this._data.numeroProductos.hasOwnProperty("familiaProductoId") ? this._data.numeroProductos.familiaProductoId : 0;
      requestUpdate.productoMovimientoId = this._data.numeroProductos.hasOwnProperty("productoMovimientoId") ? this._data.numeroProductos.productoMovimientoId : 0;
    }

    if (this.codigoI == null && this.codigoF == null)
    {
      requestUpdate.noEsnormal = false;
    }else{
      requestUpdate.noEsnormal = true;
    }
    requestUpdate.codigoI = this.codigoI;
    requestUpdate.codigoF = this.codigoF;
    requestUpdate.codigoTipo = this.codigoQR; //Variable de multiuso
    requestUpdate.codigoCompleto = this._servicio.codigoQR;

    // Actualizar nombre agrupación
    if(this.nombreAgru.trim() != this.responseDataData.movimientosDataGeneralRecepList[0].nombreAgrupacion.toString().trim()) {
      if(this.nombreAgru == undefined || this.nombreAgru.trim() == "") {
        this.openSnack("Ingrese un nombre para el movimiento", "Aceptar");
        return false;
      }
      requestUpdate.nombre = this.nombreAgru.toString().trim();
      requestUpdate.isUpName = true;
    }
    
    //Obtener valor de rango en formato Json
    if(JSON.parse('{ "return":'+this._servicio.codigoQR+' }')){
      let objJson = JSON.parse('{ "return":'+this._servicio.codigoQR+' }')
      let caja = objJson.return.ID;
      requestUpdate.caja = caja;
    }else{
      requestUpdate.caja = '';
    }

    requestUpdate.codigoIHEXA = this.codigoIHEXA.toString();
    requestUpdate.codigoFHEXA = this.codigoFHEXA.toString();

    if(this.codigoIHEXA != 0 && this.codigoFHEXA != 0)
    {
      requestUpdate.isHexa = true;
    }else{
      requestUpdate.isHexa = false;
    }

    // Registrar el domicilio Remitente
    if(this.domicilioRem.hasOwnProperty("ranchoR") && (this.movimientoId == -1 || this.movimientoId == 0)) {
      requestUpdate.domicilioUpRem = true;
      requestUpdate.domicilioRem = this.domicilioRem;
    }
    // Registrar el domicilio Destinatario
    if(this.domicilioDest.hasOwnProperty("ranchoD") && (this.movimientoId == -1 || this.movimientoId == 0)) {
      requestUpdate.domicilioUp = true;
      requestUpdate.domicilio = this.domicilioDest;
    }
    // Registrar el Transportista
    if(this.infoTransportista.hasOwnProperty("transportista") && (this.movimientoId == -1 || this.movimientoId == 0)) {
      requestUpdate.infoUpTras = true;
      requestUpdate.infoTransportista = this.infoTransportista;
    }
    // Registrar las Observaciones
    if(this.infoObservaciones.hasOwnProperty("observacion") && (this.movimientoId == -1 || this.movimientoId == 0)) {
      requestUpdate.infoUpObs = true;
      requestUpdate.infoObservaciones = this.infoObservaciones;
    }
    requestUpdate.isAgro = false;

    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      requestUpdate.isAgro = true;
    }

    // Recepcion forzada cuando el envio es completada
    if(this.movimientoId > 0 && requestUpdate.isAgro && this.nProdsRestantes <= 0 && this.tipoRecepcion == 1) {
      requestUpdate.productosRecibidos = this.numeroProductos.cantidad;
      requestUpdate.force = true;
    }

    // Indicar si es Recepcion de un pallet
    let objJsonAgru = JSON.parse(requestUpdate.codigoCompleto);
    if(this._data.hasOwnProperty("isAgro") && this.tipoRecepcion == 1 && objJsonAgru.T == "P") {
      objJsonAgru.T = "A";
      requestUpdate.isPallet = true;
      requestUpdate.codigoCompleto = '{"T":"A","P":'+objJsonAgru.P+',"I":"'+objJsonAgru.I+'","F":"'+objJsonAgru.F+'","ID":"Agrupacion"}';
    }

    //this.guardarMermas();
    //console.log("requestUpdate", requestUpdate);
    //console.log("nrestantes", this.nProdsRestantes);
    //return;
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<EditDataAgruAEnvioResponse>("Movimientos/editDataEnvioARecep", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            this._dialogRef.close();
            if (data.messageEsp != "") {
              this.openSnack(data.messageEsp, "Aceptar");
              this._overlay.close(this.overlayRef);
            } else {
              this.openSnack("Recepción realizada con éxito", "Aceptar");
              //this.openDialogActualizarNombre();
              this.openDialogRecepcionRegistrada();
              if(sessionStorage.getItem("name") == ''){
                this.openDialogRegistrate();
              }
              this.dialogRef.close(true);
              this._overlay.close(this.overlayRef);
            }
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("recibir");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }

  guardarMermas() {
    let request = this.numeroProductos;
    request.nProductosMerma = this.nProductosMerma; 
    return;
    this.dataService.postData<any>("Movimientos/editDataMovimientoMerma", sessionStorage.getItem("token"), request).subscribe(
      data => {
          if (data.messageEsp != "") {
              this.openSnack(data.messageEsp, "Aceptar");
          } else {
            this.openSnack("Recepción realizada con éxito", "Aceptar");
            //this.openDialogActualizarNombre();
            this.openDialogRecepcionRegistrada();
            if(sessionStorage.getItem("name") == ''){
              this.openDialogRegistrate();
            }
            this.dialogRef.close(true);
          }
          this._overlay.close(this.overlayRef);
      },
      error => {
          this.openSnack("Error al mandar la solicitud", "Aceptar");
          this._overlay.close(this.overlayRef);
      }
    );
  }

  //#endregion

//Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
openVerDetalle()
{
  setTimeout(() => {
    this.overlayRef = this._overlay.open();
  }, .001);

  this.BusquedaDes();
}

BusquedaDes() {
    
  var request = new SearchDataMovimientoDestinatarioRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

  this.dataService.postData<SearchDataMovimientoDestinatarioResponse>("Movimientos/searchDataMovimientoDestinatario", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataDes = data; 
      this.BusquedaRem();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("BusquedaDes");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

BusquedaRem() {
    
  var request = new SearchDataMovimientoRemitenteRequest();
  request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

  this.dataService.postData<SearchDataMovimientoRemitenteResponse>("Movimientos/searchDataMovimientoRemitente", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataRem = data;
      this.BusquedaTrans();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("BusquedaRem");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

BusquedaTrans() {
    
  var request = new SearchDataMovimientoTransportistaRequest();
  request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

  this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportista", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataTrans = data;
      this.BusquedaInfo();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("BusquedaTrans");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

//Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
BusquedaInfo() {
    
  var request = new SearchDataMovimientoInfoLegalRequest();
  request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

  this.dataService.postData<SearchDataMovimientoInfoLegalResponse>("Movimientos/searchDataMovimientoInfoLegal", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataInfo = data;
      this.BusquedaDocsProductos();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("Busqueda");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsProductos() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductos", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        //this.BusquedaDetalleProductos();
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  /*BusquedaDetalleProductos() { // Productos tabla Pallets
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = this.codigoQR;

    this.dataService.postData<SearchDocDetalleProductosIndiResponse>("Movimientos/searchDocDetalleProductosIndi", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosIndi = data;
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDetalleProductos2() { // Productos tabla Cajas
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = '' + this.responseData.movimientosDataGeneralRecepList.movimientoId;

    //Obtener valor de rango en formato Json
    if(JSON.parse('{ "return":'+this.responseData.movimientosDataGeneralRecepList.codigoQR+' }')){
      let objJson = JSON.parse('{ "return":'+this.responseData.movimientosDataGeneralRecepList.codigoQR+' }')
      let inicial = objJson.return.I;
      let final = objJson.return.F;
      let tipo = objJson.return.T;

      request.codigoTipo = tipo;
      request.codigoI = inicial;
      request.codigoF = final;
    }
    

    this.dataService.postData<SearchDocDetalleProductosCajasResponse>("Movimientos/searchDocDetalleProductosCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosCajas = data;
        this.BusquedaDocsTotalProd();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalProd() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalProdResponse>("Movimientos/searchDocDetalleTotalProd", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalProd = data;
        //this.BusquedaDocsTotalPallet();
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalProd");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  /*BusquedaDocsTotalPallet() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPalletResponse>("Movimientos/searchDocDetalleTotalPallet", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPallet = data;
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPallet");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCajas() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCajasResponse>("Movimientos/searchDocDetalleTotalCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCajas = data;
        this.BusquedaDocsTotalCantidad();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCajas");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCantidad() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCantidadResponse>("Movimientos/searchDocDetalleTotalCantidad", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCantidad = data;
        this.BusquedaDocsTotalPeso();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCantidad");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPeso() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPesoResponse>("Movimientos/searchDocDetalleTotalPeso", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPeso = data;
        this.BusquedaDocsFechaMin();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPeso");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsFechaMin() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    this.dataService.postData<SearchDocDetalleFechaMinResponse>("Movimientos/searchDocDetalleFechaMin", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsFechaMin = data;
        this.BusquedaComentario();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsFechaMin");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaComentario() {
      
    var request = new SearchDataMovimientoObservacionesRequest();
    request.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcion", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataComentario = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaObservaciones() {
      
    var request = new SearchDataMovimientoObservacionesRecepRequest();
    request.codigoQR = this.codigoQR;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcionRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataObservaciones = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  openDialogRegistrate(): void {
    const dialogRef =this.dialog.open(DialogoCreeCuentaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Mensaje de éxito');
      }
      else{
        console.log('Mensaje de error');
      }
    });
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'Guía de Embarque',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 60, 20, 30 ]);

    let tableHeader = new Table([
        [ {text:this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, bold:true, fontSize:18}
        ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;

    if(this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR == "") {
      tableHeader = new Table([
        [ new Cell( new Txt(' ').bold().fontSize(12).end).fillColor("#012152").end ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;
    }

    pdf.header(
      new Columns([
        tableHeader,
        await new Img('../../assets/img/img-logo-stock-3.png').width('100').margin([-20,20,20,0]).build()
      ]).end,
    );

    pdf.add(
      pdf.ln(1)
    );

    let textCajaId = this.responseDocsProductosCajas.docDetalleProductoCajasList.length > 0 ? this.responseDocsProductosCajas.docDetalleProductoCajasList[0].codigoCaja.split(',')[0] : '';

    pdf.add(
      new Columns([
        new Table([
          ['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],
          [ {text: 'Guía de Embarque', bold:true, alignment:'left', fontSize:16, colSpan: 3} ],
          ['','',''],['','',''], ['','',''],  ['','',''],['','',''],  ['','',''],  ['','',''],  
          [ {text:'OP Interna: ', alignment:'right', bold:true}, 
            {text: this.responseData.movimientosDataGeneralRecepList.referenciaInterna, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'No. cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.numeroC, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'OP Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseData.movimientosDataGeneralRecepList.referenciaExterna, alignment:'left', color: 'gray'},
            ''
          ]
        ]).widths([80, 150, 40]).fontSize(9).layout('noBorders').end,
        new Table([
          [ {qr: enviroments.qrUrl + this.responseData.movimientosDataGeneralRecepList.codigoQR, alignment:'center', fit:100, colSpan:2},
            ''
          ],
          [ {text: textCajaId, color: 'gray', colSpan:2}]
        ]).widths([80, 150]).alignment('center').fontSize(9).layout('noBorders').end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    // Domicilio Destinatario
    let fullDomicilioDest = this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD;
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '') 
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '');
    // Domicilio Remitente
    let fullDomicilioRem = this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR;
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? ', C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? 'C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '') 
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '');

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Destinatario: ', alignment:'right', bold:true, fontSize:11}, 
            {text: /*this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+*/
                   this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioDest, alignment:'left', color: 'gray'}
          ],
          [ {text:'CP: ', alignment:'right', bold:true}, 
            {text: (this.responseDataDes.movimientosDataDestinatarioRecepList.cpD == "0" ? "" : this.responseDataDes.movimientosDataDestinatarioRecepList.cpD), alignment:'left', color: 'gray'}
          ],
          [ {text:'Tel: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.telefonoD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Remitente: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioRem, alignment:'left', color: 'gray'} 
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreRemitente+' '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Transporte: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.transportista, alignment:'left', color: 'gray'}
          ],
          [ {text:'Fecha embarque: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, alignment:'left', color: 'gray'}
          ],
          [ {text:'Referencia: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.numReferencia, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Observaciones: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.observacion, alignment:'left', color: 'gray'} ////////////////////////////////////////////////////////
          ],
          [ {text:'Dimensiones por caja (cms): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.dimensionesCaja, alignment:'left', color: 'gray'}
          ],
          [ {text:'Peso por caja (Kg): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.pesoCaja, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Resumen de Productos/Pedido: ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
          ],
          [ {text:'Producto/Familia: ', bold:true}, 
            {text: 'Marca: ', bold:true},
            {text: 'GTIN: ', bold:true},
            {text: 'Pallets: ', bold:true},
            {text: 'Cajas por pallet: ', bold:true},
            {text: 'Unidades por caja: ', bold:true},
            {text: 'Total de unidades: ', bold:true},
            {text: 'Caducidad prox: ', bold:true},
          ]
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(9).end,
      ]).end
    );

    //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      var a;
      if(products.numPallet==0){
        a=1;
      }else{
        a=products.numPallet;
      }
      pdf.add(
        new Table([
          [ {text:products.producto, alignment:'right'}, 
            {text: products.ciu, alignment:'right'}, 
            products.gtin,
            0, 
            products.numCajas/a, 
            products.cantidad/products.numCajas, 
            products.cantidad, 
            products.fechaCaducidad
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );
    }

    pdf.add(
      new Table([
        [ 
          {text:'', bold:true, colSpan:9, fontSize:11, alignment:'left',pageBreak: 'before'}, 
            '','','','','','','',''
        ]
      ]).widths([120, 40, 70, 40, 40,40,40,40,40]).alignment('center').fontSize(9). layout('noBorders').end
    );

    /*pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Pallets', colSpan:9, bold:true}, 
          '','','','','','','',''
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let pallets of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
        pdf.add(
          new Table([
            [ {text: pallets.producto, bold:true}, 
            {text: pallets.ciu, bold:true}, 
            {text: pallets.gtin, bold:true}, 
            {text: pallets.tipoEmpaque, bold:true}, 
            {text: pallets.cantidad/pallets.numPallet, bold:true}, 
            {text: pallets.codigoPallet, bold:true} ,
            {text: ' ', bold:true} ,
            {text: pallets.lote, bold:true} ,
            {text: pallets.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
        );
      }
    }*/

    pdf.add(
      pdf.ln(2)
    );
    
    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          //{text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,80,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Cajas', bold:true}
        ]
      ]).widths(['*']).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let cajas of this.responseDocsProductosCajas.docDetalleProductoCajasList) {
        pdf.add(
          new Table([
            [ {text: cajas.producto, bold:true}, 
            {text: cajas.ciu, bold:true}, 
            {text: cajas.gtin, bold:true}, 
            {text: cajas.tipoEmpaque, bold:true}, 
            {text: cajas.cantidad/cajas.numCajas, bold:true}, 
            //{text: cajas.codigoPallet, bold:true} ,
            {text: cajas.codigoCaja.includes("X") ? cajas.codigoCaja.split("X").join("") : cajas.codigoCaja, bold:true} ,
            {text: cajas.lote, bold:true} ,
            {text: cajas.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,80,40,50]).alignment('center').fontSize(8).end
        );
      }
    }

    pdf.footer(
      new Columns([
        new Table(
        [
          [ 
            {text:'Controla tus inventarios y automatiza las comunicaciones de tu almacen con Traceit Stock   https://stock.traceit.net' }]
        ]).widths(['*']).alignment('center').layout('noBorders').fontSize(7).end
      ]).end
    );

    if ( screen.width <= 576)
		{
			pdf.create().download();
		}
		if (screen.width > 576 && screen.width <= 768)
		{
      pdf.create().download();
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			pdf.create().open();
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			pdf.create().open();
		} 
		if (screen.width > 1200){
			pdf.create().open();
    }
  }
}
