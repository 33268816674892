import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialogo-registro-exitoso',
  templateUrl: './dialogo-registro-exitoso.component.html',
  styleUrls: ['./dialogo-registro-exitoso.component.css']
})
export class DialogoRegistroExitosoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoRegistroExitosoComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    private _router: Router
  ) { }

  ngOnInit() {
    this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
    this._router.navigate(["Home"]));
  }

}
