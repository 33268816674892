import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
	selector: 'app-dialogo-imprimir',
	templateUrl: './dialogo-imprimir.component.html',
	styleUrls: ['./dialogo-imprimir.component.less']
})
export class DialogoImprimirComponent implements OnInit {

	constructor(private _router: Router,
	private snack: MatSnackBar,
	private _dialogRef: MatDialogRef<DialogoImprimirComponent>,
	@Inject(MAT_DIALOG_DATA) public _data: any
	) { }

	selected_device: any;
	devices: any = [];
	printerList: any[] = [];
	selectedPrinter: number = 0;
	medianilX: number = 20;
	medianilY: number = 20;

	overlayRef: OverlayRef;

	ngOnInit() {
		this.printerList = this._data["Devices"];
	}

	accionBottom() {
		// Impresora seleccionada
		this._dialogRef.close({ result: true, printerId: this.selectedPrinter, x: this.medianilX, y:this.medianilY });
	}

	cancelBottom() {
		// Impresora seleccionada
		this._dialogRef.close({ result: false, printerId: 0, x: this.medianilX, y: this.medianilY });
	}
}
