import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DialogoCrearCuentaComponent } from '../../login/dialogo-crear-cuenta/dialogo-crear-cuenta.component'
@Component({
  selector: 'app-dialogo-cree-cuenta',
  templateUrl: './dialogo-cree-cuenta.component.html',
  styleUrls: ['./dialogo-cree-cuenta.component.css']
})
export class DialogoCreeCuentaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoCreeCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  //#region Métodos (click) 

  //Abrir ventana de Dialogo de Crear Cuenta
  openDialogCrearCuenta(): void {
    if ( screen.width <= 576)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
		if (screen.width > 1200){
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
				console.log(res);
			if(res){
				console.log('Mensaje de éxito');
			}
			else{
				console.log('Mensaje de error');
			}
			});
		} 
  }

  //#endregion

}
