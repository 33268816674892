import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogoCrearCuentaComponent } from '../login/dialogo-crear-cuenta/dialogo-crear-cuenta.component'
import { DialogoCreeCuentaComponent } from './dialogo-cree-cuenta/dialogo-cree-cuenta.component'

import { DataServices } from '../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../Interfaces/Models/LoginModels';
import { Router } from '@angular/router';
import { enviroments } from "../Interfaces/Enviroments/enviroments";

//Servicio para verificar seleccion
import { ServicesComponent } from '../Interfaces/Services/general.service';
import { ExporterService } from '../Interfaces/Services/exporter.service';

//DataServices and Models
import {
  MovimientosData,
  SearchMovimientoRequest,
  SearchMovimientoResponse,

  SearchComboMovimientoRequest,
  SearchComboMovimientoResponse
} from '../Interfaces/Models/MovimientosModels';
import { MatSnackBar } from '@angular/material';
import { OverlayService } from '../Interfaces/Services/overlay.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-header-invitado',
  templateUrl: './header-invitado.component.html',
  styleUrls: ['./header-invitado.component.css']
})
export class HeaderInvitadoComponent implements OnInit {

  deshabilitado : boolean = true;

  constructor(
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    public datepipe: DatePipe,
    public _servicio : ServicesComponent,
    private excelService: ExporterService,
  ) { }

  movimientoId:number=0;
  tipoMovimientoId: number = 0;
  responseCombos = new SearchComboMovimientoResponse();
  productoId: number = 0;
  anio: string = new Date().getFullYear().toString();
  fechaIngresoDe: string = this.anio.concat('-01-01'.toString());
  fechaIngresoHasta: string = this.anio.concat('-12-31'.toString());

  ngOnInit() {
  }

   //#region Métodos (click) 

  //Abrir ventana de Dialogo de Crear Cuenta
  openDialogCrearCuenta(): void {
    const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Mensaje de éxito');
      }
      else{
        console.log('Mensaje de error');
      }
    });
  }

  openDialogRegistrate(): void {
    const dialogRef =this.dialog.open(DialogoCreeCuentaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Mensaje de éxito');
      }
      else{
        console.log('Mensaje de error');
      }
    });
  }

  BusquedaCombos() {
    var request = new SearchComboMovimientoRequest();
    request.tipoMovimiento = this.tipoMovimientoId;

    this.dataService.postData<SearchComboMovimientoResponse>("Movimientos/searchMovimientoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseCombos = data;
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            
        } else {
          console.log(error);
        }
      }
    )
  }
  //#endregion

}
