import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialogo-genericmsg',
  templateUrl: './dialogo-genericmsg.component.html',
  styleUrls: ['./dialogo-genericmsg.component.less']
})
export class DialogoGenericmsgComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<DialogoGenericmsgComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

  mensaje: string = "";

  ngOnInit() {
    this.mensaje = this._data.mensaje;
  }

  cancelar() {
    this._dialogRef.close(false);
  }

  aceptar() {
    this._dialogRef.close(true);
  }
}
