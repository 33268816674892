import { Component, OnInit, Inject, ViewChild , ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { PdfMakeWrapper, 
  Table, 
  Img, 
  Columns,
  Cell,
  Txt, } from 'pdfmake-wrapper';
import { DialogoDestinatarioComponent } from '../envios/dialogo-destinatario/dialogo-destinatario.component';
import { DialogoLegalComponent } from '../envios/dialogo-legal/dialogo-legal.component';
import { DialogoInfoTransporteComponent } from '../dialogo-info-transporte/dialogo-info-transporte.component';
import { DialogoRemitenteComponent } from '../envios/dialogo-remitente/dialogo-remitente.component';
import { DialogoEnvioRegistradoComponent } from '../dialogo-envio-registrado/dialogo-envio-registrado.component';
import { DialogoImprimirComponent } from '../dialogo-imprimir/dialogo-imprimir.component';

import { DataServices, ServicesComponent } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { enviroments } from '../../Interfaces/Enviroments/enviroments';

import {
  SearchDataMovimientoGeneralRequest,
  SearchDataMovimientoGeneralResponse,

  SearchDataMovimientoGeneralProdRequest,
  SearchDataMovimientoGeneralProdResponse,

  
  SearchDataMovimientoDestinatarioRequest,
  SearchDataMovimientoDestinatarioResponse,
  SearchDataMovimientoRemitenteRequest,
  SearchDataMovimientoRemitenteResponse,
  SearchDataMovimientoTransportistaRequest,
  SearchDataMovimientoTransportistaResponse,
  SearchDataMovimientoInfoLegalRequest,
  SearchDataMovimientoInfoLegalResponse,

  SearchDocDetalleProductosRequest,
  SearchDocDetalleProductosResponse,
  SearchDocDetalleTotalProdResponse,
  SearchDocDetalleTotalPalletResponse,
  SearchDocDetalleTotalCajasResponse,
  SearchDocDetalleTotalCantidadResponse,
  SearchDocDetalleTotalPesoResponse,
  SearchDocDetalleFechaMinResponse,

  EditDataAgruAEnvioRequest,
  EditDataAgruAEnvioResponse, 
  SearchDataMovimientoObservacionesRequest, 
  SearchDataMovimientoObservacionResponse, 
  SearchDocDetalleProductosIndiRequest, 
  SearchDocDetalleProductosIndiResponse, 
  SearchDocDetalleProductosCajasResponse,
  SearchDataMovimientoGeneralRecepRequest,
  SearchDataMovimientoGeneralRecepResponse2
} from '../../Interfaces/Models/MovimientosModels';
import { DialogoCreeCuentaComponent } from '../../header-invitado/dialogo-cree-cuenta/dialogo-cree-cuenta.component';
import { formatDate } from '@angular/common';
import { DialogoObservacionesComponent } from '../dialogo-observaciones/dialogo-observaciones.component';
import { SearchDataFichaRequest, SearchDataFichaResponse, SearchDataFichaResponse2 } from '../../Interfaces/Models/AgrupacionesModels';
import { DialogoMermaComponent } from '../dialogo-merma/dialogo-merma.component';
import { ScanQrComponent } from '../scan-qr/scan-qr.component';
import { DialogoGenericmsgComponent } from '../dialogo-genericmsg/dialogo-genericmsg.component';
import { DialogoProductosAgrupacionComponent } from '../dialogo-productos-agrupacion/dialogo-productos-agrupacion.component';


declare var BrowserPrint: any;

// Lo necesario para acopios
import { AfterViewInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { from, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProductoresByAcopioModels } from '../../Interfaces/Models/ProductoresByAcopioModels'; 
import { rejects } from 'assert';

@Component({
  selector: 'app-dialogo-enviar',
  templateUrl: './dialogo-enviar.component.html',
  styleUrls: ['./dialogo-enviar.component.css']
})
export class DialogoEnviarComponent implements OnInit,  OnDestroy, AfterViewInit {
  selectedValue: any;
    searchTxt: any;
    @ViewChild('search') searchElement: ElementRef;
    doSomething(any){
        setTimeout(()=>{ // this will make the execution after the above boolean has changed
            this.searchElement.nativeElement.focus();
        },0);  
    }
  protected _productoresByAcopioId: ProductoresByAcopioModels[] = [];
 
  protected _onDestroy = new Subject();
  displayedColumns: string[] = ['producto', 'embalaje', 'charolas'/*, 'pallet', 'caja'*/];
  dataSource: MatTableDataSource<any>;
  
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<DialogoEnviarComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
    private _router: Router,
    public _servicio : ServicesComponent,
    private _overlay: OverlayService,
  ) { }
  
  company: number = 0;
  movimientoId : number = -1;
  codigoIHEXA : number = 0;
  codigoFHEXA : number = 0;
  responseData = new SearchDataMovimientoGeneralResponse();
  responseData2 = new SearchDataMovimientoGeneralProdResponse();
  responseDataDes = new SearchDataMovimientoDestinatarioResponse();
  responseDataRem = new SearchDataMovimientoRemitenteResponse();
  responseDataTrans = new SearchDataMovimientoTransportistaResponse();
  responseDataInfo = new SearchDataMovimientoInfoLegalResponse();
  responseDataInfoEtiqueta:any = {};

  responseDocsProductos = new SearchDocDetalleProductosResponse();
  responseDocsProductosIndi = new SearchDocDetalleProductosIndiResponse();
  responseDocsProductosCajas = new SearchDocDetalleProductosCajasResponse();
  responseDocsTotalProd = new SearchDocDetalleTotalProdResponse();
  responseDocsTotalPallet = new SearchDocDetalleTotalPalletResponse();
  responseDocsTotalCajas = new SearchDocDetalleTotalCajasResponse();
  responseDocsTotalCantidad = new SearchDocDetalleTotalCantidadResponse();
  responseDocsTotalPeso = new SearchDocDetalleTotalPesoResponse();
  responseDocsFechaMin= new SearchDocDetalleFechaMinResponse();

  responseEtiqueta = new SearchDataMovimientoGeneralResponse();

  responseDataComentario = new SearchDataMovimientoObservacionResponse();

  responseDataAgrupar = new SearchDataFichaResponse2();

  today= new Date();
  fechaHoy = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US', '0500');

  overlayRef: OverlayRef;
  
  codigoQRInput:string ="";
  codigoQR:string ="";
  codigoI:string ="";
  codigoF:string ="";
  numeroCEnvio:string = "";
  referenciaInterna:number = 0;
  referenciaExterna:number = 0;

  // Vriable para controlar el numero de charolas a elegir
  limitnBoxes: number = 0;

  // Variables para imprimir en zebra
  printerList: any[] = [];
  devices: any[] = [];
  selected_device: any;
  fileData: FormData = new FormData();
  
  // Variables para Agrupaciones
  isGroup: boolean = true;
  productosAgrupacion: any = [];
  productosAgruEmbalaje: any = [];
  embalajeList: any = [];
  detalleProductosLst: any = {};

  // Variables para el domicilio del destinatario
  domicilioDest: any = {};
  // Variables para el domicilio del remitente
  domicilioRem: any = {};
  // Variables para la info del transportista
  infoTransportista: any = {};
  // Variables para la info de observaciones
  infoObservaciones: any = {};

  // Variable para validar info del Destinatario 
  isDestino: boolean = false;

  ngOnInit() {
    console.log(this._data, this._data.hasOwnProperty('isAgro'), !this._data.hasOwnProperty('isAgro'), !!this._data.hasOwnProperty('isAgro'));
    if(typeof(this._data.id) == "object"){
      this._data.id = this._data.id.length ? this._data.id[0] : 0;
    }

    this.movimientoId = parseInt(this._data.id);

    if (this.movimientoId) {
      this.isGroup = false;
      this.isDestino = true;
      this.Busqueda();
  
      this.setup(this.printerList, this.devices);
    } else {
      this.movimientoId = -1;
    }
    this.dataSource = new MatTableDataSource();
    ////////////////////////////////////////////////////////
    if(!this._data.hasOwnProperty('isAgro')){
      //Si la variable isAgro regresa vacía llamamo los productores
      this.getProductoresByAcopiosIds('');
    }
  }

  

  async ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;

	}
  
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  busquedaProductor: string = '';
  productorDestinatario: any;
  async modelChanged(event: any){
    await this.getProductoresByAcopiosIds(event);
  }

  verSeleccionado(event: any){
    console.log('Ver selec',  this.productorDestinatario);
  }
  acopiosIds: string;
  async getProductoresByAcopiosIds(_busqueda: string){
    this.acopiosIds = sessionStorage.getItem('acopiosIds');
    console.log('Estos son los acopios', this.acopiosIds);
    let auxLoading: any;
    setTimeout(() => {
      auxLoading = this._overlay.open();
     });
    let data = {
      acopiosIds: this.acopiosIds,
      busqueda: _busqueda
    }
    this.dataService.postData<any>("Acopio/getProductoresByAcopio", sessionStorage.getItem("token"), data).subscribe(
      data => {
        if(data['messageEsp'] == null || data['messageEsp'] == ''){
          //Indica que la petición fue exitosa
          this._productoresByAcopioId = data['searchListProductorByAcopio'];
        }
        setTimeout(() => {
            this._overlay.close(auxLoading);
        });
      },
      error => {
        setTimeout(() => {
            this._overlay.close(auxLoading);
        },1);
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
      }
    );
  }
  ////////////////////////////////////////////////////// OLD CODE DATA ////////////////////////////////////////////////////
  openDialogScan(): void {
    this.codigoQRInput = '';
    let data: any = { tipoRecepcion: 0, envioModal: true };
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      data.isAgro = true;
    }
    let _dialogRefqr =this.dialog.open(ScanQrComponent,{
      width: 'auto',
      height: 'auto', 
      data: data
    });
    _dialogRefqr.afterClosed().subscribe(res=>{
      if(res != "" && res != undefined) {
        this.codigoQR = res;
        this.codigoQRInput = res;
        this.buscarOperacionPorQr();
      }
    });
  }


  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = this.movimientoId;
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        if(this._data.hasOwnProperty("isAgro")) {
          if(data.movimientosDataGeneralRecepList.isAgro == false) {
            this._overlay.close(this.overlayRef);
            this.snack.open('El QR ingresado pertenece a Agro','Aceptar', { duration: 10000});
            this.movimientoId = -1;
            this._data.id = 0;
            this.responseData = new SearchDataMovimientoGeneralResponse();
            this.responseData2 = new SearchDataMovimientoGeneralProdResponse();
            return;
          }
        } else {
          if(data.movimientosDataGeneralRecepList.isAgro == true) {
            this._overlay.close(this.overlayRef);
            this.snack.open('El QR ingresado no pertenece a Agro','Aceptar', { duration: 10000});
            this.movimientoId = -1;
            this._data.id = 0;
            this.responseData = new SearchDataMovimientoGeneralResponse();
            this.responseData2 = new SearchDataMovimientoGeneralProdResponse();
            return;
          }
        }
        this.responseData = data;
        this.referenciaInterna = this.responseData.movimientosDataGeneralRecepList.referenciaInterna;
        this.referenciaExterna = this.responseData.movimientosDataGeneralRecepList.referenciaExterna;
        this.responseData.movimientosDataGeneralRecepList.numeroCajas = 0;
        this.codigoQR = this.responseData.movimientosDataGeneralRecepList.codigoQR;
        this.isGroup = this.responseData.movimientosDataGeneralRecepList.codigoQR.includes("Agrupacion");
        this.Busqueda2();
      },
      error => {
        this._overlay.close(this.overlayRef);
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda2() {
    //Obtener valor de rango en formato Json
    let objJson = JSON.parse('{ "return":'+this.codigoQR.toString()+' }');
    let inicial = objJson.return.I;
    let final = objJson.return.F;
    let tipo = objJson.return.T;
    this.codigoI = objJson.return.I;
    this.codigoF = objJson.return.F;

    if(parseInt(inicial, 16) && parseInt(final, 16)){
      let codigoIDecimal = parseInt(inicial, 16);
      let codigoFDecimal = parseInt(final, 16);

      this.codigoIHEXA = parseInt(codigoIDecimal.toString().slice(6));
      this.codigoFHEXA = parseInt(codigoFDecimal.toString().slice(6));
    }else{
      this.codigoIHEXA =0;
      this.codigoFHEXA =0;
    }
    
    var request = new SearchDataMovimientoGeneralProdRequest();
    request.movimientoId = this.movimientoId;
    request.codigoI = inicial;
    request.codigoF = final;
    request.codigoTipo = tipo;
    request.totalProductosQR = objJson.return.P;
    request.codigoIHEXA = this.codigoIHEXA.toString();
    request.codigoFHEXA = this.codigoFHEXA.toString();
    if(this.codigoIHEXA != 0 && this.codigoFHEXA != 0)
    {
      request.isHexa = true;
    }else{
      request.isHexa = false;
    }

    this.dataService.postData<SearchDataMovimientoGeneralProdResponse>("Movimientos/searchDataMovimientoGeneralProducto", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        this.responseData2 = data;
        if(this.responseData2.movimientosDataGeneralProdRecepList.length == 0) {
          this.movimientoId = -1;
          this._data.id = 0;
          this.snack.open('Producto no encontrado','Aceptar');
          return;
        } else {
          if(this._data.id == 0 && !this.isGroup) {
            let modalWidth = "auto";
            
            if (screen.width > 768 && screen.width <= 992) {
              modalWidth = "25%";
            }
            if (screen.width > 992 && screen.width <= 1200) {
              modalWidth = "25%";
            }
            if (screen.width > 1200){
              modalWidth = "25%";
            }
            let _dialogMsg =this.dialog.open(DialogoGenericmsgComponent,{
              height: 'auto',
              data: { mensaje: "¿Desea agregarlo como agrupación?"}
            });   
            
            _dialogMsg.afterClosed().subscribe(res=>{
              if(res) {
                this.isGroup = true;
              }
              if(this._data.id == 0 && this.isGroup) {
                if(this.productosAgrupacion.filter(x => x.codigoQR == this.codigoQR).length == 0) {
                  this.productosAgrupacion.push({codigoQR: this.codigoQR, codigoI: request.codigoI, codigoF: request.codigoF, cantidad: objJson.return.P, productoNombre: data.movimientosDataGeneralProdRecepList[0].producto, familiaProductoId: this.responseData2.movimientosDataGeneralProdRecepList[0].familiaProductoId, embalajeId: 0});
                }
                this.responseData.movimientosDataGeneralRecepList.numeroCajas = this.productosAgrupacion.length;
                this.responseData.movimientosDataGeneralRecepList.numeroPallet = this.productosAgrupacion.length > 1 ? 1 : 0;
              } else {
                this.productosAgruEmbalaje = [];
                this.responseData.movimientosDataGeneralRecepList.numeroCajas = 1;
                this.updateEmbalajeLst();
              }
            });
          } else {
            if(this._data.id == 0 && this.isGroup) {
              if(this.productosAgrupacion.filter(x => x.codigoQR == this.codigoQR).length == 0) {
                this.productosAgrupacion.push({codigoQR: this.codigoQR, codigoI: request.codigoI, codigoF: request.codigoF, cantidad: objJson.return.P, productoNombre: data.movimientosDataGeneralProdRecepList[0].producto, familiaProductoId: this.responseData2.movimientosDataGeneralProdRecepList[0].familiaProductoId, embalajeId: 0});
              }
              this.responseData.movimientosDataGeneralRecepList.numeroCajas = this.productosAgrupacion.length;
              this.responseData.movimientosDataGeneralRecepList.numeroPallet = this.productosAgrupacion.length > 1 ? 1 : 0;
            }
          }
          if(this._data.id != 0 && this.isGroup) {
            this.productosAgruEmbalaje = [];
            this.responseData2.movimientosDataGeneralProdRecepList.forEach(x => {
              this.responseData.movimientosDataGeneralRecepList.numeroCajas += x.numeroCajas;
            });
            this.snack.open('No se permiten agrupar cajas con un envío','Aceptar', { duration: 10000});
          }
          if(this._data.id != 0 && !this.isGroup) {
            this.productosAgruEmbalaje = [];
            this.responseData2.movimientosDataGeneralProdRecepList.forEach(x => {
              this.responseData.movimientosDataGeneralRecepList.numeroCajas += x.numeroCajas;
            });
          }
          this.updateEmbalajeLst();
        }
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda2");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  buscarOperacionPorQr():void {
    let request:any = {};
    let txtPallet = false;
    if(this.codigoQRInput.trim() == "") {
      this.snack.open('Ingrese o escaneé un código QR válido','Aceptar', { duration: 10000});
      return;
    }
    this.codigoQRInput = decodeURIComponent(this.codigoQRInput);
    let result = "";
    for(let x=0;x<this.codigoQRInput.length; x++) {
      result += this.codigoQRInput[x].replace("[", '"').replace("-", "/").replace("'", "-").replace("Ñ", ":").replace("ñ",":").replace("*","}").replace("¿", "=").replace("_", "?").replace("¨", "{");
    };

    if(!this.codigoQRInput.includes("{")) {
      txtPallet = this.codigoQRInput.includes("P-");
    }
    
    this.codigoQRInput = result;

    if(!this.codigoQRInput.includes("{") && !txtPallet) {
      if(this.codigoQRInput.includes("/") || this.codigoQRInput.includes("-")) {
        this.codigoQRInput = this.codigoQRInput.replace("/", "-");
        request.codigoQR = this.codigoQRInput.includes("C-") ? this.codigoQRInput.split("-")[1].substring(1).trim() : this.codigoQRInput;
      } else {
        request.codigoQR = this.codigoQRInput.trim();
      }
      
      // Buscar por codigo de caja
      setTimeout(() => {
        this.overlayRef = this._overlay.open();
      }, .001);
      // Revisar si es agro
      if(this._data.hasOwnProperty("isAgro")) {
        request.isAgro = true;
      }
      this.dataService.postData<any>("Movimientos/searchMovimientoByCode", sessionStorage.getItem("token"), request).subscribe(
        data => {
          this._overlay.close(this.overlayRef);
          this.codigoQRInput = data.codigoQR;
          if(this.codigoQRInput == undefined || this.codigoQRInput == '') {
            this.snack.open('No se encontraron resultados','Aceptar', { duration: 10000});
          } else {
            this.buscarOperacionPorQr();
          }
        },
        error => {
          this.snack.open('Error al consultar el código','Aceptar');
          console.log(error);
          this._overlay.close(this.overlayRef);
        }
      );
    } else {
      let objJson:any = {};
      if(txtPallet) {
        this.codigoQRInput = this.codigoQRInput.replace("/", "-");
        request.codigoI = this.codigoQRInput.split("-")[1].substring(1).trim();
        request.codigoF = "";
        this.codigoQRInput = '';
      } else {
        this.codigoQR = this.codigoQRInput.trim();
        this.codigoQRInput = '';
        this.codigoQR = this.codigoQR.includes("qr=") ? this.codigoQR.split("qr=")[1] : this.codigoQR;
        if(this.codigoQR.includes('/')) {
          result = "";
          for(let x=0;x<this.codigoQR.length; x++) {
            result += this.codigoQR[x].replace('/', '-');
          };
          this.codigoQR = result;
        }
        objJson = JSON.parse(this.codigoQR.toString());
        request.codigoQR = this.codigoQR;
        request.codigoI = objJson.I;
        request.codigoF = objJson.F;
      }

      // Revisar si es agro
      if(this._data.hasOwnProperty("isAgro")) {
        request.isAgro = true;
      }
      this.isGroup = this.responseData.movimientosDataGeneralRecepList.codigoQR.includes("Agrupacion") ? true : this.isGroup;
  
      if(objJson.T == "P" || txtPallet) {
        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        request.nPallet = txtPallet ? 0 : objJson.ID.split("PL-")[1].split("-")[0];
        this.dataService.postData<any>("Movimientos/searchDataMovimientoGeneralProdsPallet", sessionStorage.getItem("token"), request).subscribe(
          data => {
            this._overlay.close(this.overlayRef);
            if(data.cajasPalletList.length == 0) {
              this.snack.open('El pallet no cuenta con cajas disponibles para enviar','Aceptar', { duration: 10000});
              if(!this.isGroup) {
                this.movimientoId = -1;
                this._data.id = 0;
                this.responseData = new SearchDataMovimientoGeneralResponse();
                this.responseData2 = new SearchDataMovimientoGeneralProdResponse();
              }
              return;
            }
            if(data.cajasPalletList.filter(x => x.exists == 0).length == 0) {
              this.snack.open('El pallet no cuenta con cajas disponibles para enviar','Aceptar', { duration: 10000});
              if(!this.isGroup) {
                this.movimientoId = -1;
                this._data.id = 0;
                this.responseData = new SearchDataMovimientoGeneralResponse();
                this.responseData2 = new SearchDataMovimientoGeneralProdResponse();
              }
              return;  
            } else {
              let disponiblesLst = data.cajasPalletList.filter(x => x.exists == 0);
              if(this.isGroup) {
                // obtener disponibles
                disponiblesLst.forEach(item => {
                  if(this.productosAgrupacion.filter(x => x.codigoQR == item.codigoQR).length == 0) {
                    this.productosAgrupacion.push({codigoQR: item.codigoQR, codigoI: item.codigoI, codigoF: item.codigoF, cantidad: item.cantidad, productoNombre: item.productoNombre, familiaProductoId: item.familiaProductoId, embalajeId: 0});
                  }
                });
                this.responseData.movimientosDataGeneralRecepList.numeroCajas = this.productosAgrupacion.length;
                this.responseData.movimientosDataGeneralRecepList.numeroPallet = this.productosAgrupacion.length > 1 ? 1 : 0;
              }
              setTimeout(() => {
                this.overlayRef = this._overlay.open();
              }, .001);
              // buscar la ultima caja para mostrar la info de la operacion obtenida del pallet
              this.codigoQR = disponiblesLst[disponiblesLst.length - 1].codigoQR;
              request.codigoQR = disponiblesLst[disponiblesLst.length - 1].codigoQR;
              request.codigoI = disponiblesLst[disponiblesLst.length - 1].codigoI;
              request.codigoF = disponiblesLst[disponiblesLst.length - 1].codigoF;
              this.findProductosMov(request);
            }
          },
          error => {
            this.snack.open('Error al consultar el pallet','Aceptar');
            console.log(error);
            this._overlay.close(this.overlayRef);
          }
        );
      } else {
        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        this.findProductosMov(request);
      }
    }
  }
  findProductosMov(request = {}) {
    let objJson = JSON.parse(this.codigoQR.toString());
    this.dataService.postData<any>("Movimientos/searchDataMovimientoGeneralRecep2", sessionStorage.getItem("token"), request).subscribe(
      data => {
        if(data.movimientosDataGeneralRecepList.length > 0) {
          this.responseData.movimientosDataGeneralRecepList = data.movimientosDataGeneralRecepList[0];
          this.responseData.movimientosDataGeneralRecepList.numeroCajas = 0;
          if(data.movimientosDataGeneralRecepList.movimientoId != 0 || data.movimientosDataGeneralRecepList.movimientoId != undefined){
            this.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId;
            this._data.id = this.movimientoId;
            if(objJson.T == "A" && this.movimientoId == 0) {
              this.movimientoId = -1;
              this._data.id = 0;
              this.responseData = new SearchDataMovimientoGeneralResponse();
              this.snack.open('Movimiento no encontrado','Aceptar', { duration: 10000});
              this._overlay.close(this.overlayRef);
            }else {
              this.Busqueda2();
            }
          } else{
            this.movimientoId = -1;
            this._data.id = 0;
            this.responseData = new SearchDataMovimientoGeneralResponse();
            this.snack.open('Movimiento no encontrado','Aceptar', { duration: 10000});
            this._overlay.close(this.overlayRef);
          }
        } else{
          this.movimientoId = -1;
          this._data.id = 0;
          this.responseData = new SearchDataMovimientoGeneralResponse();
          this.snack.open('Movimiento no encontrado','Aceptar', { duration: 10000});
          this._overlay.close(this.overlayRef);
        }
      },
      error => {
        this.snack.open('Error al consultar el movimiento','Aceptar');
        console.log(error);
        this._overlay.close(this.overlayRef);
      }
    );
  }

  chkGroup() {
    if(!this.isGroup) {
      let _dialogMsg =this.dialog.open(DialogoGenericmsgComponent,{
        width: 'auto',
        height: 'auto',
        data: { mensaje: "Si desactiva esta opción se perderán las agrupaciones escaneadas anteriormente"}
      });   
      
      _dialogMsg.afterClosed().subscribe(res=>{
        if(res) {
          this.productosAgrupacion = [];
          this.productosAgruEmbalaje = [];
          this.dataSource.data = [];
          this.isGroup = false;
          this.movimientoId = -1;
          this._data.id = 0;
          this.responseData = new SearchDataMovimientoGeneralResponse();
          this.responseData2 = new SearchDataMovimientoGeneralProdResponse();
        } else {this.isGroup = true;}
      });
    } else {
      if(this._data.id == 0 && this.responseData2.movimientosDataGeneralProdRecepList.length != 0) {
        if(this.productosAgrupacion.filter(x => x.codigoQR == this.codigoQR).length == 0) {
          let objJson = JSON.parse(this.codigoQR);
          this.productosAgrupacion.push({codigoQR: this.codigoQR, codigoI: objJson.I, codigoF: objJson.F, cantidad: objJson.P, productoNombre: this.responseData2.movimientosDataGeneralProdRecepList[0].producto, familiaProductoId: this.responseData2.movimientosDataGeneralProdRecepList[0].familiaProductoId, embalajeId: 0});
          this.responseData.movimientosDataGeneralRecepList.numeroCajas = this.productosAgrupacion.length;
          this.responseData.movimientosDataGeneralRecepList.numeroPallet = this.productosAgrupacion.length > 1 ? 1 : 0;
        }
      }
    }
    if(this._data.id != 0 && this.isGroup) {
      this.snack.open('No se permiten agrupar cajas con un envío','Aceptar', { duration: 10000});
    }
  }

  mostrarProductosAgrupacion() {
    let _dialogMsg =this.dialog.open(DialogoProductosAgrupacionComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: { productosAgrupacion: this.productosAgrupacion}
    });   
    
    _dialogMsg.afterClosed().subscribe(res=>{
      if(typeof(res) == "object"){
        this.productosAgrupacion = res.productosLst;
        this.responseData.movimientosDataGeneralRecepList.numeroCajas = this.productosAgrupacion.length;
        this.responseData.movimientosDataGeneralRecepList.numeroPallet = this.productosAgrupacion.length > 1 ? 1 : 0;
        if(!this._data.hasOwnProperty("isAgro")) {
          if(this.isGroup) {
            this.productosAgruEmbalaje.forEach(item => {
              item.cantidad = 0;
            });
            this.productosAgrupacion.forEach(agrup => {
              this.productosAgruEmbalaje.forEach(emb => {
                if(agrup.familiaProductoId == emb.familiaProductoId) {
                  emb.cantidad += agrup.cantidad;
                }
              });
            });
            this.productosAgruEmbalaje.forEach(emb => {
              emb.charolas = emb.embalajeId.hasOwnProperty("unitsPerBox") ? Math.ceil(emb.cantidad / emb.embalajeId.unitsPerBox) : 0;
            });
            this.dataSource.data = this.productosAgrupacion.length ? this.productosAgruEmbalaje : []; 
          }
        }
      }
    });
  }

  updateEmbalajeLst() {
    let upost = false;
    let request:any = {};
    let objJson = JSON.parse(this.codigoQR);

    // Revisar si es agro
    if(!this._data.hasOwnProperty("isAgro")) {
      if(this.responseData2.hasOwnProperty("movimientosDataGeneralProdRecepList")) {
        this.responseData2.movimientosDataGeneralProdRecepList.forEach(item => {
          if(this.productosAgruEmbalaje.filter(x => x.familiaProductoId == item.familiaProductoId).length == 0) {
            upost = true;
            this.productosAgruEmbalaje.push({
              producto: item.producto,
              familiaProductoId: item.familiaProductoId,
              charolas: 1,
              cantidad: !(this._data.id > 0) ? objJson.P : item.cantidad,
              embalajeId: 0,
              embalajeLst: [],
              embalajeMovId: item.embalajeId
            });
          }
        });
        
        if(this.isGroup) {
          // En caso de que sea un envío registrado en stock y sea agrupación
          if(!(this._data.id > 0)) {
            this.productosAgruEmbalaje.forEach(item => {
              item.cantidad = 0;
            });
          }
          this.productosAgrupacion.forEach(agrup => {
            this.productosAgruEmbalaje.forEach(emb => {
              if(agrup.familiaProductoId == emb.familiaProductoId) {
                emb.cantidad += agrup.cantidad;
              }
            });
          });
        }
        
        // Realizar petición para obtener los embalajes por familias capturadas para envío
        if(upost) {
          setTimeout(() => {
            this.overlayRef = this._overlay.open();
          }, .001);

          request.ids = this.productosAgruEmbalaje.map(i => i.familiaProductoId).join(",");
          this.dataService.postData<any>("Movimientos/searchPackagingByProduct", sessionStorage.getItem("token"), request).subscribe(
            data => {
              this._overlay.close(this.overlayRef);
              this.productosAgruEmbalaje.forEach(item => {
                item.embalajeLst = data.packagingLst.filter(x => x.productId == item.familiaProductoId);
                if(item.embalajeLst.length) {
                  if(item.embalajeMovId > 0 && item.embalajeLst.filter(y => y.id == item.embalajeMovId).length > 0)
                    item.embalajeId = item.embalajeLst.filter(y => y.id == item.embalajeMovId)[0]
                  else
                    item.embalajeId = item.embalajeLst[0]
                }
                item.charolas = item.embalajeId.hasOwnProperty("unitsPerBox") ? Math.ceil(item.cantidad / item.embalajeId.unitsPerBox) : 0;
              });

              this.dataSource.data = this.productosAgruEmbalaje;

              this.getInfoProducts();
            },
            error => {
              this.openSnack('Error al consultar los embalajes','Aceptar');
              this._overlay.close(this.overlayRef);
            }
          );
        } else {
          this.productosAgruEmbalaje.forEach(emb => {
            emb.charolas = emb.embalajeId.hasOwnProperty("unitsPerBox") ? Math.ceil(emb.cantidad / emb.embalajeId.unitsPerBox) : 0;
          });
          this.dataSource.data = this.productosAgruEmbalaje;
        }
      }
    }
  }

  getInfoProducts() {
    if(this._data.id > 0) {
      if(this.codigoQR.includes("Agrupacion")) {
        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        let request: any = {};
        request.id = this._data.id;
        this.dataService.postData<any>("Movimientos/ListBoxesPerFamily", sessionStorage.getItem("token"), request).subscribe(
          data => {
            this._overlay.close(this.overlayRef);
            this.detalleProductosLst = data;
          },
          error => {
            this.openSnack('Error al consultar el detalle de los productos','Aceptar');
            this._overlay.close(this.overlayRef);
          }
        );
      }
    }
  }

  upCharolasElement(elemento) {
    elemento.charolas = 0;
    if(typeof(elemento.embalajeId) == 'object') {
      elemento.charolas = elemento.embalajeId.hasOwnProperty("unitsPerBox") ? Math.ceil(elemento.cantidad / elemento.embalajeId.unitsPerBox) : 0;
    }
  }

  printEmbalaje(item, tipo) {
    console.log("tipo: ", tipo);
    console.log("item: ", item);
    let objCode:any = {};
    // Se obtiene el qr del movimiento como caja individual
    objCode = JSON.parse(this.codigoQR);

    let charolasLst:any = [];

    if(!item.embalajeId.hasOwnProperty("copiesPallet"))
      return this.openSnack("Seleccione un embalaje", "Aceptar");
      
      let _dialogoRef = this.dialog.open(DialogoImprimirComponent, {
        panelClass: "dialog-min",
        data: {Devices: this.printerList}
    });
    
    _dialogoRef.afterClosed().subscribe(res => {
      if(this._data.id > 0) {

        if(this.codigoQR.includes("Agrupacion")){
          // Obtener los qr de las cajas del movimiento agrupado
          console.log("detalleProductosLst: ", this.detalleProductosLst);
          if(this.detalleProductosLst.familyInfo.filter(i => i.productId == item.familiaProductoId).length > 0) {
            let cajaslst = this.detalleProductosLst.familyInfo.filter(i => i.productId == item.familiaProductoId)[0].boxId;
            console.log("cajaslst: ", cajaslst);
            let products: any = [];
            this.detalleProductosLst.boxesInfo.forEach(e => {
              if(cajaslst.includes(e.boxId)) {
                products.push(e);
              }
            });
            products.forEach(e => {
              objCode = JSON.parse(e.codeQR);
              charolasLst.push({
                qrCode: enviroments.qrUrl + "%7B%22T%22:%22" + objCode.T + "%22,%22P%22:" + objCode.P + ",%22I%22:%22" + objCode.I + "%22,%22F%22:%22" + objCode.F + "%22,%22ID%22:%22" + objCode.ID + "%22%7D",
                lote: item.embalajeId.prefix + "-" + item.embalajeId.consecutiveLote
              });
            });
          } else {
            this.openSnack('El producto seleccionado no se encuentra en el movimiento','Aceptar');
            return;
          }
        } else {
          charolasLst.push({
            qrCode: enviroments.qrUrl + "%7B%22T%22:%22" + objCode.T + "%22,%22P%22:" + objCode.P + ",%22I%22:%22" + objCode.I + "%22,%22F%22:%22" + objCode.F + "%22,%22ID%22:%22" + objCode.ID + "%22%7D",
            lote: item.embalajeId.prefix + "-" + item.embalajeId.consecutiveLote
          });
        }
        
        console.log("charolasLst: ", charolasLst);

        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        let request: any = {};
        request.productId = item.familiaProductoId;
        request.id = this._data.id;
        //request.consecutiveBox = tipo == 1 ? item.embalajeId.copiesPallet : item.embalajeId.copiesBox;
        request.consecutiveBox = charolasLst.length;
        this.dataService.postData<any>("Movimientos/saveConsecutiveBoxProduct", sessionStorage.getItem("token"), request).subscribe(
          data => {
            this._overlay.close(this.overlayRef);
            console.log("Consecutivos inicio y fin:", data);
            let index = 0;
            for(let x = data.consecutiveStart; x < data.consecutiveEnd; x++) {
              charolasLst[index].lote = charolasLst[index].lote + "-" + (x + 1);
              index++;
            }
            console.log("result charolas: ", charolasLst);
            this.sendPrintData(item, tipo, res, charolasLst);
          },
          error => {
            this.openSnack('Error al consultar los embalajes','Aceptar');
            this._overlay.close(this.overlayRef);
          }
        );
      } else {
        if(this.isGroup) {
          // consultar las cajas agrupadas
          console.log("agrupa sin guardar productosAgrupacion: ", this.productosAgrupacion);
          if(this.productosAgrupacion.filter(i => i.familiaProductoId == item.familiaProductoId).length > 0) {  
            let products: any = [];
            products = this.productosAgrupacion.filter(i => i.familiaProductoId == item.familiaProductoId);
            products.forEach((e, index) => {
              objCode = JSON.parse(e.codigoQR);
              charolasLst.push({
                qrCode: enviroments.qrUrl + "%7B%22T%22:%22" + objCode.T + "%22,%22P%22:" + objCode.P + ",%22I%22:%22" + objCode.I + "%22,%22F%22:%22" + objCode.F + "%22,%22ID%22:%22" + objCode.ID + "%22%7D",
                lote: item.embalajeId.prefix + "-" + item.embalajeId.consecutiveLote + "-" + (index + 1)
              });
            });
          } else {
            this.openSnack('El producto seleccionado no se encuentra en el movimiento','Aceptar');
            return;
          }
        } else {
          // validar el codigo qr ingresado
          charolasLst.push({
            qrCode: enviroments.qrUrl + "%7B%22T%22:%22" + objCode.T + "%22,%22P%22:" + objCode.P + ",%22I%22:%22" + objCode.I + "%22,%22F%22:%22" + objCode.F + "%22,%22ID%22:%22" + objCode.ID + "%22%7D",
            lote: item.embalajeId.prefix + "-" + item.embalajeId.consecutiveLote + "-1"
          });
        }

        // Imprimir etiquetas
        this.sendPrintData(item, tipo, res, charolasLst);
      }
    });
  }

  sendPrintData(item, tipo, res, charolasLst) {
    charolasLst.forEach(x => {
      x.labelZPL = this.printLabelEmbalaje(x.lote, x.qrCode, res.x, res.y);
    });
    //let labelZPL = this.printLabelEmbalaje("company", "productName", res.x, res.y);
    console.log("with printer string: ", charolasLst);
    
    if(res.printerId == 0){
      this.openSnack("No se realizará la impresión de la etiqueta, seleccionar una impresora", "Aceptar");
      // return;
    }else{ // Imprimir en impresora
      this.onDeviceSelected(res.printerId);
      //console.log("There will be " + copies + " copies.");
      let copies = tipo == 1 ? item.embalajeId.copiesPallet : item.embalajeId.copiesBox;
      copies = !(copies > 0) ? 1 : copies;  
      for(var i = 0; i < copies; i++) {
        charolasLst.forEach(x => {
          this.writeToSelectedPrinter(x.labelZPL);
        });
      }
    }

    //Preview PDF
    charolasLst.forEach(x => {
      this.showTmpPdfLabel(x.labelZPL, 1);
    });
  }

  openDialogInfoRemitente(): void {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataRem:any = {
      id: this.movimientoId > 0 ? this.movimientoId : 0
    }

    if(this.domicilioRem.hasOwnProperty("ranchoR")) {
      dataRem.domicilioRem = this.domicilioRem;
    }
    console.log('esta es el dataRem', dataRem)
    const dialogRef =this.dialog.open(DialogoRemitenteComponent,{
      disableClose: true,
      data: dataRem
    }); 
    dialogRef.afterClosed().subscribe(res=>{
      if(typeof(res) == "object"){
        if(res.domicilioRem.paisId == 241 && 
          res.domicilioRem.estadoId == 36 &&
          res.domicilioRem.ciudadR == "" &&
          res.domicilioRem.cpR == 0 &&
          res.domicilioRem.domicilioR == "" &&
          res.domicilioRem.nombreRemitente == "" &&
          res.domicilioRem.apellidoRemitente == "" &&
          res.domicilioRem.nombreCompaniaR == "" &&
          res.domicilioRem.rzCompaniaR == "" &&
          res.domicilioRem.telefonoR == "" &&
          res.domicilioRem.ranchoR == "" &&
          res.domicilioRem.sectorR == "") {
          // Sin domicilio
          this.domicilioRem = {};
        } else {
          // Con domicilio precargado
          this.domicilioRem = res.domicilioRem;
        }
      }
    });
  }

  openDialogInfoDestinatario(productorDestinatario: any) {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataDest:any = {
      productorDestinatario: productorDestinatario,
      referenciaInterna: this.referenciaInterna,
      referenciaExterna: this.referenciaExterna
    }
    const dialogRef =this.dialog.open(DialogoDestinatarioComponent,{
      height: modalHeight,
      disableClose: true,
      data: dataDest
    }); 
  }

  openDialogInfoTransportista(): void {
    let modalWidth = "auto";
    
    if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
		}
		if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataTrans:any = {
      id: this.movimientoId > 0 ? this.movimientoId : 0
    }

    if(this.infoTransportista.hasOwnProperty("transportista")) {
      dataTrans.infoTransportista = this.infoTransportista;
    }

    const dialogRef =this.dialog.open(DialogoInfoTransporteComponent,{
      disableClose: true,
      data: dataTrans
    });  
    dialogRef.afterClosed().subscribe(res=>{
      if(typeof(res) == "object"){
        if(res.infoTransportista.transportista == "" &&
          res.infoTransportista.numReferencia == "") {
          // Sin info
          this.infoTransportista = {};
        } else {
          // Con info precargado
          this.infoTransportista = res.infoTransportista;
        }
      }
    });
  }

  //#region Métodos (click)

  openDialogInfoLegal(): void {
    const dialogRef =this.dialog.open(DialogoLegalComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
          id: this.movimientoId
      }
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Datos de movimiento encontrados');
      }
      else{
        console.log('Error');
      }
    });
  }

  openDialogObservaciones(): void {
    let modalWidth = "auto";
    let modalHeight = "auto";
    
    if ( screen.width <= 576) {
      modalWidth = "90%";
    }
    if (screen.width > 576 && screen.width <= 768) {
      modalWidth = "80%";
		}
		if (screen.width > 768 && screen.width <= 992) {
      modalWidth = "50%";
    }
    if (screen.width > 992 && screen.width <= 1200) {
      modalWidth = "50%";
    }
		if (screen.width > 1200){
      modalWidth = "50%";
    }

    let dataObs:any = {
      id: this.movimientoId > 0 ? this.movimientoId : 0
    }

    if(this.infoObservaciones.hasOwnProperty("observacion")) {
      dataObs.infoObservaciones = this.infoObservaciones;
    }
    
		const dialogRef =this.dialog.open(DialogoObservacionesComponent,{
      disableClose: true,
      data: dataObs
    });    
    dialogRef.afterClosed().subscribe(res=>{
      if(typeof(res) == "object"){
        if(res.infoObservaciones.observacion == "") {
          // Sin info
          this.infoObservaciones = {};
        } else {
          // Con info precargado
          this.infoObservaciones = res.infoObservaciones;
        }
      }
    });
  }

  openDialogMermas(): void{
    if ( screen.width <= 576)
		{
			const dialogRef =this.dialog.open(DialogoMermaComponent,{
        height: 'auto',
        disableClose: true,
        data: {
            id: this.movimientoId
        }
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Datos de movimiento encontrados');
        }
        else{
          console.log('Error');
        }
      });
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this.dialog.open(DialogoMermaComponent,{
        height: 'auto',
        disableClose: true,
        data: {
            id: this.movimientoId
        }
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Datos de movimiento encontrados');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this.dialog.open(DialogoMermaComponent,{
        height: 'auto',
        disableClose: true,
        data: {
            id: this.movimientoId
        }
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Datos de movimiento encontrados');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this.dialog.open(DialogoMermaComponent,{
        height: 'auto',
        disableClose: true,
        data: {
            id: this.movimientoId
        }
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Datos de movimiento encontrados');
        }
        else{
          console.log('Error');
        }
      });
		} 
		if (screen.width > 1200){
			const dialogRef =this.dialog.open(DialogoMermaComponent,{
        height: 'auto',
        disableClose: true,
        data: {
            id: this.movimientoId
        }
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Datos de movimiento encontrados');
        }
        else{
          console.log('Error');
        }
      });
    }
  }

  /*async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Detalle Envío',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 20, 20, 20 ]);

    pdf.add(
      new Columns([
        new Table(
        [
          [ {text: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion, bold:true},
            {qr:this.responseData.movimientosDataGeneralRecepList.codigoQR, fit:75} 
          ],
          [ ' ','' ],
          [{text:'Referencia interna: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaInterna],
          [{text:'Referencia externa: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaExterna]
        ]).widths(['auto', 'auto']).alignment('center').layout('noBorders').background('red').end,
        new Table(
          [
            [ {text:'Destinatario', bold:true},
              {text:'Información de Transporte', bold:true}, 
              {text:'Remitente', bold:true} 
            ],
            [ {text:'Compañia / Razón Social: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, marginLeft:5, fontSize:'10'},
              {text:'Núm de Referencia: \n '+ this.responseDataTrans.movimientosDataTransportistaList.numReferencia, marginLeft:5, fontSize:'10'},
              {text:'Compañia / Razón Social: \n '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, marginLeft:5, fontSize:'10'}
            ],
            [ {text:'Dirección: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD, marginLeft:5, fontSize:'10'}, 
              {text:'Comp. Transportista: \n '+this.responseDataTrans.movimientosDataTransportistaList.transportista, marginLeft:5, fontSize:'10'},
              {text:'Contacto: \n '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreRemitente+' '+this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, marginLeft:5, fontSize:'10'}
            ],
            [ {text:'Contacto: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, marginLeft:5, fontSize:'10'}, 
              {text:'Fecha de Embarque: \n '+this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, marginLeft:5, fontSize:'10'},
              {text:' ', marginLeft:5, fontSize:'10'}
            ]
          ]).widths(['auto', 'auto','auto']).margin([-130,0,20,0]).end,
        await new Img('../../assets/img/logofondo3.png').width('100').build()
      ]).end
      
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Table([
        [ {text:'Origen de la Mercancía', bold:true},
          {text:'Información Legal', bold:true}
        ],
        [ {text:'Origen: '+this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR+', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR+', C.P. '+
            this.responseDataRem.movimientosDataRemitenteRecepList.cpR, marginLeft:5, fontSize:'10'},
          {text:'Importador: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.nombreInfo, marginLeft:5, fontSize:'10'}
        ],
        [ {text:'Nombre de compañia: '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR, marginLeft:5, fontSize:'10'}, 
          {text:'Dirección: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.direccionInfo, marginLeft:5, fontSize:'10'}
        ],
        [ {text:' ', marginLeft:5, fontSize:'10'}, 
          {text:'Contacto: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.contactoInfo, marginLeft:5, fontSize:'10'}
        ]
      ]).widths(['auto','auto']).end
    );

    pdf.add(
      pdf.ln(1)
    );
  //Cabeceras
    pdf.add(
      new Table([
        [{text:'Consolidado de Productos',bold:true,alignment:'left', colSpan:8}, '','','','','','',''],
        [{text:' ', colSpan:8}, '','','','','','',''],
        [ {text:'Producto', bold:true}, 
          {text:'Pallets', bold:true}, 
          {text:'Cajas', bold:true}, 
          {text:'Unidades', bold:true}, 
          {text:'Peso Bruto', bold:true}, 
          {text:'Dimensiones', bold:true}, 
          {text:'Caducidad Min.', bold:true}, 
          {text:'Lote', bold:true}
        ]
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );
  //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          [''+products.producto, ''+products.numPallet, ''+products.numCajas, ''+products.cantidad, ''+products.pesoBruto+' Kgs', ''+products.dimensiones, ''+products.fechaCaducidad, ''+products.lote],
        ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
      );
    }
  //Totales
    pdf.add(
      new Table([
        [ ' ', '', '', '', '', '', '', ''],
        [ {text:'Total', bold:true}, '', '', '', '', '', '', ''],
        [ ''+this.responseDocsTotalProd.docDetalleTotalProdList.totalProducto+' productos', ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet+' pallets', ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas+' cajas', 
          ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad+' unidades', ''+this.responseDocsTotalPeso.docDetalleTotalPesoList.totalPeso+' Kgs', '---', ''+this.responseDocsFechaMin.docDetalleFechaMinList.fechaMin, ''],
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );


    pdf.add(
      pdf.ln(1)
    );

    

    pdf.add(
      pdf.ln(22)
    );
    
    pdf.add(
      new Table(
      [
        [ {text: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion, bold:true, rowSpan:2},
          '', 
          {text:'Referencia interna: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaInterna, 
          {text:'Referencia externa: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaExterna,  
        ],
        [' ',' ',' ',' ',' ',' '],
        [ {text:'Pallets:', marginLeft:30}, ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet, 
          {text:'Cajas:', marginLeft:60}, ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas, 
          {text:'Total de Unidades:', marginLeft:30}, ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad]
      ]).widths(['auto', 'auto', 'auto', 'auto', 'auto', 'auto']).alignment('center').layout('noBorders').margin([150,0,150,0]).end
    );
    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Table([
        [ {text:' ', bold:true}, 
          {text:'Familia', bold:true}, 
          {text:'CIU', bold:true}, 
          {text:'Lote', bold:true}, 
          {text:'Caducidad', bold:true}, 
          {text:'Número de Serie', bold:true} 
        ]
      ]).widths([30, 100, 100, 100, 100, 100]).alignment('center').fontSize(11).margin([100,0,150,0]).end
    );
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 100, 100, 100, 100, 100]).alignment('center').fontSize(11).margin([100,0,150,0]).end
      );
    }
    pdf.create().open();
  }*/

  buscarInfoEtiqueta(res) {
    let request:any = {};
    request.movimientoId = this.movimientoId;
    let codeMov = JSON.parse(this.responseData.movimientosDataGeneralRecepList.codigoQR);

    this.dataService.postData<any>("Movimientos/searchDataMovimientoInfoEtiqueta", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        let fechaOpera:any = new Date(data.movimientosDataInfoEtiquetaList.fecha);
        fechaOpera = fechaOpera.getFullYear()+"/"+(((fechaOpera.getMonth() + 1) + "").length > 1 ? fechaOpera.getMonth() + 1 : '0'+(fechaOpera.getMonth() + 1))+"/"+ ((fechaOpera.getDate()+"").length > 1 ? fechaOpera.getDate() : '0'+fechaOpera.getDate()) + ' ' + ((fechaOpera.getHours()+"").length > 1 ? fechaOpera.getHours() : '0'+fechaOpera.getHours()) + ':' + ((fechaOpera.getMinutes()+"").length > 1 ? fechaOpera.getMinutes() : '0'+fechaOpera.getMinutes());

        let labelZPL = this.printLabel(1, 
          codeMov.T, 
          codeMov.P, 
          codeMov.I, codeMov.F, 
          codeMov.ID, 
          data.movimientosDataInfoEtiquetaList.nombreComp,/*"company",*/ 
          data.movimientosDataInfoEtiquetaList.nombreProducto,/*"productName", */
          fechaOpera,/*"packagin", */
          "",/*"ranges", */
          "", "", "", "", 
          data.movimientosDataInfoEtiquetaList.cajaId.split(',')[0],/*"etiquetaId", */
          "------"/*"instructions"*/, res.x, res.y);

        if(res.printerId == 0){
          this.openSnack("No se realizará la impresión de la etiqueta, seleccionar una impresora", "Aceptar");
          // return;
        }else{
          this.onDeviceSelected(res.printerId);
          //console.log(labelZPL);
          //console.log("There will be " + copies + " copies.");
          for(var i = 0; i < 1; i++)
            this.writeToSelectedPrinter(labelZPL)
        }
        this.showTmpPdfLabel(labelZPL);
      },
      error => {
        this.openSnack("Error al mandar la solicitud", "Aceptar");
        this._overlay.close(this.overlayRef);
      }
    );
  }

  showTmpPdfLabel(labelZPL: any, t = 0): void {
    this.fileData = new FormData();
    let zpl = new Blob([labelZPL], { type: "text/plain" } );
    this.fileData.append('file', zpl);

    var xhr = new XMLHttpRequest();
        xhr.open('POST', t > 0 ? enviroments.urlZebraLogistic : enviroments.urlZebra);
        xhr.setRequestHeader('Accept', 'application/pdf');
        xhr.setRequestHeader('X-Quality', 'grayscale');
        xhr.setRequestHeader('X-Linter', 'On');
        xhr.responseType = 'blob';
        xhr.send(this.fileData);
        xhr.onreadystatechange = function() { 
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              // Descarga directa
              /*var wurl = window.URL;
                var url = wurl.createObjectURL(xhr.response);
                var filename = 'label.pdf';
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                //a.style = 'display: none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                wurl.revokeObjectURL(url);*/

                // Preview en nueva ventana
                var file = new Blob([xhr.response], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            } else if (xhr.status >= 400 && xhr.status <= 599) {
                var reader = new FileReader();
                reader.onload = function(e) {
                  console.log("status error: ",reader.result);
                };
                reader.readAsText(xhr.response);
            } else if (xhr.status == 0) {
                // see note in refreshLabel() for details on when this might happen
                console.log("ERROR: Temporarily down for maintenance");
            }
        }
      };
  }

  async generateEtiquetaPDF(){
    let _dialogoRef = this.dialog.open(DialogoImprimirComponent, {
      panelClass: "dialog-min",
      data: {Devices: this.printerList}
    });

    _dialogoRef.afterClosed().subscribe(res => {
      setTimeout(() => {
        this.overlayRef = this._overlay.open();
      }, .001);
      this.buscarInfoEtiqueta(res);
    });
  }

  openDialogEnvioRegistrado(newMov = 0, qrAgru = ""): void {
    const dialogRef =this.dialog.open(DialogoEnvioRegistradoComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
          nombre: this.isGroup ? (newMov + '-Agrupación') : this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion,
          movimientoId: newMov,
          codigoQR: this.isGroup ? qrAgru : this.codigoQR,
          responseData: this.responseData
      }
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    
    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "Busqueda2":
                  this.Busqueda2();
                  break;
                case "enviar":
                  this.enviar();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "Busqueda2":
                  this.Busqueda2();
                  break;
                case "enviar":
                  this.enviar();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  enviar(){
    if(this.productosAgrupacion.length == 0 && this.isGroup && this.movimientoId <= 0) {
      this.openSnack("La agrupación no cuenta con productos a enviar", "Aceptar");
      return;
    }
    var requestUpdate:any = {};
    requestUpdate.movimientoId = this.movimientoId;
    requestUpdate.codigoCompleto = this.codigoQR;
    requestUpdate.acopioId = this.productorDestinatario.acopioId;
    requestUpdate.productorId = this.productorDestinatario.productorId;
    let objJson = JSON.parse(this.codigoQR.toString());
    requestUpdate.totalProductosQR = objJson.P;
    let cantidad = 0;
    if(this.isGroup && this.productosAgrupacion.length != 0) {
      requestUpdate.movimientoId = 0;
      let ciuI = "";
      let ciuF = "";
      this.productosAgrupacion.forEach(element => {
        ciuI = cantidad == 0 ? element.codigoI : ciuI;
        cantidad += element.cantidad;
        ciuF = element.codigoF;
      });
      requestUpdate.codigoCompleto = '{"T":"A","P":'+cantidad+',"I":"'+ciuI+'","F":"'+ciuF+'","ID":'+ (this._data.hasOwnProperty("isAgro") ? '"Agrupacion"' : '"Agrupacion-Agro"') +'}';
      requestUpdate.totalProductosQR = cantidad;
    }
    requestUpdate.codigoI = this.codigoI;
    requestUpdate.codigoF = this.codigoF;
    requestUpdate.fecha = this.fechaHoy;
    requestUpdate.numeroC = this.numeroCEnvio;
    requestUpdate.isGroup = this.isGroup ? 1 : 0;
    requestUpdate.referenciaInterna = this.referenciaInterna > 0 ? this.referenciaInterna : 0;
    requestUpdate.referenciaExterna = this.referenciaExterna > 0 ? this.referenciaExterna : 0;
    if(!this._data.hasOwnProperty("isAgro")) {
      this.productosAgrupacion.forEach(agrup => {
        this.productosAgruEmbalaje.forEach(emb => {
          if(agrup.familiaProductoId == emb.familiaProductoId) {
            agrup.embalajeId = emb.embalajeId.id;
          }
        });
      });
      if(!this.isGroup && this.productosAgruEmbalaje.length > 0) {
        requestUpdate.embalajeId = this.productosAgruEmbalaje[0].embalajeId.hasOwnProperty("id") ? this.productosAgruEmbalaje[0].embalajeId.id : 0;
      }
    }
    requestUpdate.productosAgrupacion = this.productosAgrupacion;
    if(objJson.T == "P") {
      requestUpdate.totalProductosQR = cantidad;
      this.snack.open('Favor de leer una caja o una agrupación','Aceptar', { duration: 10000});
      return;
    }

    // Registrar el domicilio Remitente
    // Registrar el Transportista
    if(this.infoTransportista.hasOwnProperty("transportista") && (this.movimientoId == -1 || this.movimientoId == 0)) {
      requestUpdate.infoUpTras = true;
      requestUpdate.infoTransportista = this.infoTransportista;
    }
    // Registrar las Observaciones
    if(this.infoObservaciones.hasOwnProperty("observacion") && (this.movimientoId == -1 || this.movimientoId == 0)) {
      requestUpdate.infoUpObs = true;
      requestUpdate.infoObservaciones = this.infoObservaciones;
    }
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      requestUpdate.isAgro = true;
    }
    
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<EditDataAgruAEnvioResponse>("Movimientos/editDataAgruAEnvio", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Envío realizado con éxito", "Aceptar");
                this.responseData.movimientosDataGeneralRecepList.referenciaInterna = this.referenciaInterna;
                this.responseData.movimientosDataGeneralRecepList.referenciaExterna = this.referenciaExterna;
                this.openDialogEnvioRegistrado(data.movimientoId, requestUpdate.codigoCompleto);
                if(sessionStorage.getItem("name") == ''){
                  this.openDialogRegistrate();
                }
                this.dialogRef.close(true);
            }
            this._overlay.close(this.overlayRef);
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("enviar");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }

  openDialogRegistrate(): void {
    const dialogRef =this.dialog.open(DialogoCreeCuentaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Mensaje de éxito');
      }
      else{
        console.log('Mensaje de error');
      }
    });
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  openVerDetalles(){

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.BusquedaDes();
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaDes() {
    
    var request = new SearchDataMovimientoDestinatarioRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoDestinatarioResponse>("Movimientos/searchDataMovimientoDestinatario", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataDes = data; 
        this.BusquedaRem();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDes");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaRem(etiqueta = 0) {
    
    var request = new SearchDataMovimientoRemitenteRequest();
    request.movimientoId = this.movimientoId;
    request.company = Number(sessionStorage.getItem("company"));
    console.log('Company', request.company);
    this.dataService.postData<SearchDataMovimientoRemitenteResponse>("Movimientos/searchDataMovimientoRemitente", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataRem = data;
        this.BusquedaTrans();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaRem");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaTrans() {
    
    var request = new SearchDataMovimientoTransportistaRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportista", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataTrans = data;
        this.BusquedaInfo();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaTrans");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaInfo() {
    
    var request = new SearchDataMovimientoInfoLegalRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoInfoLegalResponse>("Movimientos/searchDataMovimientoInfoLegal", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataInfo = data;
        this.BusquedaDocsProductos();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsProductos() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductos", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        //this.BusquedaDetalleProductos();
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  /*BusquedaDetalleProductos() { // Productos tabla Pallets
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = this.codigoQR;

    this.dataService.postData<SearchDocDetalleProductosIndiResponse>("Movimientos/searchDocDetalleProductosIndi", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosIndi = data;
        this.BusquedaDetalleProductos2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDetalleProductos2() { // Productos tabla Cajas
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = '' + this.movimientoId;

    //Obtener valor de rango en formato Json
    if(JSON.parse('{ "return":'+this.responseData.movimientosDataGeneralRecepList.codigoQR+' }')){
      let objJson = JSON.parse('{ "return":'+this.responseData.movimientosDataGeneralRecepList.codigoQR+' }')
      let inicial = objJson.return.I;
      let final = objJson.return.F;
      let tipo = objJson.return.T;

      request.codigoTipo = tipo;
      request.codigoI = inicial;
      request.codigoF = final;
    }
    

    this.dataService.postData<SearchDocDetalleProductosCajasResponse>("Movimientos/searchDocDetalleProductosCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosCajas = data;
        this.BusquedaDocsTotalProd();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalProd() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalProdResponse>("Movimientos/searchDocDetalleTotalProd", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalProd = data;
        //this.BusquedaDocsTotalPallet();
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalProd");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  /*BusquedaDocsTotalPallet() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPalletResponse>("Movimientos/searchDocDetalleTotalPallet", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPallet = data;
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPallet");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCajas() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCajasResponse>("Movimientos/searchDocDetalleTotalCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCajas = data;
        this.BusquedaDocsTotalCantidad();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCajas");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCantidad() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCantidadResponse>("Movimientos/searchDocDetalleTotalCantidad", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCantidad = data;
        this.BusquedaDocsTotalPeso();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCantidad");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPeso() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPesoResponse>("Movimientos/searchDocDetalleTotalPeso", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPeso = data;
        this.BusquedaDocsFechaMin();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPeso");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsFechaMin() {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDocDetalleFechaMinResponse>("Movimientos/searchDocDetalleFechaMin", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsFechaMin = data;
        this.BusquedaComentario();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsFechaMin");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaComentario() {
      
    var request = new SearchDataMovimientoObservacionesRequest();
    request.movimientoId = this.movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcion", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataComentario = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

   //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
   BusquedaEtiqueta() {
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = parseInt(this._servicio.getElemento());

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseEtiqueta = data;
        this.generateEtiquetaPDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();
    pdf.info({
      title: 'Guía de Embarque',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 60, 20, 30 ]);


    let tableHeader = new Table([
        [ {text:this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, bold:true, fontSize:18}
        ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;

    if(this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR == "") {
      tableHeader = new Table([
        [ new Cell( new Txt(' ').bold().fontSize(12).end).fillColor("#012152").end ]
      ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end;
    }
    pdf.header(
      new Columns([
        tableHeader,
        await new Img('../../assets/img/img-logo-stock-3.png').width('100').margin([-20,20,20,0]).link(""+enviroments.logoUrl).build()
      ]).end,
    );
    pdf.images;

    pdf.add(
      pdf.ln(1)
    );

    let textCajaId = this.responseDocsProductosCajas.docDetalleProductoCajasList.length > 0 ? this.responseDocsProductosCajas.docDetalleProductoCajasList[0].codigoCaja.split(',')[0] : '';

    pdf.add(
      new Columns([
        new Table([
          ['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],
          [ {text: 'Guía de Embarque', bold:true, alignment:'left', fontSize:16, colSpan: 3} ],
          ['','',''],['','',''], ['','',''],  ['','',''],['','',''],  ['','',''],  ['','',''],  
          [ {text:'OP Interna: ', alignment:'right', bold:true}, 
            {text: this.responseData.movimientosDataGeneralRecepList.referenciaInterna, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'No. cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.numeroC, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'OP Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseData.movimientosDataGeneralRecepList.referenciaExterna, alignment:'left', color: 'gray'},
            ''
          ]
        ]).widths([80, 150, 40]).fontSize(9).layout('noBorders').end,
        new Table([
          [ {qr: enviroments.qrUrl + this.responseData.movimientosDataGeneralRecepList.codigoQR, alignment:'center', fit:100, colSpan:2},
            ''
          ],
          [ {text: textCajaId, color: 'gray', colSpan:2}]
        ]).widths([80, 150]).alignment('center').fontSize(9).layout('noBorders').end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    // Domicilio Destinatario
    let fullDomicilioDest = this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD;
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD != '' ? this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '') 
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND != 'Seleccionar un estado' ? this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND : '');
    fullDomicilioDest += fullDomicilioDest != "" ? 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? ', '+this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '')
      : 
        (this.responseDataDes.movimientosDataDestinatarioRecepList.paisND != 'Seleccionar un pais' ? this.responseDataDes.movimientosDataDestinatarioRecepList.paisND : '');
    // Domicilio Remitente
    let fullDomicilioRem = this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR;
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? ', C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '' && this.responseDataRem.movimientosDataRemitenteRecepList.cpR != '0' ? 'C.P. '+this.responseDataRem.movimientosDataRemitenteRecepList.cpR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR != '' ? this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '') 
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR != 'Seleccionar un estado' ? this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR : '');
    fullDomicilioRem += fullDomicilioRem != "" ? 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? ', '+this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '')
      : 
        (this.responseDataRem.movimientosDataRemitenteRecepList.paisNR != 'Seleccionar un pais' ? this.responseDataRem.movimientosDataRemitenteRecepList.paisNR : '');

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Destinatario: ', alignment:'right', bold:true, fontSize:11}, 
            {text: /*this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+*/
                   this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioDest, alignment:'left', color: 'gray'}
          ],
          [ {text:'CP: ', alignment:'right', bold:true}, 
            {text: (this.responseDataDes.movimientosDataDestinatarioRecepList.cpD == "0" ? "" : this.responseDataDes.movimientosDataDestinatarioRecepList.cpD), alignment:'left', color: 'gray'}
          ],
          [ {text:'Tel: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.telefonoD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Remitente: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: fullDomicilioRem, alignment:'left', color: 'gray'} 
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {
              text: this.responseDataRem.movimientosDataRemitenteRecepList.atendioUsuario, alignment:'left', color: 'gray'
            }
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Transporte: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.transportista, alignment:'left', color: 'gray'}
          ],
          [ {text:'Fecha embarque: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, alignment:'left', color: 'gray'}
          ]
          ,
          [ {text:'Referencia: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.numReferencia, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Observaciones: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.observacion, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dimensiones por caja (cms): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.dimensionesCaja, alignment:'left', color: 'gray'}
          ],
          [ {text:'Peso por caja (Kg): ', alignment:'right', bold:true}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.pesoCaja, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Resumen de Productos/Pedido: ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
          ],
          [ {text:'Producto/Familia: ', bold:true}, 
            {text: 'Marca: ', bold:true},
            {text: 'GTIN: ', bold:true},
            {text: 'Pallets: ', bold:true},
            {text: 'Cajas por pallet: ', bold:true},
            {text: 'Unidades por caja: ', bold:true},
            {text: 'Total de unidades: ', bold:true},
            {text: 'Caducidad prox: ', bold:true},
          ]
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(9).end,
      ]).end
    );

    //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      var a;
      if(products.numPallet==0){
        a=1;
      }else{
        a=products.numPallet;
      }
      pdf.add(
        new Table([
          [ {text:products.producto, alignment:'right'}, 
            {text: products.ciu, alignment:'right'}, 
            products.gtin,
            0, 
            products.numCajas/a, 
            products.cantidad/products.numCajas, 
            products.cantidad, 
            products.fechaCaducidad
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );
    }

    pdf.add(
      new Table([
        [ 
          {text:'', bold:true, colSpan:9, fontSize:11, alignment:'left',pageBreak: 'before'}, 
            '','','','','','','',''
        ]
      ]).widths([120, 40, 70, 40, 40,40,40,40,40]).alignment('center').fontSize(9). layout('noBorders').end
    );

    /*pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );
    
    if(this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Pallets', colSpan:9, bold:true}, 
          '','','','','','','',''
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let pallets of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
        pdf.add(
          new Table([
            [ {text: pallets.producto, bold:true}, 
            {text: pallets.ciu, bold:true}, 
            {text: pallets.gtin, bold:true}, 
            {text: pallets.tipoEmpaque, bold:true}, 
            {text: pallets.cantidad, bold:true}, 
            {text: pallets.codigoPallet, bold:true} ,
            {text: ' ', bold:true} ,
            {text: pallets.lote, bold:true} ,
            {text: pallets.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
        );
      }
    }*/

    pdf.add(
      pdf.ln(2)
    );
    
    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          //{text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,80,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Cajas', bold:true}
        ]
      ]).widths(['*']).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let cajas of this.responseDocsProductosCajas.docDetalleProductoCajasList) {
        pdf.add(
          new Table([
            [ {text: cajas.producto, bold:true}, 
            {text: cajas.ciu, bold:true}, 
            {text: cajas.gtin, bold:true}, 
            {text: cajas.tipoEmpaque, bold:true}, 
            {text: cajas.cantidad/cajas.numCajas, bold:true}, 
            //{text: cajas.codigoPallet, bold:true} ,
            {text: cajas.codigoCaja.includes("X") ? cajas.codigoCaja.split("X").join("") : cajas.codigoCaja, bold:true} ,
            {text: cajas.lote, bold:true} ,
            {text: cajas.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,80,40,50]).alignment('center').fontSize(8).end
        );
      }
    }

    /*for (let products of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 120, 80, 80, 80, 80]).alignment('center').fontSize(9).end
      );
    }*/


    pdf.footer(
      new Columns([
        new Table(
        [
          [ 
            {text:'Controla tus inventarios y automatiza las comunicaciones de tu almacen con Traceit Stock   https://stock.traceit.net' }]
        ]).widths(['*']).alignment('center').layout('noBorders').fontSize(7).end
      ]).end


    );

    if ( screen.width <= 576)
		{
			pdf.create().download();
		}
		if (screen.width > 576 && screen.width <= 768)
		{
      pdf.create().download();
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			pdf.create().open();
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
      pdf.create().open();
      //document.getElementById("frame1").src = pdf.create().getDataUrl();
		} 
		if (screen.width > 1200){
			pdf.create().open();
    }
  }

  //Obtener las impresoras zebra agregadas
  setup(list, listdevices)
  {
    //Get the default device from the application as a first step. Discovery takes longer to complete.
    BrowserPrint.getDefaultDevice("printer", function(device)
        {       
          //Discover any other devices available to the application
          listdevices.push(device)
          BrowserPrint.getLocalDevices(function(device_list){
            for(var i = 0; i < device_list.length; i++)
            {
              listdevices.push(device_list[i]);
              list.push({name: device_list[i].name, uid: device_list[i].uid})
            }
          }, function(){console.log("Error getting local devices")},"printer");
          
        }, function(error){
          console.log("Error getting local devices: ", error);
        })
  }

  onDeviceSelected(selected) {
    for(var i = 0; i < this.devices.length; ++i){
      if(selected == this.devices[i].uid)
      {
        this.selected_device = this.devices[i];
        return;
      }
    }
  }

  printLabel(copies, operationType, operationScanned, first, last, id, superiorText, superiorText2, leftText, leftText2, rightText, rightText2, bottomText, bottomText2, etiquetaID, instrucciones, medianilx, medianily) {
    //T: C - caja, P - Pallet
    //P: Cantidad de unidades escaneadas
    //I: veryFirst
    //F: veryLast
    //Id: agrupacion - pallet - box


    /*

      Primario
       { id: 1, value: "Producto" }
       { id: 2, value: "Rangos" }
       { id: 3, value: "Datos de empaque" }
       { id: 4, value: "compañia" }
      Secundario
       { id: 1, value: "Producto" }
       { id: 2, value: "Rangos" }
       { id: 3, value: "Datos de empaque" }
       { id: 4, value: "compañia" }
      en db tengo por cada lado
      _topPrimary,
      _topSecondary,
      _rightPrimary,
      _rightSecondary,
      _bottomPrimary,
      _bottomSecondary,
      _leftPrimary,
      _leftSecondary,
     */
    //console.log("inciar construcción de etiqueta");
    let labelZPL = "^XA" +
      "^CI28"+
      "^PW4000" +
      `^LH${medianilx},${medianily}`+
      "^A1N,50,40" +
      "^FO712,59,1" +
      "^FD" + "STOCK" +
      "^FS" +
      "^A1N,20,12" +
      "^FO82,72,0" +
      "^FD" + etiquetaID +
      "^FS" +
      "^A1N,25,15" +
      "^FO82,120,0" +
      "^FD" + superiorText + //Compañia
      "^FS" +
      "^A1N,15,10" +
      "^FO82,150,0" +
      "^FD" + superiorText2 + //Nombre del producto de la familia
      "^FS" +
      "^A1N,15,10" +
      "^FO207,830,0" +
      "^FD" + superiorText2 + //Nombre del producto de la familia
      "^FS" +
      "^A1N,15,10" +
      "^FO207,1030,0" +
      "^FD" + superiorText2 + //Nombre del producto de la familia
      "^FS" +
      "^FO102,200" +
      //Data matriz del lado superior izquiedo
      "^BXN,7,200^FD"+ enviroments.qrUrl + "{\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //DM
      "^FS" +
      //QR del lado superior derecho
      "^FO482,200" +
      "^BQN,2,4,^FDQA,"+ enviroments.qrUrl + "{\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //QR
      "^FS" +
      //Datamatriz del primer hijo (Izquierda)
      "^FO32,825" +
      "^BXN,4,200^FD"+ enviroments.qrUrl + "{\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //DM
      "^FS" +
      //QR del primer hijo (Derecha)
      "^FO630,825" +
      "^BQN,2,2,^FDQA,"+ enviroments.qrUrl + "{\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //QR
      "^FS" +
      //Datamatriz del segundo hijo (Izquierda)
      "^FO32,1025" +
      "^BXN,4,200^FD"+ enviroments.qrUrl + "{\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //DM
      "^FS" +
      //QR del segundo hijo (derecha)
      "^FO632,1025" +
      "^BQN,2,2,^FDQA,"+ enviroments.qrUrl + "{\"T\":\"" + operationType + "\",\"P\":" + operationScanned + ",\"I\":\"" + first + "\",\"F\":\"" + last + "\",\"ID\":\"" + id + "\"}" + //QR
      "^FS" +
      "^A2R,20,12" +
      "^FO40,150,1" +
      //Fecha lado izquiero texto vertical (datamatrix superior)
      "^FD" + leftText +
      "^FS" +
      "^A1N,23,13" +
      "^FO732,500,1" +
      //Titulo count
      "^FDCount" +
      "^FS" +
      "^A1N,23,13" +
      "^FO82,500,0" +
      "^FDCode Ranges";

      
      /*if (operationType == "P") {
        let aRngsX = leftText2.split(';');
        first = aRngsX[0].split('-')[0];
      }*/
      leftText2 = first + " - " + last;
      let aRngs = leftText2.split(';');
    for (var x = 0; x < aRngs.length; x++)
    {
      if (aRngs[x] != "")
      {
        labelZPL += "^FS" +
        "^A1N,20,10" +
        "^FO82," + (550 + (x * 20)) + ",0" +
        "^FD" + aRngs[x] + "                      " + operationScanned +
        "^FS" +
        "^A1N,20,10" +
        "^FO202," + (850 + (x * 20)) + ",0" +
        "^FD" + aRngs[x] +
        "^FS" +
        "^A1N,20,10" +
        "^FO202," + (1050 + (x * 20)) + ",0" +
        "^FD" + aRngs[x];
      }
    }
     
    labelZPL += "^XZ";

    //console.log("labelZPL: ", labelZPL);

    return labelZPL;
  }

  printLabelEmbalaje(lote, qrCode, medianilx, medianily) {
    let labelZPL = "^XA" +
      "^CI28"+
      "^PW4000" +
      `^LH${medianilx},${medianily}`+
      "^CF0,40" +
      "^FO290,50^FD" + lote + "^FS" +
      "^FO50,100^GB700,3,3^FS" +
      "^FO310,120" +
      "^BXN,5,200^FD" + qrCode;
     
    labelZPL += "^XZ";

    return labelZPL;
  }

  writeToSelectedPrinter(dataToWrite) {
    this.selected_device.send(dataToWrite, undefined, this.errorCallback);
  }

  errorCallback = function(errorMessage) {
    console.log("Error: " + errorMessage);  
  }

  agrupar(){
    var request = new SearchDataFichaRequest();
    console.log(this.responseData.movimientosDataGeneralRecepList.codigoQR);
      request.codigo = this.responseData.movimientosDataGeneralRecepList.codigoQR;

    request.usuario = parseInt(sessionStorage.getItem("idUser"));
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);


    this.dataService.postData<SearchDataFichaResponse2>("Agrupaciones/searchDataFicha2", sessionStorage.getItem("token"), request).subscribe(
      data => {
      this.responseDataAgrupar = data;
        this.dataService.postData<SearchDataFichaResponse>("Agrupaciones/searchDataFicha", sessionStorage.getItem("token"), request).subscribe(
          data => {
            if (data.messageEsp != "") {
              this.openSnack(data.messageEsp, "Aceptar");
            } else {
              this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
              this._router.navigate(["Agrupar"]));
            }
            this._overlay.close(this.overlayRef);
          },
          error => {
            if (error.error.hasOwnProperty("messageEsp")) {
              this.relogin("Busqueda");
            } else {
              console.log(error);
            }
            this._overlay.close(this.overlayRef);
          }
        );
      }
      ,
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
}
