import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay-carga',
  templateUrl: './overlay-carga.component.html',
  styleUrls: ['./overlay-carga.component.less']
})
export class OverlayCargaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
