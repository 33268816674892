import { TraceITListDropDown, TraceITListFichas } from "./TraceITBaseModels";

//#region Mostrar datos de una ficha
// Request and response del web method "searchDataFicha"
export class SearchDataFichaRequest{
    codigo: string;
    usuario: number;

    constructor(){
        this.codigo = "";
        this.usuario = 0;
    }
}

export class SearchDataFichaResponse {
    fichasDataList: FichaData;
    messageEng: string;
    messageEsp: string;

    constructor() {
        this.fichasDataList = new FichaData();
        this.messageEng = "";
        this.messageEsp = "";
    };
}

export class FichaData {
    idFicha : number;
	nombreFicha : string;
	numeroPallets : number;
	numeroCajas : number;
	producto : number;
	cantidad : number;
	tipoFicha : number;
	lote : number;
	fechaCaducidad : string;
    numSerie : string;
    codigo : string;

    constructor() {
        this.idFicha = 0;
        this.nombreFicha = "";
        this.numeroPallets = 0;
        this.numeroCajas = 0;
        this.producto = 0;
        this.cantidad = 0;
        this.tipoFicha = 0;
        this.lote = 0;
        this.fechaCaducidad = "";
        this.numSerie = "";
        this.codigo = "";
    }
}

export class SearchDataFichaResponse2 {
    fichasDataList: FichaData[];
    messageEng: string;
    messageEsp: string;

    constructor() {
        this.fichasDataList = [];
        this.messageEng = "";
        this.messageEsp = "";
    };
}
//#endregion

//#region  Request and response del web method "saveFichaTemp"
export class SaveFichaTempRequest{
    fichaId: number;
    tipoFicha: number;
    usuario: number;
    desconocidoId: number;

    constructor(){
        this.fichaId = 0;
        this.tipoFicha = 0;
        this.usuario = 0;
        this.desconocidoId = 0;
    }
}

export class SaveFichaTempResponse {
    messageEng: string;
    messageEsp: string;

    constructor() {
        this.messageEng = "";
        this.messageEsp = "";
    };
}
//#endregion

//#region  Request and response del web method "searchFichasSeleccionadas"
export class SearchDataFichasSeleccionadasRequest{
    usuario: number;

    constructor(){
        this.usuario = 0;
    }
}

export class SearchDataFichasSeleccionadasResponse {
    fichasSeleccionadasDataList: TraceITListFichas[];
    fichasDesconocidasDataList: TraceITListFichas[];
    fichasUnicasDataList: TraceITListFichas[];
    fichasMovimientosDataList: TraceITListFichas[];
    messageEng: string;
    messageEsp: string;

    constructor() {
        this.fichasSeleccionadasDataList = [];
        this.fichasDesconocidasDataList = [];
        this.fichasUnicasDataList = [];
        this.fichasMovimientosDataList = [];
        this.messageEng = "";
        this.messageEsp = "";
    };
}

export class FichasSeleccionadasData {
    movfichaId: number;
    idFicha: number;
    nombreFicha: string;
    numeroPallets: number;
    numeroCajas: number;
    producto: number;
    cantidad: number;
    tipoFicha: number;
    nombreTipoFicha: string;
    lote: number;
    fechaCaducidad: string;
    numSerie : string;
    usuario : number;

    constructor() {
        this.movfichaId = 0;
        this.idFicha = 0;
        this.nombreFicha = "";
        this.numeroPallets = 0;
        this.numeroCajas = 0;
        this.producto = 0;
        this.cantidad = 0;
        this.tipoFicha = 0;
        this.nombreTipoFicha = "";
        this.lote = 0;
        this.fechaCaducidad = "";
        this.numSerie = "";
        this.usuario = 0;
    }
}
//#endregion 

//#region Request en Response de metodo "deleteFichaSeleccionada" 
export class DeleteFichaSeleccionadasRequest {
    movfichaId: number;

    constructor() {
        this.movfichaId = 0;
    }
}

export class DeleteFichaSeleccionadaResponse {
    messageEng: string;
    messageEsp: string;

    constructor() {
        this.messageEng = "";
        this.messageEsp = "";
    }
}
//#endregion

//#region  Request and response del web method "saveProdDesc"
export class SaveProdDescRequest{
    nombre: string;
    cantidad: number;
    lote: string;
    fechaCaducidad : string;
    numSerie : string;
    codigo : string;
    usuario : number;

    constructor(){
        this.nombre = "";
        this.cantidad = 0;
        this.lote = "";
        this.fechaCaducidad = "";
        this.numSerie = "";
        this.codigo = "";
        this.usuario = 0;
    }
}

export class SaveProdDescResponse {
    messageEng: string;
    messageEsp: string;

    constructor() {
        this.messageEng = "";
        this.messageEsp = "";
    };
}
//#endregion