import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import { EditDataMovimientoMermaRequest, 
         EditDataMovimientoMermaResponse, 
         SearchDataMovimientoMermaRequest, 
         SearchDataMovimientoMermaResponse, 
         SearchDataMovimientoGeneralProdRecepResponse
       } from '../../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-dialogo-merma',
  templateUrl: './dialogo-merma.component.html',
  styleUrls: ['./dialogo-merma.component.css']
})
export class DialogoMermaComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<DialogoMermaComponent>,
    private _router: Router,
    private dataService: DataServices,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _overlay: OverlayService,
  ) { }

  movimientoId : string = "";
  responseData = new SearchDataMovimientoMermaResponse();
  producto:any = {};
  overlayRef: OverlayRef;
  tipoRecepcion: number = 0;
  mermaActual : number = 0;
  productoActual : string = "";
  nombreAgrupacion: string = "";

  numeroPallet: number = 0;
  numeroCajas: number = 0;
  numeroProductos: number = 0;

  numeroRemPallet: number = 0;
  numeroRemCajas: number = 0;
  numeroRemProductos: number = 0;

  stepInput: number = 0;
  

  ngOnInit() {
    this.movimientoId = this._data.id;
    this.tipoRecepcion = this._data.tipoRecepcion;
    this.nombreAgrupacion = this._data.nombreAgrupacion;
    this.producto = this._data.producto;
    
    this.numeroPallet = this._data.numeroPallet;
    this.numeroCajas = this._data.numeroCajas;
    this.numeroProductos = this._data.numeroProductos;

    //this.numeroRemPallet = this._data.numeroRemPallet;
    //this.numeroRemCajas = this._data.numeroRemCajas;
    this.numeroRemProductos = this._data.numeroRemProductos;
    console.log("_data mermas: ",this._data);

    this.stepInput = this.tipoRecepcion == 2 ? 1 : this.producto.cantidad;
    
    this.updateInfoOperation();
    //this.Busqueda();
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoMermaRequest();
    request.codigoId = this.movimientoId;
    //console.log('Movimiento:' + this.movimientoId);

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoMermaResponse>("Movimientos/searchDataMovimientoMerma", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        this.openSnack("Error al mandar la solicitud", "Aceptar");
        console.log(error);
        this._overlay.close(this.overlayRef);
      }
    );
  }

  updateInfoOperation() {
    //this.numeroRemCajas = Math.ceil(this.numeroRemProductos / (this.producto.cantidad / this.numeroCajas));
    //this.numeroRemPallet = Math.ceil(this.numeroRemCajas / (this.numeroCajas / this.numeroPallet));
    this.numeroRemCajas = Math.ceil((this.numeroRemProductos + this.numeroProductos) / (this.producto.cantidad / this.numeroCajas));
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  SaveMerma(){
    this._dialogRef.close(this.numeroRemProductos);
    /*setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    for(let cmerma = 0; cmerma < this.responseData.movimientosDataMermaList.length; cmerma++){
      var requestUpdate = new EditDataMovimientoMermaRequest();
      requestUpdate.codigoId = this.movimientoId;
      requestUpdate.merma = this.responseData.movimientosDataMermaList[cmerma].merma;
      requestUpdate.productoMovimientoId = this.responseData.movimientosDataMermaList[cmerma].productoMovimiento;

      console.log('Merma: '+  this.responseData.movimientosDataMermaList[cmerma].merma);
      console.log('Producto: '+  this.responseData.movimientosDataMermaList[cmerma].productoMovimiento);
      
      this.dataService.postData<EditDataMovimientoMermaResponse>("Movimientos/editDataMovimientoMerma", sessionStorage.getItem("token"), requestUpdate).subscribe(
          data => {
              if (data.messageEsp != "") {
                  this.openSnack(data.messageEsp, "Aceptar");
              } else {
                if(cmerma = this.responseData.movimientosDataMermaList.length)
                {
                  this.openSnack("Datos de merma guardados con éxito", "Aceptar");
                  this._dialogRef.close(true);
                }
              }
              this._overlay.close(this.overlayRef);
          },
          error => {
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              this._overlay.close(this.overlayRef);
          }
      )
    }*/
  }
  
  closeBottom() {
    this._dialogRef.close(0);
	}
}
