import { Component, OnInit, Inject, ViewChild, ViewEncapsulation, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { PdfMakeWrapper,
  Table, 
  Img, 
  Columns, } from 'pdfmake-wrapper';
import { DialogoInfoRemitenteComponent } from '../dialogo-info-remitente/dialogo-info-remitente.component';
import { DialogoInfoDestinatarioComponent } from '../dialogo-info-destinatario/dialogo-info-destinatario.component';
import { DialogoInfoLegalComponent } from '../dialogo-info-legal/dialogo-info-legal.component';
import { DialogoRecepcionRegistradaComponent } from '../dialogo-recepcion-registrada/dialogo-recepcion-registrada.component';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import {
  SearchDataMovimientoGeneralProdRecepRequest,
  SearchDataMovimientoGeneralProdRecepResponse,

  SearchDataMovimientoGeneralRecepRequest,
  SearchDataMovimientoGeneralRecepResponse,

  SearchDataMovimientoDestinatarioRecepRequest,
  SearchDataMovimientoDestinatarioRecepResponse,
  SearchDataMovimientoRemitenteRecepRequest,
  SearchDataMovimientoRemitenteRecepResponse,
  SearchDataMovimientoInfoLegalRecepRequest,
  SearchDataMovimientoInfoLegalRecepResponse,

  SearchDataMovimientoTransportistaRecepRequest,
  SearchDataMovimientoTransportistaResponse,

  SearchDocDetalleProductosRecepRequest,
  SearchDocDetalleProductosResponse,
  SearchDocDetalleTotalProdResponse,
  SearchDocDetalleTotalPalletResponse,
  SearchDocDetalleTotalCajasResponse,
  SearchDocDetalleTotalCantidadResponse,
  SearchDocDetalleTotalPesoResponse,
  SearchDocDetalleFechaMinResponse,

  EditDataAgruAEnvioRequest,
  EditDataAgruAEnvioResponse
} from '../../Interfaces/Models/MovimientosModels';
import { ScanQrComponent } from '../scan-qr/scan-qr.component';
import { ServicesComponent } from '../../Interfaces/Services/general.service';
import { DialogoRecibirBusComponent } from '../dialogo-recibir-bus/dialogo-recibir-bus.component';
import { DialogoVerDetalleProductosComponent } from '../dialogo-ver-detalle-productos/dialogo-ver-detalle-productos.component';

@Component({
  selector: 'app-dialogo-recibir',
  templateUrl: './dialogo-recibir.component.html',
  styleUrls: ['./dialogo-recibir.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DialogoRecibirComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoRecibirComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    private _servicio: ServicesComponent,
    private _dialogRef: MatDialogRef<DialogoRecibirComponent>
  ) { }

  codigoQR : string = '';
  responseData = new SearchDataMovimientoGeneralRecepResponse();
  responseData2 = new SearchDataMovimientoGeneralProdRecepResponse();
  responseDataDes = new SearchDataMovimientoDestinatarioRecepResponse();
  responseDataRem = new SearchDataMovimientoRemitenteRecepResponse();
  responseDataInfo = new SearchDataMovimientoInfoLegalRecepResponse();

  responseDataTrans = new SearchDataMovimientoTransportistaResponse();

  responseDocsProductos = new SearchDocDetalleProductosResponse();
  responseDocsTotalProd = new SearchDocDetalleTotalProdResponse();
  responseDocsTotalPallet = new SearchDocDetalleTotalPalletResponse();
  responseDocsTotalCajas = new SearchDocDetalleTotalCajasResponse();
  responseDocsTotalCantidad = new SearchDocDetalleTotalCantidadResponse();
  responseDocsTotalPeso = new SearchDocDetalleTotalPesoResponse();
  responseDocsFechaMin= new SearchDocDetalleFechaMinResponse();

  overlayRef: OverlayRef; 

  tipoRecepcion: number = 1;
  list = [{ "id": 1, "data": "Completa", "action": 1 }, { "id": 2, "data": "Parcializada", "action": 1 }];

  numeroProductos: any = { cantidad: 0 };
  numeroRemProductos: number = 0;

  ngOnInit() {
    this.codigoQR = this._data.codigoQR;
   // this.Busqueda();
    if(this.codigoQR == '')
    {
      this.codigoQR = null;
    }
    if(this.responseData.movimientosDataGeneralRecepList.referenciaInterna == 0)
    {
      this.responseData.movimientosDataGeneralRecepList.referenciaInterna = null;
    }
    if(this.responseData.movimientosDataGeneralRecepList.referenciaExterna == 0)
    {
      this.responseData.movimientosDataGeneralRecepList.referenciaExterna = null;
    }
    if(this.responseData.movimientosDataGeneralRecepList.numeroPallet == 0)
    {
      this.responseData.movimientosDataGeneralRecepList.numeroPallet = null;
    }
    if(this.responseData.movimientosDataGeneralRecepList.numeroCajas == 0)
    {
      this.responseData.movimientosDataGeneralRecepList.numeroCajas = null;
    }
    if(this.responseData.movimientosDataGeneralRecepList.cantidad == 0)
    {
      this.responseData.movimientosDataGeneralRecepList.cantidad = null;
    }
    
    if(this._data.hasOwnProperty("isAgro")){
      this.list = [{ "id": 1, "data": "Completa", "action": 1 }, { "id": 2, "data": "Parcializada", "action": 0 }];
    }
  }

  
  openDialogRecibir(): void {
    if(this.codigoQR == undefined || this.codigoQR.trim() == "") {
      this.snack.open('Ingrese o escaneé un código QR válido','Aceptar', { duration: 10000});
      return;
    }

    this.codigoQR = decodeURIComponent(this.codigoQR);
    let result = "";
    for(let x=0;x<this.codigoQR.length; x++) {
      result += this.codigoQR[x].replace("[", '"').replace("-", "/").replace("'", "-").replace("Ñ", ":").replace("ñ",":").replace("*","}").replace("¿", "=").replace("_", "?").replace("¨", "{");
    };
    this.codigoQR = result;

    if(!this.codigoQR.includes("{")) {
      let request:any = {};
      
      if(this.codigoQR.includes("/") || this.codigoQR.includes("-")) {
        this.codigoQR = this.codigoQR.replace("/", "-");
        request.codigoQR = this.codigoQR.includes("C-") ? this.codigoQR.split("-")[1].substring(1).trim() : this.codigoQR;
      } else {
        request.codigoQR = this.codigoQR.trim();
      }

      // Buscar por codigo de caja
      setTimeout(() => {
        this.overlayRef = this._overlay.open();
      }, .001);
      // Revisar si es agro
      if(this._data.hasOwnProperty("isAgro")) {
        request.isAgro = true;
      }
      request.isRecibe = true;
      this.dataService.postData<any>("Movimientos/searchMovimientoByCode", sessionStorage.getItem("token"), request).subscribe(
        data => {
          this._overlay.close(this.overlayRef);
          this.codigoQR = data.codigoQR;
          if(this.codigoQR == undefined || this.codigoQR == '') {
            this.snack.open('No se encontraron resultados','Aceptar', { duration: 10000});
          } else {
            this.openDialogRecibir();
          }
        },
        error => {
          this.snack.open('Error al consultar el código','Aceptar', { duration: 10000});
          console.log(error);
          this._overlay.close(this.overlayRef);
        }
      );
    } else {
      this.codigoQR = this.codigoQR.includes("qr=") ? this.codigoQR.split("qr=")[1] : this.codigoQR;
      if(this.codigoQR.includes('/')) {
        result = "";
        for(let x=0;x<this.codigoQR.length; x++) {
          result += this.codigoQR[x].replace('/', '-');
        };
        this.codigoQR = result;
      }
      let objJson = JSON.parse(this.codigoQR);
      if(objJson.T == "P" && ((this.tipoRecepcion == 1 || this.tipoRecepcion == 2) && !this._data.hasOwnProperty("isAgro") || this.tipoRecepcion == 2 && this._data.hasOwnProperty("isAgro"))) {
        this.snack.open('Favor de leer una caja o una agrupación','Aceptar', { duration: 10000});
        return;
      }
      if(this.tipoRecepcion == 2) {
        this.openDialogVerDetalleProductos();
      } else {
        let data: any = {
          codigoQR : this.codigoQR, 
          tipoRecepcion: this.tipoRecepcion, 
          numeroProductos: this.numeroProductos, 
          numeroRemProductos: this.numeroRemProductos,
          nProdsRestantes: 0, 
        }
        // Revisar si es agro
        if(this._data.hasOwnProperty("isAgro")) {
          data.isAgro = true;
        }
        let dialogRef2 =this.dialog.open(DialogoRecibirBusComponent,{
          height: 'auto',
          disableClose: true,
          data: data
        });   
      
        this.dialogRef.close();
      }
    }
  }

  openDialogVerDetalleProductos(): void {
    var request:any = {};
    let objJson = JSON.parse(this.codigoQR);
    request.codigoQR = this.codigoQR;
    request.codigoI = objJson.I;
    request.codigoF = objJson.F;

    if(parseInt(objJson.I, 16) && parseInt(objJson.F, 16)){
      let codigoIDecimal = parseInt(objJson.I, 16);
      let codigoFDecimal = parseInt(objJson.F, 16);

      request.codigoIHEXA  = parseInt(codigoIDecimal.toString().slice(6)).toString();
      request.codigoFHEXA = parseInt(codigoFDecimal.toString().slice(6)).toString();

    }else{
      request.codigoIHEXA = "0";
      request.codigoFHEXA = "0";
    }

    if(request.codigoIHEXA != "0" && request.codigoFHEXA != "0") {
      request.isHexa = true;
      console.log('HEXA :  TRUE');
    }else{
      request.isHexa = false;  
      console.log('HEXA :  NO TRUE');
    }

    if(request.codigoQR == "A"){
      request.codigoQR = this.codigoQR;
    }
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      request.isAgro = true;
    }

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralProdRecepResponse>("Movimientos/searchDataMovimientoGeneralProductoRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        this.responseData2 = data;
        
        if(this.responseData2.movimientosDataGeneralDesProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralOperaProdRecepList.length == 0
          && this.responseData2.movimientosDataGeneralProdRecepList.length == 0 && this.responseData2.movimientosDataGeneralUnicoProdRecepList.length == 0) {
            this.snack.open('Productos no encontrados','Aceptar');
        } else {
          let _dialogoRef = this.dialog.open(DialogoVerDetalleProductosComponent, {
            panelClass: "dialog-min",
            data: {responseData2: this.responseData2, numeroProductos: { cantidad: 0 }}
          });
          _dialogoRef.afterClosed().subscribe(res=>{
            if(res.selectedProduct.hasOwnProperty("movimientoId")) {
              this._dialogRef.close();
              this.numeroProductos = res.selectedProduct;
              this.numeroRemProductos = res.selectedProduct.cantidad;
              
              let dataRecibirBus: any = {
                codigoQR : this.codigoQR, 
                tipoRecepcion: this.tipoRecepcion, 
                numeroProductos: this.numeroProductos, 
                numeroRemProductos: this.numeroRemProductos,
                nProdsRestantes: this.numeroProductos.cantidad - (this.numeroProductos.merma + this.numeroProductos.productosRecibidos) <= 0 ? 0 : this.numeroProductos.cantidad - (this.numeroProductos.merma + this.numeroProductos.productosRecibidos), 
              }
              // Revisar si es agro
              if(this._data.hasOwnProperty("isAgro")) {
                dataRecibirBus.isAgro = true;
              }
              let _dialogRef2 =this.dialog.open(DialogoRecibirBusComponent,{
                height: 'auto',
                disableClose: true,
                data: dataRecibirBus
              });   
              
              _dialogRef2.afterClosed().subscribe(res=>{
                console.log(res);
                if(res) {
                    console.log('Listo para buscar movimientos a recibir');
                } else{
                    console.log('Error');
                }
              });
            }
          });
        }
      },
      error => {
        console.log(error);
        this._overlay.close(this.overlayRef);
      }
    );
  }


  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoGeneralRecepRequest();
    request.codigoQR = this.codigoQR;
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralRecepResponse>("Movimientos/searchDataMovimientoGeneralRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this.Busqueda2();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

   //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
   Busqueda2() {
    
    var request = new SearchDataMovimientoGeneralProdRecepRequest();
    request.codigoQR = this.codigoQR

    this.dataService.postData<SearchDataMovimientoGeneralProdRecepResponse>("Movimientos/searchDataMovimientoGeneralProductoRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData2 = data;
        this.BusquedaDes();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda2");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }


  openDialogInfoRemitente(): void {
    const dialogRef =this.dialog.open(DialogoInfoRemitenteComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
          id: this.codigoQR
      }
    }); 

    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Datos de movimiento encontrados');
      }
      else{
        console.log('Error');
      }
    });
  }

  openDialogInfoDestinatario(): void {
    const dialogRef =this.dialog.open(DialogoInfoDestinatarioComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
          id: this.codigoQR
      }
    }); 

    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Datos de movimiento encontrados');
      }
      else{
        console.log('Error');
      }
    });
  }

  openDialogScan(): void {
    let data:any = { tipoRecepcion: this.tipoRecepcion, recepEvent: this._dialogRef };
    // Revisar si es agro
    if(this._data.hasOwnProperty("isAgro")) {
      data.isAgro = true;
    }
    let dialogRef =this.dialog.open(ScanQrComponent,{
      width: 'auto',
      height: 'auto', 
      data: data
    });

    if(this.tipoRecepcion == 1) { this._dialogRef.close(); }
  }


  //#region Métodos (click)

  openDialogInfoLegal(): void {
    const dialogRef =this.dialog.open(DialogoInfoLegalComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
          id: this.codigoQR
      }
    });    

    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Datos de movimiento encontrados');
      }
      else{
        console.log('Error');
      }
    });
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Detalle Pedido',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([ 20, 20, 20, 20 ]);

    pdf.add(
      new Columns([
        new Table(
        [
          [ {text: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion, bold:true},
            {qr:this.responseData.movimientosDataGeneralRecepList.codigoQR, fit:50} 
          ],
          [ ' ','' ],
          [{text:'Referencia interna: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaInterna],
          [{text:'Referencia externa: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaExterna]
        ]).widths(['auto', 'auto']).alignment('center').layout('noBorders').end,
        new Table(
          [
            [ {text:'Destinatario', bold:true},
              {text:'Información de Transporte', bold:true}, 
              {text:'Remitente', bold:true} 
            ],
            [ {text:'Compañia / Razón Social: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, marginLeft:5, fontSize:'10'},
              {text:'Núm de Referencia: \n '+ this.responseDataTrans.movimientosDataTransportistaList.numReferencia, marginLeft:5, fontSize:'10'},
              {text:'Compañia / Razón Social: \n '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, marginLeft:5, fontSize:'10'}
            ],
            [ {text:'Dirección: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD, marginLeft:5, fontSize:'10'}, 
              {text:'Comp. Transportista: \n '+this.responseDataTrans.movimientosDataTransportistaList.transportista, marginLeft:5, fontSize:'10'},
              {text:'Contacto: \n '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreRemitente+' '+this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, marginLeft:5, fontSize:'10'}
            ],
            [ {text:'Contacto: \n '+this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, marginLeft:5, fontSize:'10'}, 
              {text:'Fecha de Embarque: \n '+this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, marginLeft:5, fontSize:'10'},
              {text:' ', marginLeft:5, fontSize:'10'}
            ]
          ]).widths(['auto', 'auto','auto']).margin([-130,0,20,0]).end,
        await new Img('../../assets/img/logofondo3.png').width('100').build()
      ]).end
      
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Table([
        [ {text:'Origen de la Mercancía', bold:true},
          {text:'Información Legal', bold:true}
        ],
        [ {text:'Origen: '+this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR+', '+this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR+', C.P. '+
            this.responseDataRem.movimientosDataRemitenteRecepList.cpR, marginLeft:5, fontSize:'10'},
          {text:'Importador: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.nombreInfo +' '+this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, marginLeft:5, fontSize:'10'}
        ],
        [ {text:'Nombre de compañia: '+this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR, marginLeft:5, fontSize:'10'}, 
          {text:'Dirección: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.direccionInfo, marginLeft:5, fontSize:'10'}
        ],
        [ {text:' ', marginLeft:5, fontSize:'10'}, 
          {text:'Contacto: '+this.responseDataInfo.movimientosDataInfoLegalRecepList.contactoInfo, marginLeft:5, fontSize:'10'}
        ]
      ]).widths(['auto','auto']).end
    );

    pdf.add(
      pdf.ln(1)
    );
  //Cabeceras
    pdf.add(
      new Table([
        [{text:'Consolidado de Productos',bold:true,alignment:'left', colSpan:8}, '','','','','','',''],
        [{text:' ', colSpan:8}, '','','','','','',''],
        [ {text:'Producto', bold:true}, 
          {text:'Pallets', bold:true}, 
          {text:'Cajas', bold:true}, 
          {text:'Unidades', bold:true}, 
          {text:'Peso Bruto', bold:true}, 
          {text:'Dimesiones', bold:true}, 
          {text:'Caducidad Min.', bold:true}, 
          {text:'Lote', bold:true}
        ]
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );
  //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          [''+products.producto, ''+products.numPallet, ''+products.numCajas, ''+products.cantidad, ''+products.pesoBruto+' Kgs', ''+products.dimensiones, ''+products.fechaCaducidad, ''+products.lote],
        ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
      );
    }
  //Totales
    pdf.add(
      new Table([
        [ ' ', '', '', '', '', '', '', ''],
        [ {text:'Total', bold:true}, '', '', '', '', '', '', ''],
        [ ''+this.responseDocsTotalProd.docDetalleTotalProdList.totalProducto+' productos', ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet+' pallets', ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas+' cajas', 
          ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad+' unidades', ''+this.responseDocsTotalPeso.docDetalleTotalPesoList.totalPeso+' Kgs', '---', ''+this.responseDocsFechaMin.docDetalleFechaMinList.fechaMin, ''],
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );


    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      pdf.ln(22)
    );
    
    pdf.add(
      new Table(
      [
        [ {text: this.responseData.movimientosDataGeneralRecepList.nombreAgrupacion, bold:true, rowSpan:2},
          '', 
          {text:'Referencia interna: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaInterna, 
          {text:'Referencia externa: ', marginLeft:30}, this.responseData.movimientosDataGeneralRecepList.referenciaExterna,  
        ],
        [' ',' ',' ',' ',' ',' '],
        [ {text:'Pallets:', marginLeft:30}, ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet, 
          {text:'Cajas:', marginLeft:60}, ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas, 
          {text:'Total de Unidades:', marginLeft:30}, ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad]
      ]).widths(['auto', 'auto', 'auto', 'auto', 'auto', 'auto']).alignment('center').layout('noBorders').margin([150,0,150,0]).end
    );
    pdf.add(
      pdf.ln(1)
    );
    pdf.add(
      new Table([
        [ {text:' ', bold:true}, 
          {text:'Familia', bold:true}, 
          {text:'CIU', bold:true}, 
          {text:'Lote', bold:true}, 
          {text:'Caducidad', bold:true}, 
          {text:'Número de Serie', bold:true} 
        ]
      ]).widths([30, 100, 100, 100, 100, 100]).alignment('center').fontSize(11).margin([100,0,150,0]).end
    );
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          ["    ",''+products.producto, ''+products.ciu,''+products.lote,''+products.fechaCaducidad, ''+products.numSerie],
        ]).widths([30, 100, 100, 100, 100, 100]).alignment('center').fontSize(11).margin([100,0,150,0]).end
      );
      pdf.create().open();
    }
  }

  openDialogRecepcionRegistrada(): void {
    const dialogRef =this.dialog.open(DialogoRecepcionRegistradaComponent,{
      width: 'auto',
      height: 'auto'
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "Busqueda2":
                  this.Busqueda2();
                  break;
                case "recibir":
                  this.recibir();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "Busqueda2":
                  this.Busqueda2();
                  break;
                case "recibir":
                  this.recibir();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  recibir(){
    var requestUpdate = new EditDataAgruAEnvioRequest();
    requestUpdate.movimientoId = this.responseData.movimientosDataGeneralRecepList.movimientoId

    this.dataService.postData<EditDataAgruAEnvioResponse>("Movimientos/editDataEnvioARecep", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Recepción realizada con éxito", "Aceptar");
                this.openDialogRecepcionRegistrada();
                this.dialogRef.close(true);
            }
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("recibir");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
        }
    )
  }

  //#endregion

//Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
BusquedaDes() {
    
  var request = new SearchDataMovimientoDestinatarioRecepRequest();
  request.codigoQR = this.codigoQR;

  this.dataService.postData<SearchDataMovimientoDestinatarioRecepResponse>("Movimientos/searchDataMovimientoDestinatarioRecep", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataDes = data; 
      this.BusquedaRem();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("BusquedaDes");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

BusquedaRem() {
  
  var request = new SearchDataMovimientoRemitenteRecepRequest();
  request.codigoQR = this.codigoQR;

  this.dataService.postData<SearchDataMovimientoRemitenteRecepResponse>("Movimientos/searchDataMovimientoRemitenteRecep", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataRem = data;
      this.BusquedaTrans();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("BusquedaRem");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

BusquedaTrans() {
  
  var request = new SearchDataMovimientoTransportistaRecepRequest();
  request.codigoQR = this.codigoQR;

  this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportistaRecep", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataTrans = data;
      this.BusquedaInfo();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("BusquedaTrans");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

//Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
BusquedaInfo() {
  
  var request = new SearchDataMovimientoInfoLegalRecepRequest();
  request.codigoQR = this.codigoQR;

  this.dataService.postData<SearchDataMovimientoInfoLegalRecepResponse>("Movimientos/searchDataMovimientoInfoLegalRecep", sessionStorage.getItem("token"), request).subscribe(
    data => {
      this.responseDataInfo = data;
      this.BusquedaDocsProductos();
    },
    error => {
      if (error.error.hasOwnProperty("messageEsp")) {
        this.relogin("Busqueda");
      } else {
        console.log(error);
      }
      this._overlay.close(this.overlayRef);
    }
  );
}

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsProductos() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductosRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        this.BusquedaDocsTotalProd();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalProd() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleTotalProdResponse>("Movimientos/searchDocDetalleTotalProdRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalProd = data;
        this.BusquedaDocsTotalPallet();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalProd");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPallet() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleTotalPalletResponse>("Movimientos/searchDocDetalleTotalPalletRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPallet = data;
        this.BusquedaDocsTotalCajas();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPallet");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCajas() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleTotalCajasResponse>("Movimientos/searchDocDetalleTotalCajasRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCajas = data;
        this.BusquedaDocsTotalCantidad();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCajas");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCantidad() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleTotalCantidadResponse>("Movimientos/searchDocDetalleTotalCantidadRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCantidad = data;
        this.BusquedaDocsTotalPeso();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCantidad");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPeso() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleTotalPesoResponse>("Movimientos/searchDocDetalleTotalPesoRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPeso = data;
        this.BusquedaDocsFechaMin();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPeso");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsFechaMin() {
    
    var request = new SearchDocDetalleProductosRecepRequest();
    request.codigoQR = this.codigoQR;

    this.dataService.postData<SearchDocDetalleFechaMinResponse>("Movimientos/searchDocDetalleFechaMinRecep", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsFechaMin = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsFechaMin");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
}
