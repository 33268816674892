import { Pipe, PipeTransform } from '@angular/core';
interface Productor {
    id: number; 
    name: string;
    numeroProductor: number;
    nombreProductor: string;
}
@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(productor: Productor[], searchTxt: any): Productor[] { 
        if(!searchTxt) return productor;
        const result = [];
        for(const prod of productor){
            if((prod.nombreProductor.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1)){
                result.push(prod);
            }else if(String(prod.numeroProductor).toLowerCase().indexOf(searchTxt.toLowerCase()) > -1){
                result.push(prod);
            }
        }
        return result;
    }
}