import { Component, OnInit, Inject, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

import { DataServices } from '../../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import {
  SearchDataMovimientoInfoLegalRequest,
  SearchDataMovimientoInfoLegalResponse,

  SearchRadioTipoInfoRequest,
  SearchRadioTipoInfoResponse,

  EditDataMovimientoInfoLegalRequest,
  EditDataMovimientoInfoLegalResponse,

  SearchDocsInfoLegalRequest,
  SearchDocsInfoLegaloResponse,
  DocsInfoLegalData,

  SaveDocsInfoLegalRequest,
  SaveDocsInfoLegalResponse
} from '../../../Interfaces/Models/MovimientosModels';
/*import { resolve } from 'dns';
import { rejects } from 'assert';
import { NgForm } from '@angular/forms';*/

//Servicio para borrar la seleccion
import { ServicesComponent } from '../../../Interfaces/Services/general.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-dialogo-legal',
  templateUrl: './dialogo-legal.component.html',
  styleUrls: ['./dialogo-legal.component.css']
})
export class DialogoLegalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoLegalComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    public _servicio : ServicesComponent
  ) { }

  company: number = 0;

  archivo = {
    nombre: null,
    nombreArchivo: null,
    base64textString: null
  }
  
  vacio : string = null;

  nombreDocumento : string;
  movimientoId : number = 0;
  importadorExportador : number = 1;
  tipoIfoLegalId = 0;
  responseData = new SearchDataMovimientoInfoLegalResponse();
  responseRadio = new SearchRadioTipoInfoResponse();
  responseDocs = new SearchDocsInfoLegaloResponse();
  overlayRef: OverlayRef;
  emptyList: boolean = false;
  file : string;
  filesLst:any = [];

  today= new Date();
  fechaIngreso = formatDate(this.today, 'yyyy-MM-dd hh:mm:ss', 'en-US', '0600');

  fileData = new FormData();
  tmpFile:any = "";

  tipoArchivo: number = 0;
  tiposArchivos: any = [
    {id: 1, name: "Certificado"},
    {id: 2, name: "Foto"},
    {id: 3, name: "Otro"}
    //{id: 4, name: "Guía de Embarque"},
  ];

  ngOnInit() {
    this.movimientoId = this._data.id;
    this.BusquedaRadios();

    this.company = sessionStorage.hasOwnProperty('company') ? parseInt(sessionStorage.getItem('company')) : 0;
  }

  //Representa la instancia para el sorting de columnas (Clasificación por)
  @ViewChild(MatSort) sort: MatSort;

  //Representa componente de paginación para la tabla
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //variables para configurar la tabla  (select es para Checkbox, columnas de la tabla)
  displayedColumns: string[] = ['docId',
                                'fechaDoc', 
                                'rutaDoc', 
                                'nombredoc', 
                                'tipoArchivoName',
                                'eliminar'
                                ];
  dataSource = new MatTableDataSource<DocsInfoLegalData>(this.responseDocs.docsInfoLegalDataList);
  selection = new SelectionModel<DocsInfoLegalData>(false, []);



  BusquedaRadios() {
    var request = new SearchRadioTipoInfoRequest();
    request.tipoInfoLegalId = this.tipoIfoLegalId;//parseInt(localStorage.getItem("company"));

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchRadioTipoInfoResponse>("Movimientos/searchTipoInfoRadioB", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseRadio = data;
        this.Busqueda();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaRadios");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    )
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoInfoLegalRequest();
    request.movimientoId = this.movimientoId;

    this.dataService.postData<SearchDataMovimientoInfoLegalResponse>("Movimientos/searchDataMovimientoInfoLegal", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this.BusquedaDocs();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaDocs() {
    
    var request = new SearchDocsInfoLegalRequest();
    request.movimientoId = this.responseData.movimientosDataInfoLegalRecepList.movimientoId;
        
    this.dataService.postData<SearchDocsInfoLegaloResponse>("Movimientos/searchDocsInfoLegal", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        this.responseDocs = data;
        if (this.responseDocs.docsInfoLegalDataList.length > 0)
          this.emptyList = false;
        else
          this.emptyList = true;
        this.responseDocs.docsInfoLegalDataList.forEach(item => {
          if(item.tipoArchivo == 0) {
            item.tipoArchivoName = "";
          } else {
            item.tipoArchivoName = this.tiposArchivos.filter(x => x.id == item.tipoArchivo).length > 0 ? this.tiposArchivos.filter(x => x.id == item.tipoArchivo)[0].name : "";
          }
        });
        this.dataSource.data = this.responseDocs.docsInfoLegalDataList;
        this.selection.clear();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocs");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaRadios":
                  this.BusquedaRadios();
                  break;
                case "SaveInfoLegal":
                  this.SaveInfoLegal();
                  break;
                case "BusquedaDocs":
                  this.BusquedaDocs();
                  break;
                case "SaveDocs":
                  this.SaveDocs();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaRadios":
                  this.BusquedaRadios();
                  break;
                case "SaveInfoLegal":
                  this.SaveInfoLegal();
                  break;
                case "BusquedaDocs":
                  this.BusquedaDocs();
                  break;
                case "SaveDocs":
                  this.SaveDocs();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  //Funcion despues de obtener todos los datos se establece el paginado
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  SaveInfoLegal(){
    var requestUpdate = new EditDataMovimientoInfoLegalRequest();
    requestUpdate.movimientoId = this.responseData.movimientosDataInfoLegalRecepList.movimientoId;
    requestUpdate.tipoInfo = this.responseData.movimientosDataInfoLegalRecepList.tipoInfo;
    requestUpdate.nombreInfo = this.responseData.movimientosDataInfoLegalRecepList.nombreInfo;
    requestUpdate.direccionInfo = this.responseData.movimientosDataInfoLegalRecepList.direccionInfo;
    requestUpdate.contactoInfo = this.responseData.movimientosDataInfoLegalRecepList.contactoInfo;
    requestUpdate.nombreInfoExp = this.responseData.movimientosDataInfoLegalRecepList.nombreInfoExp;
    requestUpdate.direccionInfoExp = this.responseData.movimientosDataInfoLegalRecepList.direccionInfoExp;
    requestUpdate.contactoInfoExp = this.responseData.movimientosDataInfoLegalRecepList.contactoInfoExp;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<EditDataMovimientoInfoLegalResponse>("Movimientos/editDataMovimientoInfoLegal", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
          this._overlay.close(this.overlayRef);
          if (data.messageEsp != "") {
              this.openSnack(data.messageEsp, "Aceptar");
          } else {
              this.openSnack("Datos de información legal guardados con éxito", "Aceptar");
          }
        },
        error => {
          if (error.error.hasOwnProperty("messageEsp")) {
              this.relogin("SaveInfoLegal");
          } else {
              this.openSnack("Error al mandar la solicitud", "Aceptar");
          }
          this._overlay.close(this.overlayRef);
        }
    )
  }

  SaveDocs() {
    if(this.company > 0 && !(this.tipoArchivo > 0)) {
      this.openSnack("Seleccione un tipo de archivo", "Aceptar");
      return;
    }

    if(this.fileData.has("tipoArchivo"))
      this.fileData.set("tipoArchivo", this.tipoArchivo.toString());
    else
      this.fileData.append("tipoArchivo", this.tipoArchivo.toString());
    
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postDataDocs<any>("Movimientos/saveDocsInfoLegal", sessionStorage.getItem("token"), this.fileData).subscribe(
        data => {
            this._overlay.close(this.overlayRef);
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
              this.openSnack("Archivo guardado con éxito", "Aceptar");
              this.tmpFile = "";
              this.tipoArchivo = 0;
              this.archivo = {
                nombre: null,
                nombreArchivo: null,
                base64textString: null
              };
              this.fileData = new FormData();
              setTimeout(() => {
                this.overlayRef = this._overlay.open();
              }, .001);
              this.BusquedaDocs();
            }
        },
        error => {
          this._overlay.close(this.overlayRef);
          if (error.error.hasOwnProperty("messageEsp")) {
              this.relogin("SaveDocs");
          } else {
              this.openSnack("Error al mandar la solicitud", "Aceptar");
          }
        }
    )
  }

  openDialogInfoLegal(): void {
    const dialogRef =this.dialog.open(DialogoLegalComponent,{
      width: 'auto',
      height: 'auto',
      disableClose: true,
      data: {
          id: this.movimientoId
      }
    });    

    dialogRef.afterClosed().subscribe(res=>{});
  }

  seleccionarArchivo(event) {
    this.fileData = new FormData();
    if (event.target.files.length > 0) {
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.docx|\.doc|\.xlsx|\.xls|\.csv|\.msg)$/i;
      var FileSize = event.target.files[0].size / 1024 / 1024;

      if(FileSize > 10) {
        this.openSnack("El archivo debe de tener un tamaño máximo 10 MB", "Aceptar");
        return;
      }
      
      let newNombre = event.target.files[0].name;
      if (!allowedExtensions.exec(newNombre.toLowerCase())) {
        this.openSnack("Solo se aceptan archivos jpg, jpeg, png, pdf, xlsx, xls, csv, docx, doc, msg", "Aceptar");
        return;
      }
      this.archivo.nombreArchivo = newNombre;
      this.archivo.nombre = newNombre;

      this.fileData = new FormData();
      this.fileData.append('fileData', event.target.files[0], this.archivo.nombre);
      this.fileData.append('fileName', this.archivo.nombre);
      this.fileData.append("doc", this.archivo.nombreArchivo);
      this.fileData.append("fecha", formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss a', 'en-US', '0500'));
      this.fileData.append("infoLegalId", this.responseData.movimientosDataInfoLegalRecepList.infoLegalId + "");
      this.fileData.append("movimientoId", this.responseData.movimientosDataInfoLegalRecepList.movimientoId + "");
      this.fileData.append("nombreDoc", this.archivo.nombre);
    }
  }

  deleteItem(item) {
    let params: any = {};
    params = {
      docId: item.docId,
      movimientoId: this.responseData.movimientosDataInfoLegalRecepList.movimientoId,
      infoLegalId: this.responseData.movimientosDataInfoLegalRecepList.infoLegalId,
      nombreDoc: item.nombredoc
    };

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postDataDownDocs<any>('Movimientos/DeleteDocsInfoLegal', sessionStorage.getItem("token"), params).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        this.openSnack("Archivo eliminado", "Aceptar");
        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        this.BusquedaDocs();
      },
      error => {
        this._overlay.close(this.overlayRef);
        this.openSnack("Error al mandar la solicitud", "Aceptar");
      }
    );
  }

  downInfoLegal = (filename) => {
    let params: any = {};
    params = {
      movimientoId: this.responseData.movimientosDataInfoLegalRecepList.movimientoId,
      nombreDoc: filename
    };

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postDataDownDocs<any>('Movimientos/DownloadDocsInfoLegal', sessionStorage.getItem("token"), params).subscribe(
      data => {
        this._overlay.close(this.overlayRef);
        let dataType = data.type;
        let binaryData = [];
        binaryData.push(data);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      error => {
        this._overlay.close(this.overlayRef);
        this.openSnack("Error al obtener el archivo", "Aceptar");
      }
    );
  }

}
