import { OverlayRef } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialogo-ver-detalle-productos',
  templateUrl: './dialogo-ver-detalle-productos.component.html',
  styleUrls: ['./dialogo-ver-detalle-productos.component.less']
})
export class DialogoVerDetalleProductosComponent implements OnInit {

  constructor(
    private _dialogRef: MatDialogRef<DialogoVerDetalleProductosComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any
  ) { }

  responseData2: any = [];
  selectedProduct: any = { cantidad: 0 };

  ngOnInit() {
    if(this._data.responseData2.movimientosDataGeneralProdRecepList.length){
      this._data.responseData2.movimientosDataGeneralProdRecepList.forEach(element => {
        this.responseData2.push(element);
      });
    }
    if(this._data.responseData2.movimientosDataGeneralDesProdRecepList.length){
      this._data.responseData2.movimientosDataGeneralDesProdRecepList.forEach(element => {
        this.responseData2.push(element);
      });
    }
    if(this._data.responseData2.movimientosDataGeneralUnicoProdRecepList.length){
      this._data.responseData2.movimientosDataGeneralUnicoProdRecepList.forEach(element => {
        this.responseData2.push(element);
      });
    }
    if(this._data.responseData2.movimientosDataGeneralOperaProdRecepList.length){
      this._data.responseData2.movimientosDataGeneralOperaProdRecepList.forEach(element => {
        this.responseData2.push(element);
      });
    }
    this.selectedProduct = this._data.numeroProductos;
    if(this.responseData2.length == 1) {
      this.selectedProduct = this.responseData2[0];
    }
  }

  accionBottom() {
		this._dialogRef.close({ selectedProduct: this.selectedProduct });
  }

  closeBottom() {
    this._dialogRef.close({ selectedProduct: this._data.numeroProductos });
	}

}
