import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

@Component({
	selector: 'app-dialogo-productos-agrupacion',
	templateUrl: './dialogo-productos-agrupacion.component.html',
	styleUrls: ['./dialogo-productos-agrupacion.component.less']
})
export class DialogoProductosAgrupacionComponent implements OnInit {
	displayedColumns: string[] = ['productoNombre','codigoQR', 'cantidad', 'delete'];
	dataSource: MatTableDataSource<any>;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		public _dialogRef: MatDialogRef<DialogoProductosAgrupacionComponent>,
		@Inject(MAT_DIALOG_DATA) public _data: any
	) { }

	ngAfterViewInit() {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	ngOnInit() {
		let productosAgrupacion = [];
		this._data.productosAgrupacion.forEach(element => {
			productosAgrupacion.push(element);
		});
		this.dataSource = new MatTableDataSource();
		this.dataSource.data = productosAgrupacion;
		// console.log("DEBUG", this.dataSource.data);
		console.log(this.dataSource, this.dataSource.data, this.dataSource.filteredData, this.dataSource._filterData);
		// JSON.parse(this.dataSource.data[0].codigoQR) '{'id'}'  {id: valor, I: valor, F: valor}
		// let auxdatasource = mismotipo 
		for(let element in this.dataSource){
			console.log("data", element);
			// let auxcodigoqr = jsonparse
			// JSON.parse
			//let auxiliarElement = { datos: val1, val2, val3, }
			//auxiliarElemnt { codigoQRInicial : auxcodigocr }
			// auxdatasource.push(auxiliarElement)
		}
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	deleteItem(item) {
		this.dataSource.data.splice(item, 1);
		this.dataSource.data = this.dataSource.data;
	}

	accept() {
		this._dialogRef.close({ productosLst: this.dataSource.data });
	}

	cancel() {
		this._dialogRef.close(false);
	}

}
