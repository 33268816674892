import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatSnackBar, MatDialogRef } from '@angular/material';
import { DialogoProductoNoRegistradoComponent } from './dialogo-producto-no-registrado/dialogo-producto-no-registrado.component';
import { DialogoCrearAgrupacionComponent } from './dialogo-crear-agrupacion/dialogo-crear-agrupacion.component';
import { 
  PdfMakeWrapper, 
  Table, 
  Img, 
  Columns,
} from 'pdfmake-wrapper';
import { SelectionModel } from '@angular/cdk/collections';

//Servicio para borrar la seleccion
import { ServicesComponent } from '../Interfaces/Services/general.service';

import { DataServices } from '../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../Interfaces/Models/LoginModels';
import { OverlayService } from '../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';

import {
  SearchDataFichaRequest,
  SearchDataFichaResponse,

  SaveFichaTempRequest,
  SaveFichaTempResponse,

  SearchDataFichasSeleccionadasRequest,
  SearchDataFichasSeleccionadasResponse,
  SearchDataFichaResponse2,
  
  DeleteFichaSeleccionadaResponse,
  DeleteFichaSeleccionadasRequest
} from '../Interfaces/Models/AgrupacionesModels';
import { ScanQrComponent } from '../header/scan-qr/scan-qr.component';
import { ScanQrAgruComponent } from '../header/scan-qr-agru/scan-qr-agru.component';
import { 
  
  SearchDocDetalleProductosAgruRequest,
  SearchDocDetalleProductosResponse,
  SearchDocDetalleTotalProdResponse,
  SearchDocDetalleTotalPalletResponse,
  SearchDocDetalleTotalCajasResponse,
  SearchDocDetalleTotalCantidadResponse,
  SearchDocDetalleTotalPesoResponse,
  SearchDocDetalleFechaMinResponse,
  SearchDataMovimientoDestinatarioRequest,
  SearchDataMovimientoDestinatarioResponse,
  SearchDataMovimientoRemitenteRequest,
  SearchDataMovimientoRemitenteResponse,
  SearchDataMovimientoTransportistaRequest,
  SearchDataMovimientoTransportistaResponse,
  SearchDataMovimientoInfoLegalRequest,
  SearchDataMovimientoInfoLegalResponse,
  SearchDocDetalleProductosRequest,
  SearchDocDetalleProductosIndiRequest,
  SearchDocDetalleProductosIndiResponse,
  SearchDataMovimientoGeneralRequest,
  SearchDataMovimientoGeneralResponse,
  SearchDataMovimientoObservacionesRequest,
  SearchDataMovimientoObservacionResponse,
  SearchDocDetalleProductosCajasResponse,

 } from '../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-agrupar',
  templateUrl: './agrupar.component.html',
  styleUrls: ['./agrupar.component.css']
})
export class AgruparComponent implements OnInit {

  constructor(
    public _servicio : ServicesComponent,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
  ) { }

  codigo : string = this._servicio.codigoQR;
  responseData = new SearchDataFichaResponse();
  responseData2 = new SearchDataFichaResponse2();
  responseSeleccionadas = new SearchDataFichasSeleccionadasResponse();
  responseDataGeneral = new SearchDataMovimientoGeneralResponse();

  responseDataDes = new SearchDataMovimientoDestinatarioResponse();
  responseDataRem = new SearchDataMovimientoRemitenteResponse();
  responseDataTrans = new SearchDataMovimientoTransportistaResponse();
  responseDataInfo = new SearchDataMovimientoInfoLegalResponse();

  responseDocsProductos = new SearchDocDetalleProductosResponse();
  responseDocsProductosIndi = new SearchDocDetalleProductosIndiResponse();
  responseDocsProductosCajas = new SearchDocDetalleProductosCajasResponse();
  responseDocsTotalProd = new SearchDocDetalleTotalProdResponse();
  responseDocsTotalPallet = new SearchDocDetalleTotalPalletResponse();
  responseDocsTotalCajas = new SearchDocDetalleTotalCajasResponse();
  responseDocsTotalCantidad = new SearchDocDetalleTotalCantidadResponse();
  responseDocsTotalPeso = new SearchDocDetalleTotalPesoResponse();
  responseDocsFechaMin= new SearchDocDetalleFechaMinResponse();

  responseDataComentario = new SearchDataMovimientoObservacionResponse();
  
  overlayRef: OverlayRef;

  vacio: string = "";

  ngOnInit() {
    this._servicio.codigoQR = '';
    //Enviar al servicio el tamaño de la selección de los check's
    this._servicio.setSeleccion(0);
    this.BusquedaAll();
  }

  openDialogScan(): void {
    const dialogRef =this.dialog.open(ScanQrAgruComponent,{
      width: 'auto',
      height: 'auto'
    }); 
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() { 
    var request = new SearchDataFichaRequest();
    console.log(this.codigo.substring(47,-1));
    if (this.codigo.substring(36,-1) == 'https://data.traceit.net/origin?ciu='){
      console.log('SI');
      var last = this.codigo.slice(36); // => Codigo QR
      console.log(last);
      request.codigo = last;
    }else{
      console.log('NO');
      request.codigo = this.codigo;
    }

    request.usuario = parseInt(sessionStorage.getItem("idUser"));
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);


    this.dataService.postData<SearchDataFichaResponse2>("Agrupaciones/searchDataFicha2", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData2 = data;
        if(this.responseData2.fichasDataList.length == 0){
          this.openSnack("Producto desconocido", "Aceptar");
          this._overlay.close(this.overlayRef);
          this.openDialogProductoNoRegistrado();
        }
        else{
          this.dataService.postData<SearchDataFichaResponse>("Agrupaciones/searchDataFicha", sessionStorage.getItem("token"), request).subscribe(
            data => {
              if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
              } else {
                  this.BusquedaAll();
              }
              this._overlay.close(this.overlayRef);
            },
            error => {
              if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("Busqueda");
              } else {
                console.log(error);
              }
              this._overlay.close(this.overlayRef);
            }
          );
        }
        this._servicio.setSeleccion(0);
      }
      ,
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  /*SaveFichaTemp(){

    var requestUpdate = new SaveFichaTempRequest();
    requestUpdate.fichaId = this.responseData.fichasDataList.idFicha;
    requestUpdate.tipoFicha = this.responseData.fichasDataList.tipoFicha;
    requestUpdate.usuario = parseInt(sessionStorage.getItem("idUser"));

    this.dataService.postData<SaveFichaTempResponse>("Agrupaciones/saveFichaTemp", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
                this._overlay.close(this.overlayRef);
            } else {
                this.BusquedaAll();
                this._overlay.close(this.overlayRef);
            }
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("SaveFichaTemp");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }*/

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaAll() {
    
    var request = new SearchDataFichasSeleccionadasRequest();
    request.usuario = parseInt(sessionStorage.getItem("idUser"));

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);
        
    this.dataService.postData<SearchDataFichasSeleccionadasResponse>("Agrupaciones/searchDataFichasSeleccionadas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        if((data.fichasSeleccionadasDataList.length == 0) && (data.fichasDesconocidasDataList.length == 0) && (data.fichasUnicasDataList.length == 0) && (data.fichasMovimientosDataList.length == 0)){
          this._servicio.activar=false;
        }else if ((data.fichasSeleccionadasDataList.length > 0) || (data.fichasDesconocidasDataList.length >= 0) || (data.fichasUnicasDataList.length >= 0) || (data.fichasMovimientosDataList.length >= 0)){
          this._servicio.activar=true;
          this.responseSeleccionadas = data;
        }
        this.responseSeleccionadas = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaAll");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para eliminar la dirección
  eliminarFicha(id : number) {
    var requestDelete = new DeleteFichaSeleccionadasRequest();
    requestDelete.movfichaId = id;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<DeleteFichaSeleccionadaResponse>("Agrupaciones/deleteFichaSeleccionada", sessionStorage.getItem("token"), requestDelete).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Ficha eliminada con éxito", "Aceptar");
                this._servicio.numFichas --; 
                this.BusquedaAll();
            }
            this._overlay.close(this.overlayRef);
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("eliminarFicha");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }

  //#region Métodos (click)

  openDialogCrearAgrupacion(): void {
    if ( screen.width <= 576)
		{
			const dialogRef =this.dialog.open(DialogoCrearAgrupacionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Registro exitoso');
        }
        else{
          console.log('Error');
        }
      });
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this.dialog.open(DialogoCrearAgrupacionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Registro exitoso');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this.dialog.open(DialogoCrearAgrupacionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Registro exitoso');
        }
        else{
          console.log('Error');
        }
      });
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this.dialog.open(DialogoCrearAgrupacionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Registro exitoso');
        }
        else{
          console.log('Error');
        }
      });
		} 
		if (screen.width > 1200){
			const dialogRef =this.dialog.open(DialogoCrearAgrupacionComponent,{
        height: 'auto'
      });    
      dialogRef.afterClosed().subscribe(res=>{
        console.log(res);
        if(res){
          console.log('Registro exitoso');
        }
        else{
          console.log('Error');
        }
      });
		}
  }

  openDialogProductoNoRegistrado(): void {
    const dialogRef =this.dialog.open(DialogoProductoNoRegistradoComponent,{
      width: 'auto',
      height: 'auto',
      data: {
        codigo: this.codigo
    }
    });    
    dialogRef.afterClosed().subscribe(res=>{
      console.log(res);
      if(res){
        console.log('Registro exitoso');
      }
      else{
        console.log('Error');
      }
    });
  }

  buscardatos(valor:number){
    console.log(valor);
    this.BusquedaGeneral(valor);
  }

  BusquedaGeneral(movimientoId:number) {
    
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = movimientoId;
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataGeneral = data;
        this.BusquedaDes(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaDes(movimientoId:number) {
    
    var request = new SearchDataMovimientoDestinatarioRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDataMovimientoDestinatarioResponse>("Movimientos/searchDataMovimientoDestinatario", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataDes = data; 
        this.BusquedaRem(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDes");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaRem(movimientoId:number) {
    
    var request = new SearchDataMovimientoRemitenteRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDataMovimientoRemitenteResponse>("Movimientos/searchDataMovimientoRemitente", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataRem = data;
        this.BusquedaTrans(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaRem");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaTrans(movimientoId:number) {
    
    var request = new SearchDataMovimientoTransportistaRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDataMovimientoTransportistaResponse>("Movimientos/searchDataMovimientoTransportista", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataTrans = data;
        this.BusquedaInfo(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaTrans");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaInfo(movimientoId:number) {
    
    var request = new SearchDataMovimientoInfoLegalRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDataMovimientoInfoLegalResponse>("Movimientos/searchDataMovimientoInfoLegal", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataInfo = data;
        this.BusquedaDocsProductos(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }
  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsProductos(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductos", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        this.BusquedaDetalleProductos(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDetalleProductos(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = movimientoId.toString();

    this.dataService.postData<SearchDocDetalleProductosIndiResponse>("Movimientos/searchDocDetalleProductosIndi", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosIndi = data;
        this.BusquedaDetalleProductos2(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDetalleProductos2(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosIndiRequest();
    request.movimientoId = movimientoId.toString();

    this.dataService.postData<SearchDocDetalleProductosCajasResponse>("Movimientos/searchDocDetalleProductosCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductosCajas = data;
        this.BusquedaDocsTotalProd(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalProd(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleTotalProdResponse>("Movimientos/searchDocDetalleTotalProd", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalProd = data;
        this.BusquedaDocsTotalPallet(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalProd");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPallet(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPalletResponse>("Movimientos/searchDocDetalleTotalPallet", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPallet = data;
        this.BusquedaDocsTotalCajas(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPallet");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCajas(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCajasResponse>("Movimientos/searchDocDetalleTotalCajas", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCajas = data;
        this.BusquedaDocsTotalCantidad(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCajas");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalCantidad(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleTotalCantidadResponse>("Movimientos/searchDocDetalleTotalCantidad", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalCantidad = data;
        this.BusquedaDocsTotalPeso(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalCantidad");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsTotalPeso(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleTotalPesoResponse>("Movimientos/searchDocDetalleTotalPeso", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsTotalPeso = data;
        this.BusquedaDocsFechaMin(movimientoId);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsTotalPeso");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  BusquedaDocsFechaMin(movimientoId:number) {
    
    var request = new SearchDocDetalleProductosRequest();
    request.movimientoId = movimientoId;

    this.dataService.postData<SearchDocDetalleFechaMinResponse>("Movimientos/searchDocDetalleFechaMin", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsFechaMin = data;
        this.BusquedaComentario(movimientoId);
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsFechaMin");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaComentario(movimientoId:number) {
      
    var request = new SearchDataMovimientoObservacionesRequest();
    request.movimientoId = movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcion", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDataComentario = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Detalle Ficha',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('portrait');
    pdf.pageMargins([ 20, 60, 20, 30 ]);

    pdf.header(
      new Columns([
        new Table(
        [
          [ {text: this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, bold:true, fontSize:18}
          ]
        ]).widths(['*']).alignment('center').color('#012152').alignment('left').margin([20,20,40,0]).end,
        await new Img('../../assets/img/img-logo-stock-3.png').width('100').margin([-20,20,20,0]).build()
      ]).end,
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          ['','',''],['','',''],['','',''],['','',''],['','',''],['','',''],
          [ {text: 'Detalle', bold:true, alignment:'right', fontSize:16},
            {text: 'Pedido',  alignment:'left', color: 'gray', fontSize:16},
            '' 
          ],
          ['','',''],['','',''], ['','',''],  ['','',''],['','',''],  ['','',''],  ['','',''],  
          [ {text:'Orden de Pedido: ', alignment:'right', bold:true}, 
            {text: this.responseDataGeneral.movimientosDataGeneralRecepList.referenciaInterna, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'No. cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.numeroC, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+
                   this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, alignment:'left', color: 'gray'},
            ''
          ],
          [ {text:'Pedido cliente: ', alignment:'right', bold:true}, 
            {text: this.responseDataGeneral.movimientosDataGeneralRecepList.referenciaExterna, alignment:'left', color: 'gray'},
            ''
          ]
        ]).widths([80, 150, 40]).fontSize(9).layout('noBorders').end,
        new Table([
          [ {qr: this.responseDataGeneral.movimientosDataGeneralRecepList.codigoQR, alignment:'center', fit:100, colSpan:2},
            ''
          ],
          [ {text: this.responseDataGeneral.movimientosDataGeneralRecepList.codigoQR, color: 'gray', colSpan:2}]
        ]).widths([80, 150]).alignment('center').fontSize(9).layout('noBorders').end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Destinatario: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreCompaniaD+' '+
                   this.responseDataDes.movimientosDataDestinatarioRecepList.rzCompaniaD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.domicilioD+', '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.ciudadD+', '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.estadoND+', '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.paisND, alignment:'left', color: 'gray'}
          ],
          [ {text:'CP: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.cpD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Tel: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.telefonoD, alignment:'left', color: 'gray'}
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: this.responseDataDes.movimientosDataDestinatarioRecepList.nombreDestinatario+' '+
            this.responseDataDes.movimientosDataDestinatarioRecepList.apellidoDestinatario, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Remitente: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreCompaniaR+' '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.rzCompaniaR, alignment:'left', color: 'gray'}
          ],
          [ {text:'Dirección: ', alignment:'right', bold:true}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.domicilioR+', C.P. '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.cpR+', '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.ciudadR+', '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.estadoNR+', '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.paisNR, alignment:'left', color: 'gray'} 
          ],
          [ {text:'Atn: ', alignment:'right', bold:true}, 
            {text: this.responseDataRem.movimientosDataRemitenteRecepList.nombreRemitente+' '+
                   this.responseDataRem.movimientosDataRemitenteRecepList.apellidoRemitente, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Transporte: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.transportista, alignment:'left', color: 'gray'}
          ],
          [ {text:'Fecha embarque: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.fechaEmbarque, alignment:'left', color: 'gray'}
          ],
          [ {text:'Referencia: ', alignment:'right', bold:true}, 
            {text: this.responseDataTrans.movimientosDataTransportistaList.numReferencia, alignment:'left', color: 'gray'}
          ]
        ]).widths([80, 150]).fontSize(9).end,
        new Table([
          [ {text:'Observaciones: ', alignment:'right', bold:true, fontSize:11}, 
            {text: this.responseDataComentario.movimientosDataObservacionRecepList.observacion, alignment:'left', color: 'gray'} ////////////////////////////////////////////////////////
          ],
          [ {text:'Dimensiones por caja (cms): ', alignment:'right', bold:true}, 
          {text: this.responseDataComentario.movimientosDataObservacionRecepList.dimensionesCaja, alignment:'left', color: 'gray'}
        ],
        [ {text:'Peso por caja (Kg): ', alignment:'right', bold:true}, 
          {text: this.responseDataComentario.movimientosDataObservacionRecepList.pesoCaja, alignment:'left', color: 'gray'}
        ]
        ]).widths([80, 150]).alignment('center').fontSize(9).end
      ]).end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Columns([
        new Table([
          [ {text:'Resumen de Productos/Pedido: ', bold:true, colSpan:8, fontSize:11, alignment:'left'}, 
            '','','','','','',''
          ],
          [ {text:'Producto/Familia: ', bold:true}, 
            {text: 'Marca: ', bold:true},
            {text: 'GTIN: ', bold:true},
            {text: 'Pallets: ', bold:true},
            {text: 'Cajas por pallet: ', bold:true},
            {text: 'Unidades por caja: ', bold:true},
            {text: 'Total de unidades: ', bold:true},
            {text: 'Caducidad prox: ', bold:true},
          ]
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(9).end,
      ]).end
    );

    //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      var a;
      if(products.numPallet==0){
        a=1;
      }else{
        a=products.numPallet;
      }
      pdf.add(
        new Table([
          [ {text:products.producto, alignment:'right'}, 
            {text: products.ciu, alignment:'right'}, 
            products.gtin,
            products.numPallet, 
            products.numCajas/a, 
            products.cantidad/products.numCajas, 
            products.cantidad, 
            products.fechaCaducidad
          ],
        ]).widths([100, 65, 55, 35, 40, 50, 60, 75]).alignment('center').fontSize(8).end
      );
    }

    pdf.add(
      new Table([
        [ 
          {text:'', bold:true, colSpan:9, fontSize:11, alignment:'left',pageBreak: 'before'}, 
            '','','','','','','',''
        ]
      ]).widths([120, 40, 70, 40, 40,40,40,40,40]).alignment('center').fontSize(9). layout('noBorders').end
    );

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Pallets', colSpan:9, bold:true}, 
          '','','','','','','',''
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let pallets of this.responseDocsProductosIndi.docDetalleProductoIndiList) {
        pdf.add(
          new Table([
            [ {text: pallets.producto, bold:true}, 
            {text: pallets.ciu, bold:true}, 
            {text: pallets.gtin, bold:true}, 
            {text: pallets.tipoEmpaque, bold:true}, 
            {text: pallets.cantidad/pallets.numPallet, bold:true}, 
            {text: pallets.codigoPallet, bold:true} ,
            {text: ' ', bold:true} ,
            {text: pallets.lote, bold:true} ,
            {text: pallets.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
        );
      }
    }

    pdf.add(
      pdf.ln(2)
    );
    
    pdf.add(
      new Table([
        [ 
          {text:'Detalle de Productos/Pedido ', bold:true, colSpan:9, fontSize:11, alignment:'left'}, 
            '','','','','','','',''
        ],
        [ {text:'Producto / Familia', bold:true}, 
          {text:'Marca', bold:true}, 
          {text:'GTIN', bold:true}, 
          {text:'Tipo de empaque', bold:true}, 
          {text:'Unidades por empaque', bold:true}, 
          {text:'Código pallet', bold:true} ,
          {text:'Código caja', bold:true} ,
          {text:'Lote / Serial', bold:true} ,
          {text:'Caducidad prox', bold:true} 
        ]
      ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(9).end
    );

    if(this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas==0){
      pdf.add(
        new Table([
          [ {text: 'Sin Cajas', bold:true}
        ]
      ]).widths(['*']).alignment('center').fontSize(8).end
      );
    }else{
      //Productos FOR
      for (let cajas of this.responseDocsProductosCajas.docDetalleProductoCajasList) {
        pdf.add(
          new Table([
            [ {text: cajas.producto, bold:true}, 
            {text: cajas.ciu, bold:true}, 
            {text: cajas.gtin, bold:true}, 
            {text: cajas.tipoEmpaque, bold:true}, 
            {text: cajas.cantidad/cajas.numCajas, bold:true}, 
            {text: cajas.codigoPallet, bold:true} ,
            {text: cajas.codigoCaja, bold:true} ,
            {text: cajas.lote, bold:true} ,
            {text: cajas.fechaCaducidad, bold:true} 
          ]
        ]).widths([100, 50, 70, 40, 40,40,40,40,50]).alignment('center').fontSize(8).end
        );
      }
    }

    pdf.footer(
      new Columns([
        new Table(
        [
          [ 
            {text:'Controla tus inventarios y automatiza las comunicaciones de tu almacen con Traceit Stock   https://stock.traceit.net' }]
        ]).widths(['*']).alignment('center').layout('noBorders').fontSize(7).end
      ]).end
    );


    if ( screen.width <= 576)
		{
			pdf.create().download();
		}
		if (screen.width > 576 && screen.width <= 768)
		{
      pdf.create().download();
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			pdf.create().open();
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			pdf.create().open();
		} 
		if (screen.width > 1200){
			pdf.create().open();
    }
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)


  //Funcion para realizar la busqueda  de la info que irá en la tabla del documento de info legal
  /*BusquedaDocsProductos(valor:number) {
    
    var request = new SearchDocDetalleProductosAgruRequest();
    request.idFicha = valor;

    this.dataService.postData<SearchDocDetalleProductosResponse>("Movimientos/searchDocDetalleProductosAgru", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseDocsProductos = data;
        this.generatePDF();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaDocsProductos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }*/
  

  /*async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Información de Agrupación',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([ 20, 20, 20, 20 ]);

    pdf.add(
      new Columns([
        new Table(
        [
          [ {text:this.responseData.fichasDataList.nombreFicha, bold:true, rowSpan:2},
            {qr:this.responseData.fichasDataList.codigo, fit:50}, 
            {text:'Referencia interna: ', marginLeft:30}, '---', 
            {text:'Referencia externa: ', marginLeft:30}, '---',  
          ],
          [ ' ', ' ', '', '', '', '']
        ]).widths(['auto', 'auto', 'auto', 'auto', 'auto', 'auto']).alignment('center').layout('noBorders').end,
        await new Img('../../assets/img/logofondo3.png').width('100').build()
      ]).end
      
    );

    pdf.add(
      pdf.ln(1)
    );
  //Cabeceras
    pdf.add(
      new Table([
        [{text:'Consolidado de Productos',bold:true,alignment:'left', colSpan:8}, '','','','','','',''],
        [{text:' ', colSpan:8}, '','','','','','',''],
        [ {text:'Producto', bold:true}, 
          {text:'Pallets', bold:true}, 
          {text:'Cajas', bold:true}, 
          {text:'Unidades', bold:true}, 
          {text:'Peso Bruto', bold:true}, 
          {text:'Dimesiones', bold:true}, 
          {text:'Caducidad Min.', bold:true}, 
          {text:'Lote', bold:true}
        ]
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );
  //Productos FOR
    for (let products of this.responseDocsProductos.docDetalleProductoList) {
      pdf.add(
        new Table([
          [''+products.producto, ''+products.numPallet, ''+products.numCajas, ''+products.cantidad, ''+products.pesoBruto+' Kgs', ''+products.dimensiones, ''+products.fechaCaducidad, ''+products.lote],
        ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
      );
    }
  //Totales
    pdf.add(
      new Table([
        [ ' ', '', '', '', '', '', '', ''],
        [ {text:'Total', bold:true}, '', '', '', '', '', '', ''],
        [ ''+this.responseDocsTotalProd.docDetalleTotalProdList.totalProducto+' productos', ''+this.responseDocsTotalPallet.docDetalleTotalPalletList.totalPallet+' pallets', ''+this.responseDocsTotalCajas.docDetalleTotalCajasList.totalCajas+' cajas', 
          ''+this.responseDocsTotalCantidad.docDetalleTotalCantidadList.totalCantidad+' unidades', ''+this.responseDocsTotalPeso.docDetalleTotalPesoList.totalPeso+' Kgs', '---', ''+this.responseDocsFechaMin.docDetalleFechaMinList.fechaMin, ''],
      ]).widths([100, 70, 70, 70, 100, 100, 120, 100]).alignment('center').fontSize(11).end
    );


    pdf.add(
      pdf.ln(1)
    );

    pdf.add(
      pdf.ln(22)
    );
    pdf.add(
      new Table([
        ['\n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n \n']
      ]).widths([400,500]).relativePosition(210,60).end
    );
    pdf.add(
      new Table([
        [ '','****','TOP','****','',
          {text:' ', rowSpan:8, noBorders:false},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:'T\nr\na\nc\ne\n-\nI\nt\n',fontSize:'15',bold:true, rowSpan:8, marginTop:70},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:' ', rowSpan:8},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5}
        ],
        [ {text:'N\no\nm\nb\nr\ne\n \nd\ne\n \np\nr\no\nd\nu\nc\nt\no',fontSize:'10', rowSpan:5, wrap:'vertical'},
          {text:'PALLET',fontSize:'20',bold:true, colSpan:3},'','',
          {text:'D\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:2},'','','','','','','','','','','',''],
        [ '',
          {qr:this.responseData.fichasDataList.codigo, colSpan:3, rowSpan:3, marginTop:35},'','','','','','','','','','','','','','',''],
        ['','','','','','','','','','','','','','','','',''],
        ['','','','',{text:'E\nN\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:2},'','','','','','','','','','','',''],
        [ '',
          {text:'1 de 9', colSpan:3},'','','','','','','','','','',
          {qr:this.responseData.fichasDataList.codigo, rowSpan:2, colSpan:2, fit:50},'','',
          {qr:this.responseData.fichasDataList.codigo, rowSpan:2, colSpan:2, fit:50},''],
        [ '',
        {text:this.responseData.fichasDataList.nombreFicha, colSpan:3},'','','','','','','','','','','','','','',''],
        [ {text:' ', colSpan:5},'','','','','','','','','','','',
        {text:'1 de 1', colSpan:2},'','',
        {text:'1 de 1', colSpan:2},'']
      ]).widths(['auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto'])
        .alignment('center')
        .layout('noBorders')
        .relativePosition(260,100).end
    );

    pdf.add(
      pdf.ln(20)
    );

    pdf.create().open();
  }*/

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin: any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");
    this.dataService.postDataApi<any>().subscribe(
			dataApi => {
				requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
              if (data.messageEsp != "") {
                  sessionStorage.clear();
                  this._router.navigate(['Login']);
                  this.openSnack(data.messageEsp, "Aceptar");
                  return;
              }
  
              sessionStorage.clear();
  
              data.userData.userPermissions.forEach((it, id) => {
                  sessionStorage.setItem(it.namePermission, it.permissionId.toString());
              });
  
              sessionStorage.setItem("token", data.token);
              sessionStorage.setItem("name", data.userData.userData.name);
              sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
              sessionStorage.setItem("email", requestLogin.user);
              sessionStorage.setItem("password", requestLogin.password);
              sessionStorage.setItem("company",data.userData.userData.company.toString());
  
              switch (peticion) {
                  case "Busqueda":
                    this.Busqueda();
                    break;
                 /* case "SaveFichaTemp":
                    this.SaveFichaTemp();
                    break;*/
                  case "BusquedaAll":
                    this.BusquedaAll();
                    break;
                  default:
                      break;
              }
            },
            error => {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack("Error al mandar la solicitud", "Aceptar");
                return;
            }
        );
      },
			error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
              if (data.messageEsp != "") {
                  sessionStorage.clear();
                  this._router.navigate(['Login']);
                  this.openSnack(data.messageEsp, "Aceptar");
                  return;
              }
  
              sessionStorage.clear();
  
              data.userData.userPermissions.forEach((it, id) => {
                  sessionStorage.setItem(it.namePermission, it.permissionId.toString());
              });
  
              sessionStorage.setItem("token", data.token);
              sessionStorage.setItem("name", data.userData.userData.name);
              sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
              sessionStorage.setItem("email", requestLogin.user);
              sessionStorage.setItem("password", requestLogin.password);
              sessionStorage.setItem("company",data.userData.userData.company.toString());
  
              switch (peticion) {
                  case "Busqueda":
                    this.Busqueda();
                    break;
                 /* case "SaveFichaTemp":
                    this.SaveFichaTemp();
                    break;*/
                  case "BusquedaAll":
                    this.BusquedaAll();
                    break;
                  default:
                      break;
              }
            },
            error => {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack("Error al mandar la solicitud", "Aceptar");
                return;
            }
        );
			}
		);  
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  //#endregion
}
