import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import {
  SearchDataMovimientoObservacionesRequest,
  SearchDataMovimientoObservacionResponse,

  EditDataMovimientoObservacionRequest,
  EditDataMovimientoObservacionResponse

} from '../../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-dialogo-observaciones',
  templateUrl: './dialogo-observaciones.component.html',
  styleUrls: ['./dialogo-observaciones.component.css']
})
export class DialogoObservacionesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoObservacionesComponent>,
    private _router: Router,
    private dataService: DataServices,
    private snack: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _overlay: OverlayService,
  ) { }

  movimientoId : string = '';
  responseData = new SearchDataMovimientoObservacionResponse();
  observacion : string = '';

  overlayRef: OverlayRef;

  ngOnInit() {
    this.movimientoId = this._data.id;
    if(this.movimientoId == "0") {
      if(this._data.hasOwnProperty("infoObservaciones")) {
        this.fillAddress();
      }
    } else {
      this.Busqueda();
    }
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
      
    var request = new SearchDataMovimientoObservacionesRequest();
    request.movimientoId = parseInt(this.movimientoId);

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoObservacionResponse>("Movimientos/searchDataObservcion", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this.observacion = this.responseData.movimientosDataObservacionRecepList.observacion;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  cleanObservacion(){
    this.observacion = "";
  }

  fillAddress() {
    this.observacion = this._data.infoObservaciones.observacion;
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  SaveObservacion(){
    // No guardar en caso de que no se encuentre el movimiento id
    if(this.movimientoId == "0"){
      this.dialogRef.close({
        infoObservaciones: {
          observacion: this.observacion,
        }
      });
      return;
    }
    var requestUpdate = new EditDataMovimientoObservacionRequest();
    requestUpdate.movimientoId = this.responseData.movimientosDataObservacionRecepList.movimientoId;
    requestUpdate.observacion = this.observacion;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<EditDataMovimientoObservacionResponse>("Movimientos/editDataMovimientoObservacion", sessionStorage.getItem("token"), requestUpdate).subscribe(
        data => {
            if (data.messageEsp != "") {
                this.openSnack(data.messageEsp, "Aceptar");
            } else {
                this.openSnack("Observaciones guardadas con éxito", "Aceptar");
                this.dialogRef.close(true);
            }
            this._overlay.close(this.overlayRef);
        },
        error => {
            if (error.error.hasOwnProperty("messageEsp")) {
                this.relogin("SaveDestinatario");
            } else {
                this.openSnack("Error al mandar la solicitud", "Aceptar");
            }
            this._overlay.close(this.overlayRef);
        }
    )
  }
}
