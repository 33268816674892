import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogoEnviarComponent } from './header/dialogo-enviar/dialogo-enviar.component';
import { DialogoRecibirComponent } from './header/dialogo-recibir/dialogo-recibir.component';

//Servicio para verificar seleccion
import { ServicesComponent } from './Interfaces/Services/general.service';
import { DialogoCrearCuentaComponent } from './login/dialogo-crear-cuenta/dialogo-crear-cuenta.component';
import { DialogoCreeCuentaComponent } from './header-invitado/dialogo-cree-cuenta/dialogo-cree-cuenta.component';
import { DialogoTerminosComponent } from './login/dialogo-terminos/dialogo-terminos.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    screenWidth: number;
    //Variable para mostrar el Footeren los componentes
    showFooter: boolean;

    constructor(private _router: Router,
        public dialog: MatDialog,
        public _servicio : ServicesComponent
    ) {
        this.screenWidth = window.innerWidth;

        window.addEventListener('resize', () => {
            this.screenWidth = window.innerWidth;
        });

        this.CheckIn();
    }

    title = 'app';
    Login = sessionStorage;
    User: string = "";
    doble : boolean = this._servicio.doble;
    vacio: string = "";

    logout = () => {
        this._servicio.invitado=false;
        sessionStorage.clear();
        this.Login = sessionStorage;
        this._router.navigate(["Login"]);
    }

    CheckIn = (e?) => {
        this.User = sessionStorage.getItem("name");
    }

    ngOnInit = () => {
    }

    openAgrupar(){
        this._router.navigate(["Agrupar"]);
    }

    openExistenciaEstimada(){
        this._router.navigate(["Existencia-Estimada"]);
    }

    openInicio(){
        this._router.navigate(["Home"]);
    }

    //#region Métodos (click)
    openDialogEnviar(isAgro = 0): void {
        let data:any = {
            id: this._servicio.getElemento(),
        }
        if(isAgro) {
            data.isAgro = isAgro;
        }
        const dialogRef =this.dialog.open(DialogoEnviarComponent,{
            width: 'auto',
            height: 'auto',
            disableClose: true,
            data: data
        });  

        dialogRef.afterClosed().subscribe(res=>{
        });
    }

    openDialogRecibir(isAgro = 0): void {
        let data: any = {
            id: 0
        }
        if(isAgro) {
            data.isAgro = isAgro;
        }
        let dialogRef =this.dialog.open(DialogoRecibirComponent,{
        data: data
        });   

        dialogRef.afterClosed().subscribe(res=>{
        });
    }

    //Abrir ventana de Dialogo de Crear Cuenta
    openDialogCrearCuenta(): void {
        if ( screen.width <= 576)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
			});
		}
		if (screen.width > 576 && screen.width <= 768)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			width: 'auto',
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
			});
		}  
		if (screen.width > 768 && screen.width <= 992)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
			});
		}  
		if (screen.width > 992 && screen.width <= 1200)
		{
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
			});
		} 
		if (screen.width > 1200){
			const dialogRef =this.dialog.open(DialogoCrearCuentaComponent,{
			height: 'auto'
			}); 
			dialogRef.afterClosed().subscribe(res=>{
			});
		} 
    }

    openDialogRegistrate(): void {
        const dialogRef =this.dialog.open(DialogoCreeCuentaComponent,{
          width: 'auto',
          height: 'auto'
        });    
        dialogRef.afterClosed().subscribe(res=>{
        });
        this.logout();
      }

    //Abrir ventana de Dialogo de Crear Cuenta
	openDialogTerminos(): void {
		const dialogRef =this.dialog.open(DialogoTerminosComponent,{
		width: 'auto',
		height: 'auto'
		});    
		dialogRef.afterClosed().subscribe(res=>{
		});
	}
    //#endregion 
}
