import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AgruparComponent } from './agrupar/agrupar.component';
import { ExistenciaEstimadaComponent } from './existencia-estimada/existencia-estimada.component';
import { HeaderInvitadoComponent } from './header-invitado/header-invitado.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { GuardsLogin } from './Interfaces/Guards/GuardsLogin.guard';

import { DataServices } from './Interfaces/Services/general.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

const appRoutes: Routes = [
    { path: 'Login', component: LoginComponent, canActivate: [GuardsLogin]},
    { path: 'Home', component: HomeComponent, canActivate: [GuardsLogin] },
    { path: 'Agrupar', component: AgruparComponent, canActivate: [GuardsLogin] },
    { path: 'Existencia-Estimada', component: ExistenciaEstimadaComponent, canActivate: [GuardsLogin] },
    { path: 'Usuarios', loadChildren: () => import('./usuarios/usuarios.module').then(mod => mod.UsuariosModule), canActivate: [GuardsLogin] },
    { path: '', redirectTo: '/Login', pathMatch: 'full' },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes, { enableTracing: false }), // <-- debugging purposes only
        FormsModule,
        HttpClientModule
    ],
    providers: [DataServices],
    exports: [RouterModule]
})

export class AppRoungModule { }