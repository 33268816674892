import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { enviroments } from '../Enviroments/enviroments';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DataServices {
    private httpHeader: HttpHeaders;

    constructor(private httpClient: HttpClient) { };

    //Metodo para realizar los consumos POST al WS
    postData<T>(method: string, bearer: string, objectRequest?: any):Observable<T> {
        this.httpHeader = new HttpHeaders({
            'Authorization': 'Bearer ' + bearer,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        
        if(method == "User/loginUser") {
            objectRequest.isOrigin = 1;
        }

        return this.httpClient.post<T>(`${enviroments.urlBase}${method}`, objectRequest, { headers: this.httpHeader });
    }

    //Metodo para realizar consumo de api
    postDataApi<T>():Observable<T> {
        return this.httpClient.get<T>(`https://ipgeolocation.abstractapi.com/v1/?api_key=3fba439c1abf4a4aa345639d905e82e4`);
    }

    //Metodo para guardar documentos realizando un consumo POST al WS
	postDataDocs<T>(method: string, bearer: string, objectRequest?: any): Observable<T> {
		this.httpHeader = new HttpHeaders({
			'Authorization': 'Bearer ' + bearer,
			'Access-Control-Allow-Origin': '*',
		});

		return this.httpClient.post<T>(`${enviroments.urlBase}${method}`, objectRequest, { headers: this.httpHeader });
    }
    
    //Metodo para descagar documentos realizando un consumo POST al WS
	postDataDownDocs<T>(method: string, bearer: string, objectRequest?: any): Observable<Blob> {
		this.httpHeader = new HttpHeaders({
			'Authorization': 'Bearer ' + bearer,
			'Access-Control-Allow-Origin': '*'
		});

		return this.httpClient.post<Blob>(`${enviroments.urlBase}${method}`, objectRequest, { responseType: 'blob' as 'json', headers: this.httpHeader });
	}
}

//Services para compartir los datos entre componentes
@Injectable()
export class ServicesComponent {
    @Output() Family: EventEmitter<number> = new EventEmitter();

    //Servicio para compartir los datos de familia
    familyData(familyIdService : number) {
        this.Family.emit(familyIdService);
    }

    //Elaborado: Javier Ramirez

    //Variable para conocer si un checkbox está seleccionado o no
    seleccionado: any = 0;

    setSeleccion(valor:any){
        this.seleccionado = valor;
    }

    getSeleccion(){
        return this.seleccionado;
    }

    //Variable para saber el id del elemeto seleccionado en el checkbox
    elemento: any = 0;

    setElemento(valor:any){
        this.elemento = valor;
    }

    getElemento(){
        return this.elemento;
    }

    //Variable para saber si está activada la opcion de ingresar como invitado
    invitado: boolean = false;
    doble:boolean=false;

    //Subir archivos a una carpeta
    URL = "http://localhost/DocumentosInfoLegal/";

    constructor(private http: HttpClient) { }

    uploadFile(archivo) {
        return this.http.post(`${this.URL}subirArchivo.php`, JSON.stringify(archivo));
    }

    //Datos para crear la cuenta (dirrecion)
    direccion: string = ''; 
    nombrePais: string = '241'; 
    nombreTexto : string ="";
    codigoPostal: string = ''; 
    ciudad: string = ''; 
    nombreEstado: string = '36'; 
    dataEstado:string='';
    dataPais:string='';

    //Obtener ubicación
    getPosition(): Promise<any> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resp => {
                    resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
                },
                err => {
                    reject(err);
              });
        });
    }

    //Codigo QR
    codigoQR:string="";

    codigoQRESTATICO:string="";

    //Agrupar
    nombreAg: any = "";
    refInAg: any = "";
    refExAg: any = "";
    activar: boolean = false;
    vistaFicha: boolean = true;

    numFichas: number = 0;
}