export const enviroments = {
    //urlBase: "http://35.84.86.164:3000/", //Test
    //qrUrl: "http://35.84.86.164/tracking?qr=", //Test
    //urlBase: "http://localhost:5101/",  //LocalHost
    //qrUrl: "http://localhost:5101/tracking?qr=", //LocalHost
    urlBase: "https://www.traceit.net:3100/", // Productiva
    qrUrl: "https://data.traceit.net/tracking?qr=", // Productiva
    urlZebra: "https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/",
    urlZebraLogistic: "https://api.labelary.com/v1/printers/8dpmm/labels/4x2/0/",
    logoUrl: "https://stock.traceit.net/",
    pageSize: [20, 50, 100]
}