import { Component, OnInit, ViewChild } from '@angular/core';
import { 
  PdfMakeWrapper, 
  Table, 
  Img, 
  Columns,
} from 'pdfmake-wrapper';

import { DataServices } from '../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../Interfaces/Models/LoginModels';
import { Router } from '@angular/router';
import { enviroments } from "../Interfaces/Enviroments/enviroments";

//Servicio para verificar seleccion
import { ServicesComponent } from '../Interfaces/Services/general.service';
import { ExporterService } from '../Interfaces/Services/exporter.service';


//DataServices and Models
import {
  ExistenciaEstimadaData,
  SearchDataMovimientoGeneralRequest,
  SearchDataMovimientoGeneralResponse,
  SearchExistenciaEstimadaRequest,
  SearchExistenciaEstimadaResponse
} from '../Interfaces/Models/MovimientosModels';
import { MatSort, MatPaginator, MatTableDataSource, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { OverlayService } from '../Interfaces/Services/overlay.service';
import { DatePipe } from '@angular/common';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-existencia-estimada',
  templateUrl: './existencia-estimada.component.html',
  styleUrls: ['./existencia-estimada.component.css']
})
export class ExistenciaEstimadaComponent implements OnInit {

  constructor(
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
    public datepipe: DatePipe,
    public _servicio : ServicesComponent,
    private excelService: ExporterService,
  ) { }

  ngOnInit() {
    this.Busqueda();
    this._servicio.nombreAg = "";
    this._servicio.refInAg = "";
    this._servicio.refExAg = "";
    this._servicio.setSeleccion(0);
  }

  movimientoId : number = 0;
  nombreFamiliaCIU : string = "";
  fechaCaducidad : string = "";
  anio: string = new Date().getFullYear().toString();
  fechaIngresoDe: string = this.anio.concat('-01-01'.toString());
  fechaIngresoHasta: string = this.anio.concat('-12-31'.toString());
  ordenar : number = 1;
  response:any = [];
  itemsPagina: number[] = enviroments.pageSize;
  overlayRef: OverlayRef;
  emptyList: boolean = false;

  responseEtiqueta = new SearchDataMovimientoGeneralResponse();

  list = [{
    "id": 1,
    "data": "Agrupar por Fecha"
  }, {
    "id": 2,
    "data": "Agrupar por Caducidad"
  }]

  //Representa la instancia para el sorting de columnas (Clasificación por)
  @ViewChild(MatSort) sort: MatSort;

  //Representa componente de paginación para la tabla
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //variables para configurar la tabla  (select es para Checkbox, columnas de la tabla)
  displayedColumns: string[] = ['select',
                                'movimientoId',
                                'nombreAgrupacion',
                                'nombreCliente',
                                'numCliente',
                                'producto', 
                                'compania',
                                'cantidadEnvio', 
                                'cantidadRecibida', 
                                'cantidadExistencia', 
                                'fechaIngreso', 
                                'fechaCaducidad',
                                'lote'
                                ];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(false, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
      if (!row) {
          return "{this.isAllSelected() ? 'select' : 'deselect'} all";
      }
      //Enviar al servicio el tamaño de la selección de los check's
      this._servicio.setSeleccion(this.selection.selected.length);
      this._servicio.setElemento(this.selection.selected.map(item => this.movimientoId = item.movimientoId));
      return "${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}";
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchExistenciaEstimadaRequest();
    request.nombreFamiliaCIU = this.nombreFamiliaCIU;
    request.fechaCaducidad = this.fechaCaducidad;
    request.fechaIngresoDe = this.fechaIngresoDe;
    request.fechaIngresoHasta = this.fechaIngresoHasta;
    request.ordenar = this.ordenar;
    request.usuario = parseInt(sessionStorage.getItem("idUser"));

    setTimeout(() => {
        this.overlayRef = this._overlay.open();
    }, 1);
        
    this.dataService.postData<any>("Movimientos/searchExistenciaEstimada", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.response = data;
        if (this.response.existenciaEstimadaDataList.length > 0)
          this.emptyList = false;
        else
          this.emptyList = true;
          this.dataSource.data = this.response.existenciaEstimadaDataList;
          this.selection.clear();
          this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  //#region 
   //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
   BusquedaEtiqueta() {
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = parseInt(this._servicio.getElemento());
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseEtiqueta = data;
        this.generatePDF();
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'PDF Etiqueta Nombre del Producto',
      author: 'Trace IT',
      subject: ''
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([ 20, 20, 20, 20 ]);
    pdf.add(
      new Table([
        [ '','****','TOP','****','',
          {text:' ', rowSpan:8, noBorders:false},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:'T\nr\na\nc\ne\n-\nI\nt\n',fontSize:'15',bold:true, rowSpan:8, marginTop:70},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:' ', rowSpan:8},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:' ', rowSpan:8},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5},
          {text:'E\nN\nC\nR\nY\nP\nT\nE\nD\n \nD\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:5}
        ],
        [ {text:'N\no\nm\nb\nr\ne\n \nd\ne\n \np\nr\no\nd\nu\nc\nt\no',fontSize:'10', rowSpan:5, wrap:'vertical'},
          {text:this.responseEtiqueta.movimientosDataGeneralRecepList.nombreAgrupacion,fontSize:'20',bold:true, colSpan:3},'','',
          {text:'D\nE\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:2},'','','','','','','','','','','',''],
        [ '',
          {qr:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, colSpan:3, rowSpan:3, marginTop:35},'','','','','','','','','','','','','','',''],
        ['','','','','','','','','','','','','','','','',''],
        ['','','','',{text:'E\nN\nC\nR\nY\nP\nT\nE\nD',fontSize:'10', rowSpan:2},'','','','','','','','','','','',''],
        [ '',
          {text:'1 de 9', colSpan:3},'','','','','','','','','','',
          {qr:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, rowSpan:2, colSpan:2, fit:50},'','',
          {qr:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, rowSpan:2, colSpan:2, fit:50},''],
        [ '',
        {text:this.responseEtiqueta.movimientosDataGeneralRecepList.codigoQR, colSpan:3},'','','','','','','','','','','','','','',''],
        [ {text:' ', colSpan:5},'','','','','','','','','','','',
        {text:'1 de 1', colSpan:2},'','',
        {text:'1 de 1', colSpan:2},'']
      ]).widths(['auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto','auto'])
        .alignment('center')
        .layout('noBorders').end
    );

    pdf.create().open();
  }

  exportAsXLSX():void{
    this.excelService.exportToExcel(this.dataSource.data, 'Exportacion de movimientos');
  }

  /*exportAsXLSXFiltered():void{
    this.excelService.exportToExcel(this.dataSource.filteredData, 'Exportacion de Productos');
  }*/

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  //Funcion despues de obtener todos los datos se establece el paginado
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  //#endregion

}
