import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import {
  SearchDataMovimientoGeneralRequest,
  SearchDataMovimientoGeneralResponse,

  SearchComboPaisEstadoRequest,
  SearchComboPaisEstadoResponse,

  SearchDataMovimientoDestinatarioRequest,
  SearchDataMovimientoDestinatarioResponse,

  EditDataMovimientoDestinatarioRequest,
  EditDataMovimientoDestinatarioResponse,

  SearchListDestinatariosRequest,
  SearchListDestinatariosResponse,
  SaveEstadosSiNoExisteResponse,
  SearchExistenciaEstadoResponse,
  SearchExistenciaEstadoRequest,
  SaveEstadosSiNoExisteRequest,
  SearchListDestinatariosResponse2,
  SearchListDestinatariosRequest2
} from '../../../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-dialogo-destinatario',
  templateUrl: './dialogo-destinatario.component.html',
  styleUrls: ['./dialogo-destinatario.component.css']
})
export class DialogoDestinatarioComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoDestinatarioComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
  ) { }
  referenciaInterna: number; 
  referenciaExterna: number;
  acopioId: number;
  ciudadAcopio: string;
  codigoPostal: number;
  estadoId: number;
  nombreAcopio: string;
  nombreEstado: string;
  nombreProductor: string;
  nombreRancho: string;
  numeroAcopio: number;
  numeroProductor: number;
  paisId: number;
  paisNombre: string;
  productorId: number;

  overlayRef: OverlayRef;



  ngOnInit() {
    console.log('DATA',this._data);
    this.numeroProductor = this._data.productorDestinatario.numeroProductor;
    this.nombreProductor = this._data.productorDestinatario.nombreProductor;
    this.nombreRancho = this._data.productorDestinatario.nombreRancho;
    this.paisNombre = this._data.productorDestinatario.paisNombre;
    this.nombreEstado = this._data.productorDestinatario.nombreEstado;
    this.ciudadAcopio = this._data.productorDestinatario.ciudadAcopio;
    this.referenciaInterna = this._data.referenciaInterna ?  this._data.referenciaInterna : 0;
    this.referenciaExterna = this._data.referenciaExterna ? this._data.referenciaExterna : 0;
    console.log('numeroC', this._data.productorDestinatario.numeroProductor);
  }
 
  
  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }


}
