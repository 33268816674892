import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common/';
import { FormsModule } from '@angular/forms';

//Modulos
import { AppRoungModule } from './app-routing-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './login/login.module';
import { HomeModule } from './home/home.module';
import { UsuariosModule } from './usuarios/usuarios.module';
import { LoginComponent } from './login/login.component';

//Material
import { MaterialModule } from './material/material.module';
import { getSpanishPaginatorIntl } from './material/spanis-paginator-intl';

//Componentes
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavMenuComponent } from './sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavLinkComponent } from './sidenav/sidenav-link/sidenav-link.component';
import { MatPaginatorIntl, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';
import { OverlayCargaComponent } from './overlay-carga/overlay-carga.component';
import { AgruparComponent } from './agrupar/agrupar.component';
import { ExistenciaEstimadaComponent } from './existencia-estimada/existencia-estimada.component';
import { HeaderComponent } from './header/header.component';
import { HeaderInvitadoComponent } from './header-invitado/header-invitado.component';

//#region Dialogos
import { DialogoCrearCuentaComponent } from './login/dialogo-crear-cuenta/dialogo-crear-cuenta.component';
import { DialogoAgregarDireccionComponent } from './login/dialogo-agregar-direccion/dialogo-agregar-direccion.component';
import { DialogoRegistroExitosoComponent } from './login/dialogo-registro-exitoso/dialogo-registro-exitoso.component';
import { DialogoPreguntasComponent } from './login/dialogo-preguntas/dialogo-preguntas.component';
import { DialogoProductoDesconocidoComponent } from './agrupar/dialogo-producto-desconocido/dialogo-producto-desconocido.component';
import { DialogoCrearAgrupacionComponent } from './agrupar/dialogo-crear-agrupacion/dialogo-crear-agrupacion.component';
import { DialogoEnviarComponent } from './header/dialogo-enviar/dialogo-enviar.component';
import { DialogoInfoRemitenteComponent } from './header/dialogo-info-remitente/dialogo-info-remitente.component';
import { DialogoInfoDestinatarioComponent } from './header/dialogo-info-destinatario/dialogo-info-destinatario.component';
import { DialogoInfoTransporteComponent } from './header/dialogo-info-transporte/dialogo-info-transporte.component';
import { DialogoInfoLegalComponent } from './header/dialogo-info-legal/dialogo-info-legal.component';
import { DialogoEnvioRegistradoComponent } from './header/dialogo-envio-registrado/dialogo-envio-registrado.component';
import { DialogoRecibirComponent } from './header/dialogo-recibir/dialogo-recibir.component';
import { DialogoRecepcionRegistradaComponent } from './header/dialogo-recepcion-registrada/dialogo-recepcion-registrada.component';
import { DialogoProductoNoRegistradoComponent } from './agrupar/dialogo-producto-no-registrado/dialogo-producto-no-registrado.component';
import { DialogoCreeCuentaComponent } from './header-invitado/dialogo-cree-cuenta/dialogo-cree-cuenta.component';

import { AppInfoComponent } from './header/scan-qr/app-info/app-info.component';
import { AppInfoDialogComponent } from './header/scan-qr/app-info-dialog/app-info-dialog.component';
import { FormatsDialogComponent } from './header/scan-qr/formats-dialog/formats-dialog.component';
import { DialogoMermaComponent } from './header/dialogo-merma/dialogo-merma.component';
//#endregion

//#region PDF
import { PdfMakeWrapper } from 'pdfmake-wrapper';
import pdfFont from 'pdfmake/build/vfs_fonts'; 
//#endregion

import { DatePipe } from '@angular/common';

//Servicio para verificar seleccion
import { ServicesComponent } from './Interfaces/Services/general.service';
import { HttpClientModule } from '@angular/common/http';
//import { NgxFileDropModule } from 'ngx-file-drop';
import { DialogoDestinatarioComponent } from './header/envios/dialogo-destinatario/dialogo-destinatario.component';
import { DialogoRemitenteComponent } from './header/envios/dialogo-remitente/dialogo-remitente.component';
import { DialogoLegalComponent } from './header/envios/dialogo-legal/dialogo-legal.component';

//import { NgxQRCodeModule } from 'ngx-qrcode2';
import { ScanQrComponent } from './header/scan-qr/scan-qr.component';

import { NgQrScannerModule } from 'angular2-qrscanner';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DialogoRecibirBusComponent } from './header/dialogo-recibir-bus/dialogo-recibir-bus.component';
import { ScanQrAgruComponent } from './header/scan-qr-agru/scan-qr-agru.component';
import { DialogoTerminosComponent } from './login/dialogo-terminos/dialogo-terminos.component';
import { DialogoAvisoComponent } from './login/dialogo-aviso/dialogo-aviso.component';
import { DialogoObservacionesComponent } from './header/dialogo-observaciones/dialogo-observaciones.component';
import { DialogoObservacionesRecepComponent } from './header/dialogo-observaciones-recep/dialogo-observaciones-recep.component';
import { DialogoNombreRecepcionComponent } from './header/dialogo-nombre-recepcion/dialogo-nombre-recepcion.component';

//ng-Autocomplete
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { DialogoImprimirComponent } from './header/dialogo-imprimir/dialogo-imprimir.component';
import { DialogoVerDetalleProductosComponent } from './header/dialogo-ver-detalle-productos/dialogo-ver-detalle-productos.component';
import { DialogoGenericmsgComponent } from './header/dialogo-genericmsg/dialogo-genericmsg.component';
import { DialogoProductosAgrupacionComponent } from './header/dialogo-productos-agrupacion/dialogo-productos-agrupacion.component';

//Cargar Fuentes desde PDf Make Wrapper
PdfMakeWrapper.setFonts(pdfFont);

import { SearchPipe } from './header/pipes/search.pipe';

  
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        HomeComponent,
        HeaderInvitadoComponent,
        SidenavComponent,
        SidenavMenuComponent,
        SidenavLinkComponent,
        LoginComponent,
        OverlayCargaComponent,
        
        AgruparComponent,
        ExistenciaEstimadaComponent,
        DialogoCrearCuentaComponent,
        DialogoAgregarDireccionComponent,
        DialogoRegistroExitosoComponent,
        DialogoPreguntasComponent,
        DialogoProductoDesconocidoComponent,
        DialogoCrearAgrupacionComponent,
        DialogoEnviarComponent,
        DialogoInfoRemitenteComponent,
        DialogoInfoDestinatarioComponent,
        DialogoInfoTransporteComponent,
        DialogoInfoLegalComponent,
        DialogoEnvioRegistradoComponent,
        DialogoRecibirComponent,
        DialogoRecepcionRegistradaComponent,
        DialogoProductoNoRegistradoComponent,
        DialogoCreeCuentaComponent,
        DialogoDestinatarioComponent,
        DialogoRemitenteComponent,
        DialogoLegalComponent,
        ScanQrComponent,
        DialogoRecibirBusComponent,
        ScanQrAgruComponent,
        DialogoTerminosComponent,
        DialogoAvisoComponent,
        DialogoObservacionesComponent,
        DialogoObservacionesRecepComponent,
        HeaderComponent,
        AppInfoComponent,
        AppInfoDialogComponent,
        FormatsDialogComponent,
        DialogoNombreRecepcionComponent,
        DialogoMermaComponent,
        DialogoImprimirComponent,
        DialogoVerDetalleProductosComponent,
        DialogoGenericmsgComponent,
        DialogoProductosAgrupacionComponent,
        SearchPipe
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        LoginModule,
        HomeModule,
        FormsModule,
        MaterialModule,
        AppRoungModule,
        HttpClientModule,
       // NgxQRCodeModule,
        NgQrScannerModule,
        ZXingScannerModule,
        AutocompleteLibModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule
    ],
    providers: [{ provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() }, { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { panelClass:['primary-snack'], verticalPosition: 'top'}},DatePipe, ServicesComponent],
    entryComponents: [OverlayCargaComponent,
        DialogoCrearCuentaComponent, 
        DialogoAgregarDireccionComponent,
        DialogoRegistroExitosoComponent,
        DialogoPreguntasComponent,
        DialogoProductoDesconocidoComponent,
        DialogoCrearAgrupacionComponent,
        DialogoEnviarComponent,
        DialogoInfoRemitenteComponent,
        DialogoInfoDestinatarioComponent,
        DialogoInfoTransporteComponent,
        DialogoInfoLegalComponent,
        DialogoEnvioRegistradoComponent,
        DialogoRecibirComponent,
        DialogoRecepcionRegistradaComponent,
        DialogoProductoNoRegistradoComponent,
        DialogoCreeCuentaComponent,
        DialogoDestinatarioComponent,
        DialogoRemitenteComponent,
        DialogoLegalComponent,
        ScanQrComponent,
        DialogoRecibirBusComponent,
        ScanQrAgruComponent,
        DialogoTerminosComponent,
        DialogoAvisoComponent,
        DialogoObservacionesComponent,
        DialogoObservacionesRecepComponent,
        DialogoNombreRecepcionComponent,
        DialogoMermaComponent,
        DialogoImprimirComponent,
        DialogoVerDetalleProductosComponent,
        DialogoGenericmsgComponent,
        DialogoProductosAgrupacionComponent
        ],
    bootstrap: [AppComponent]
})
export class AppModule { }
