import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog, MatDialogRef } from '@angular/material';
import { ServicesComponent } from '../../Interfaces/Services/general.service';
import { BarcodeFormat } from '@zxing/library';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scan-qr-agru',
  templateUrl: './scan-qr-agru.component.html',
  styleUrls: ['./scan-qr-agru.component.less']
})
export class ScanQrAgruComponent implements OnInit {

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  scannerEnabled: boolean = true;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;
  selectedValue;

  constructor(
    private readonly _dialog: MatDialog, 
    private snack: MatSnackBar, 
    private _dialogRef: MatDialogRef<ScanQrAgruComponent>,
    private _servicio: ServicesComponent,
    private _router: Router,
    ) { }

  ngOnInit() {

  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;

    if (this.qrResultString.length) {
      this.reproducir();
      this.scannerEnabled = false;
      this._dialogRef.close(this.qrResultString);
      this._servicio.codigoQR = this.qrResultString;
      this._router.navigateByUrl('/DummyComponent', {skipLocationChange: true}).then(()=>
        this._router.navigate(["Agrupar"]));

    } else {
      this.openSnack("El QR tiene una liga vacia o tiene un error", "Aceptar");
    }

  }

  reproducir() {
    const audio = new Audio('../../../assets/aud/sonidoEscaner.mp3');
    audio.play();
  }


  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  empezarCaptura() {
    this.scannerEnabled = true;
  }

  cerrarCaptura() {
    this.scannerEnabled = false;
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
      duration: 5000
    })
  }

}
