import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

import { DataServices } from '../../../Interfaces/Services/general.service';
import { LoginUserRequest, LoginUserResponse } from '../../../Interfaces/Models/LoginModels';
import { OverlayService } from '../../../Interfaces/Services/overlay.service';
import { OverlayRef } from '@angular/cdk/overlay';
import { Router } from '@angular/router';


import {
  SearchDataMovimientoGeneralRequest,
  SearchDataMovimientoGeneralResponse,

  SearchComboPaisEstadoRequest,
  SearchComboPaisEstadoResponse,

  SearchDataMovimientoRemitenteRequest,
  SearchDataMovimientoRemitenteResponse,

  EditDataMovimientoRemitenteRequest,
  EditDataMovimientoRemitenteResponse,
  SaveEstadosSiNoExisteResponse,
  SearchExistenciaEstadoResponse,
  SaveEstadosSiNoExisteRequest,
  SearchExistenciaEstadoRequest,
} from '../../../Interfaces/Models/MovimientosModels';

@Component({
  selector: 'app-dialogo-remitente',
  templateUrl: './dialogo-remitente.component.html',
  styleUrls: ['./dialogo-remitente.component.css']
})
export class DialogoRemitenteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogoRemitenteComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialog: MatDialog,
    private dataService: DataServices,
    private snack: MatSnackBar,
		private _router: Router,
    private _overlay: OverlayService,
  ) { }

  movimientoId : number = 0;
  company: number = Number(sessionStorage.getItem("company"));
  paisId : number = 0;
  estadoId : number = 0;
  responseNombre = new SearchDataMovimientoGeneralResponse();
  responsePaisEstado = new SearchComboPaisEstadoResponse();
  responseData = new SearchDataMovimientoRemitenteResponse();
  overlayRef: OverlayRef;

  nombreEstado : string ="";
  responseGuardarEstado = new SaveEstadosSiNoExisteResponse();
  responseExistenciaEstado = new SearchExistenciaEstadoResponse();

  // Info Remitente
  nombreRemitente = "";
  apellidoRemitente = "";
  nombreCompaniaR = "";
  rzCompaniaR = "";
  telefonoR = "";
  paisR = 0;
  estadoR = 0;
  ciudadR = "";
  cpR = "";
  domicilioR = "";
  ranchoR = "";
  sectorR = "";

  //NG AUTOCOMPLETE PAIS
  public keyword = "data";
  initialPais = {
    id: 241,
    data: 'Seleccione un Pais',
  }

  initialEstado = {
    id: 36,
    data: 'Seleccione un Estado',
  }

  selectEvent(item) {
    this.paisId = item.id;
    this.nombreEstado = '';
    this.BusquedaCombos2();
  }
  onClearedSearch(){
    this.nombreEstado = '';
    this.paisId = 241;
  }

  //NG AUTOCOMPLETE ESTADO
  public keywordE = "data";
  selectEventE(item) {
    this.nombreEstado = '';
    this.estadoId = item.id;
  }

  onChangeSearchE(item){
    this.nombreEstado = item;
  }

  onClearedSearchE(){
    this.nombreEstado = '';
    this.estadoId = 36;
  }

  ngOnInit() {
    this.movimientoId = this._data.id;
    if(this.movimientoId != 0) {
      this.BusquedaNombre();
    } else {
      this.responseNombre.movimientosDataGeneralRecepList.nombreAgrupacion = "";
      this.responseNombre.movimientosDataGeneralRecepList.referenciaInterna = 0;
      this.responseNombre.movimientosDataGeneralRecepList.referenciaExterna = 0;
      if(this._data.hasOwnProperty("domicilioRem") || this.movimientoId == 0) {
        setTimeout(() => {
          this.overlayRef = this._overlay.open();
        }, .001);
        if(this._data.hasOwnProperty("domicilioRem")) {
          this.fillAddress();
        }
        this.BusquedaCombos();
        if(this.movimientoId == 0 && !this._data.hasOwnProperty("domicilioRem")) {
          this.Busqueda();
        }
      } else {
        this.Busqueda();
      }
    }
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  BusquedaNombre() {
      
    var request = new SearchDataMovimientoGeneralRequest();
    request.movimientoId = this.movimientoId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchDataMovimientoGeneralResponse>("Movimientos/searchDataMovimientoGeneral", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseNombre = data;
        this.Busqueda();
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("BusquedaNombre");
        } else {
          console.log(error);
        }
      }
    );
  }

  //Funcion para realizar la busqueda  (Las variables son las que se envian para hacer la búsqueda)
  Busqueda() {
    
    var request = new SearchDataMovimientoRemitenteRequest();
    request.movimientoId = this.movimientoId;
    request.company = this.company;
    this.dataService.postData<SearchDataMovimientoRemitenteResponse>("Movimientos/searchDataMovimientoRemitente", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseData = data;
        this.initialPais.id=this.responseData.movimientosDataRemitenteRecepList.paisR;
        this.paisId = this.responseData.movimientosDataRemitenteRecepList.paisR;
        this.initialPais.data=this.responseData.movimientosDataRemitenteRecepList.paisNR;
        this.initialEstado.id=this.responseData.movimientosDataRemitenteRecepList.estadoR;
        this.estadoId = this.responseData.movimientosDataRemitenteRecepList.estadoR;
        this.initialEstado.data=this.responseData.movimientosDataRemitenteRecepList.estadoNR;

        this.paisR = this.responseData.movimientosDataRemitenteRecepList.paisR;
        this.ciudadR = this.responseData.movimientosDataRemitenteRecepList.ciudadR;
        this.cpR = this.responseData.movimientosDataRemitenteRecepList.cpR;
        this.domicilioR = this.responseData.movimientosDataRemitenteRecepList.domicilioR;
        this.nombreRemitente = this.responseData.movimientosDataRemitenteRecepList.nombreRemitente;
        this.apellidoRemitente = this.responseData.movimientosDataRemitenteRecepList.apellidoRemitente;
        this.nombreCompaniaR = this.responseData.movimientosDataRemitenteRecepList.nombreCompaniaR;
        this.rzCompaniaR = this.responseData.movimientosDataRemitenteRecepList.rzCompaniaR;
        this.telefonoR = this.responseData.movimientosDataRemitenteRecepList.telefonoR;
        this.ranchoR = this.responseData.movimientosDataRemitenteRecepList.ranchoR;
        this.sectorR = this.responseData.movimientosDataRemitenteRecepList.sectorR;
        this.BusquedaCombos();
        
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );
  }

  BusquedaCombos() {
    var request = new SearchComboPaisEstadoRequest();
    request.paisId = this.paisId;

    this.dataService.postData<SearchComboPaisEstadoResponse>("Movimientos/searchPaisEstadoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responsePaisEstado = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaCombos");
        } else {
          console.log(error);
        }
      }
    )
  }

  //Posee  barra de carga
  BusquedaCombos2() {
    var request = new SearchComboPaisEstadoRequest();
    request.paisId = this.paisId;

    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, 1);

    this.dataService.postData<SearchComboPaisEstadoResponse>("Movimientos/searchPaisEstadoDropDown", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responsePaisEstado = data;
        this._overlay.close(this.overlayRef);
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
            this.relogin("BusquedaCombos");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    )
  }

  cleanRemitente(){
    this.initialPais = {
      id: 241,
      data: 'Seleccione un Pais',
    }
  
    this.initialEstado = {
      id: 36,
      data: 'Seleccione un Estado',
    }
    this.paisId = 241;
    this.estadoId = 36;
    this.ciudadR = "";
    this.cpR = "";
    this.domicilioR = "";
    this.nombreRemitente = "";
    this.apellidoRemitente = "";
    this.nombreCompaniaR = "";
    this.rzCompaniaR = "";
    this.telefonoR = "";
    this.ranchoR = "";
    this.sectorR = "";
  }

  fillAddress() {
    this.paisId = this._data.domicilioRem.paisId;
    this.estadoId = this._data.domicilioRem.estadoId;
    this.ciudadR = this._data.domicilioRem.ciudadR;
    this.cpR = this._data.domicilioRem.cpR;
    this.domicilioR = this._data.domicilioRem.domicilioR;
    this.nombreRemitente = this._data.domicilioRem.nombreRemitente;
    this.apellidoRemitente = this._data.domicilioRem.apellidoRemitente;
    this.nombreCompaniaR = this._data.domicilioRem.nombreCompaniaR;
    this.rzCompaniaR = this._data.domicilioRem.rzCompaniaR;
    this.telefonoR = this._data.domicilioRem.telefonoR;
    this.ranchoR = this._data.domicilioRem.ranchoR;
    this.sectorR = this._data.domicilioRem.sectorR;
    this.initialEstado = this._data.domicilioRem.initialEstado;
    this.initialPais = this._data.domicilioRem.initialPais;
  }

  //Funcion para realizar el proceso del relogin
  relogin(peticion) {
    var requestLogin:any = {};
    requestLogin.user = sessionStorage.getItem("email");
    requestLogin.password = sessionStorage.getItem("password");

    this.dataService.postDataApi<any>().subscribe(
      dataApi => {
        requestLogin.lat = dataApi.latitude;
        requestLogin.lon = dataApi.longitude;
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaNombre":
                  this.BusquedaNombre();
                  break;
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                case "SaveRemitente":
                    this.SaveRemitente();
                    break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      },
      error => {
        this.dataService.postData<LoginUserResponse>("User/loginUser", "", requestLogin).subscribe(
          data => {
            if (data.messageEsp != "") {
                sessionStorage.clear();
                this._router.navigate(['Login']);
                this.openSnack(data.messageEsp, "Aceptar");
                return;
            }
    
            sessionStorage.clear();
    
            data.userData.userPermissions.forEach((it, id) => {
                sessionStorage.setItem(it.namePermission, it.permissionId.toString());
            });
    
            sessionStorage.setItem("token", data.token);
            sessionStorage.setItem("name", data.userData.userData.name);
            sessionStorage.setItem("idUser", data.userData.userData.idUser.toString());
            sessionStorage.setItem("email", requestLogin.user);
            sessionStorage.setItem("password", requestLogin.password);
            sessionStorage.setItem("company",data.userData.userData.company.toString());
    
            switch (peticion) {
                case "Busqueda":
                  this.Busqueda();
                  break;
                case "BusquedaNombre":
                  this.BusquedaNombre();
                  break;
                case "BusquedaCombos":
                  this.BusquedaCombos();
                  break;
                case "SaveRemitente":
                    this.SaveRemitente();
                    break;
                default:
                    break;
            }
          },
          error => {
              sessionStorage.clear();
              this._router.navigate(['Login']);
              this.openSnack("Error al mandar la solicitud", "Aceptar");
              return;
          }
        );
      }
    );
  }

  //Funcion para abrir el modal del mensaje
  openSnack = (message: string, action: string) => {
    this.snack.open(message, action, {
        duration: 5000
    })
  }

  SaveRemitente(){ 
    // No guardar en caso de que no se encuentre el movimiento id
    if(this.movimientoId == 0){
      this.dialogRef.close({
        domicilioRem: {
          nombreRemitente: this.nombreRemitente,
          apellidoRemitente: this.apellidoRemitente,
          nombreCompaniaR: this.rzCompaniaR,
          rzCompaniaR: this.rzCompaniaR,
          telefonoR: this.telefonoR,
          paisId: this.paisId,
          estadoId: this.estadoId,
          paisR: this.paisId,
          estadoR: this.estadoId,
          ciudadR: this.ciudadR,
          cpR: this.cpR,
          domicilioR: this.domicilioR,
          ranchoR: this.ranchoR,
          sectorR: this.sectorR,
          initialEstado: this.initialEstado.data,
          initialPais: this.initialPais.data
        }
      });
      return;
    }
    
    var request = new SearchExistenciaEstadoRequest();
    request.pais = this.paisId;
    if(this.nombreEstado == ''){
      request.nombreEstado = this.estadoId.toString();
      console.log('Vaciooooo');
    }
    else{
      request.nombreEstado = this.nombreEstado;
      console.log('Nuevo');
    }
        
    setTimeout(() => {
      this.overlayRef = this._overlay.open();
    }, .001);

    this.dataService.postData<SearchExistenciaEstadoResponse>("Movimientos/searchExistenciaEstado", sessionStorage.getItem("token"), request).subscribe(
      data => {
        this.responseExistenciaEstado = data;
        
        //Si no existe el dato
        if(this.responseExistenciaEstado.existenciaEstadoList.bandera == false){
          var requestUpdate2 = new SaveEstadosSiNoExisteRequest();
          requestUpdate2.pais = this.paisId;
          requestUpdate2.nombreEstado = this.nombreEstado;

          this.dataService.postData<SaveEstadosSiNoExisteResponse>("Movimientos/saveEstadosSiNoExiste", sessionStorage.getItem("token"), requestUpdate2).subscribe(
              data => {
                  if (data.messageEsp != "") {
                      this.openSnack(data.messageEsp, "Aceptar");
                      this._overlay.close(this.overlayRef);
                  } else {
                    this.openSnack("Estado agregado, favor de seleccionarlo", "Aceptar");
                    this._overlay.close(this.overlayRef)
                  }
              },
              error => {
                  if (error.error.hasOwnProperty("messageEsp")) {
                      this.relogin("SaveDocs");
                  } else {
                      this.openSnack("Error al mandar la solicitud", "Aceptar");
                  }
                  this._overlay.close(this.overlayRef);
              }
          );
        }
        //Si existe, solo guarda las variables
        else{
          console.log('Si existe');
          var requestUpdate = new EditDataMovimientoRemitenteRequest();
          requestUpdate.movimientoId = this.movimientoId;
          requestUpdate.nombreRemitente = this.nombreRemitente;
          requestUpdate.apellidoRemitente = this.apellidoRemitente;
          requestUpdate.nombreCompaniaR = this.rzCompaniaR;
          requestUpdate.rzCompaniaR = this.rzCompaniaR;
          requestUpdate.telefonoR = this.telefonoR;
          requestUpdate.paisR = this.paisId;
          requestUpdate.estadoR = this.estadoId;
          requestUpdate.ciudadR = this.ciudadR;
          requestUpdate.cpR = this.cpR;
          requestUpdate.domicilioR = this.domicilioR;
          requestUpdate.ranchoR = this.ranchoR;
          requestUpdate.sectorR = this.sectorR;


          setTimeout(() => {
            this.overlayRef = this._overlay.open();
          }, .001);

          this.dataService.postData<EditDataMovimientoRemitenteResponse>("Movimientos/editDataMovimientoRemitente", sessionStorage.getItem("token"), requestUpdate).subscribe(
              data => {
                  if (data.messageEsp != "") {
                      this.openSnack(data.messageEsp, "Aceptar");
                  } else {
                      this.openSnack("Datos de remitente guardados con éxito", "Aceptar");
                      this.dialogRef.close(true);
                  }
                  this._overlay.close(this.overlayRef);
              },
              error => {
                  if (error.error.hasOwnProperty("messageEsp")) {
                      this.relogin("SaveRemitente");
                  } else {
                      this.openSnack("Error al mandar la solicitud", "Aceptar");
                  }
                  this._overlay.close(this.overlayRef);
              }
          )
          this._overlay.close(this.overlayRef)
        }
      },
      error => {
        if (error.error.hasOwnProperty("messageEsp")) {
          this.relogin("Busqueda");
        } else {
          console.log(error);
        }
        this._overlay.close(this.overlayRef);
      }
    );

  }
}
